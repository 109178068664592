import React, {useState, useRef, useEffect} from 'react'
import './lucroCesante.css'
import html2canvas from 'html2canvas'
import { Dialog, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import Axios from 'axios'
import config from "../../libs/config"
import InsertLucro from './InsertLucro'
import moment from "moment"
import { mortalidadHombres, mortalidadMujeres } from '../../sharedComponents/mortalidades/mortalidades'
import BackButton from '../../sharedComponents/backButton/BackButton'
import ContinueButton from '../../sharedComponents/continueButton/ContinueButton'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { backStep, nextStep } from '../../store/features/appSlice'
import { getLucroCesnte, getMoneyFormat, getPeriod, getSecondDate } from '../../libs/helpers'

function LucroTable({setNext, step, setData, saveData, setImgTable2, muerte, condicion, ingreso}) {
    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

    const dispatch = useAppDispatch()
    

    const miDiv = useRef(null)

    const data = useAppSelector(state => state.app.data)
    const gastos = useAppSelector(state => state.app.data.lucroCesante.pasados)
    const gastosFuturos = useAppSelector(state => state.app.data.lucroCesante.futuros)

    const [insert, setInsert] = useState(false)
    const [ipcs, setIpcs] = useState([])
    const [calculoPasado, setCalculoPasado] = useState(0)
    const [calculoFuturo, setCalculoFuturo] = useState(0)

    const getIpcs = async () => {
        await Axios.get(`${config.api}/api/ipc`). then(response => {
            setIpcs(response.data)
        })
    }
    
    const siguiente = () => {
        setNext(step + 1)
    }
    
    function diffMonths(date1, date2) {
        var diffDays = (date2 - date1) / (1000 * 60 * 60 * 24);
        var diffMonths = diffDays / 30;
        return diffMonths.toFixed(2); // redondear a 2 decimales
    }

    const convertirImagen = () => {
        html2canvas(miDiv.current).then(canvas => {
            canvas.toBlob(blob => {
                setImgTable2(blob)
            });
        })
    }


    useEffect(() => {
        getIpcs()
        // if (ingreso > 0) {
        //     const ingresoPeriodico = {
        //         unico: false,
        //         tipo: "Sueldo",
        //         fecha1: moment(data.fechaHechos).format('YYYY-MM-DD'),
        //         fecha2: moment().format('YYYY-MM-DD'),
        //         monto: ingreso
        //     }
        //     setGastos([...gastos, ingresoPeriodico])
    
        //     const ingresoPeriodicoFuturo = {
        //         unico: false,
        //         tipo: "Sueldo",
        //         fecha1: moment().add(1, 'days').format('YYYY-MM-DD'),
        //         monto: ingreso
        //     }
    
        //     setGastosFuturos([...gastosFuturos, ingresoPeriodicoFuturo])
        // }
    }, [])
    
    
    
    useEffect(() => {
        let localPasados = 0
        let localFuturos = 0
        let saSueldo = 0
        
        if (ipcs.length > 0 && gastos.length > 0) {
            gastos.forEach(gasto => {
                if (gasto.unico){           
                    localPasados += getLucroCesnte(gasto, ipcs, data)
                } else {
                    localPasados += getLucroCesnte(gasto, ipcs, data)
                    
                }
            })
        }

        setCalculoPasado(localPasados)

        if (ipcs.length > 0 && gastosFuturos.length > 0) {
                gastosFuturos.forEach(gasto => {
                    if (gasto.unico){
                        localFuturos += getLucroCesnte(gasto, ipcs, data)
                    } else {
                        localFuturos += getLucroCesnte(gasto, ipcs, data)
    
                }
            })
        }

        setCalculoFuturo(localFuturos)
        
    }, [ipcs, gastos, gastosFuturos])

    const getGastoActualizado = (gasto, actualizado = true) => {
        if (ipcs.length === 0) return gasto.monto
        if (moment().diff(moment(gasto.fecha1), 'days') > 0){
            if (gasto.unico){
                return getLucroCesnte(gasto, ipcs, data, actualizado)
            } else {
                return getLucroCesnte(gasto, ipcs, data, actualizado)
                
            }
        } else {
            if (gasto.unico){
                return getLucroCesnte(gasto, ipcs, data, actualizado)
            } else {
                return getLucroCesnte(gasto, ipcs, data, actualizado)
        }
        }
    }

    // useEffect(() => {
    //     setData({...data, totalIndemnizacion: data.totalCalculo + calculoPasado + calculoFuturo})
    // }, [calculoPasado, calculoFuturo])

    useEffect(() => {
        convertirImagen()
    },[data])


    return (
        <div className='cardContainerTable'>
            <div className="rowTable">
                <label className="labelOptionBold">Resultado</label>
                <label className="labelOption">Indemnización por concepto de lucro cesante</label>
            </div>
            <Table className="tablePregunta tableSelect mt-20">
                <TableHead className="tableHead">
                    <TableRow>
                        <TableCell className="textTableHead blue1">Gasto</TableCell>
                        <TableCell className="textTableHead blue1">Tipo de gasto</TableCell>
                        <TableCell className="textTableHead blue1">Monto</TableCell>
                        <TableCell className="textTableHead blue1">Monto Actualizado</TableCell>
                        <TableCell className="textTableHead blue2">Fecha</TableCell>
                        <TableCell className="textTableHead blue3">Periodo indemnizable</TableCell>
                        <TableCell className="textTableHead blue4">Liquidación</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {gastos.map((ingreso, index) => (
                        <TableRow>
                            <TableCell className="tableCellBody">{ingreso.unico ? 'Único' : 'Periódico'}</TableCell>
                            <TableCell className="tableCellBody">{ingreso.tipo}</TableCell>
                            <TableCell className="tableCellBody">{getMoneyFormat(ingreso.monto)}</TableCell>
                            <TableCell className="tableCellBody">{getMoneyFormat(getGastoActualizado(ingreso))}</TableCell>
                            <TableCell className="tableCellBody">{ingreso.fecha1} {getSecondDate(ingreso)}</TableCell>
                            <TableCell className="tableCellBody">{getPeriod(ingreso, data.fechaLiquidacion, data)}</TableCell>
                            <TableCell className="tableCellBody">{getMoneyFormat(getGastoActualizado(ingreso, false))}</TableCell>
                        </TableRow>
                    ))}
                    {gastosFuturos.map((ingreso, index) => (
                        <TableRow>
                            <TableCell className="tableCellBody">{ingreso.unico ? 'Único' : 'Periódico'}</TableCell>
                            <TableCell className="tableCellBody">{ingreso.tipo}</TableCell>
                            <TableCell className="tableCellBody">{getMoneyFormat(ingreso.monto)}</TableCell>
                            <TableCell className="tableCellBody">{getMoneyFormat(getGastoActualizado(ingreso))}</TableCell>
                            <TableCell className="tableCellBody">{ingreso.fecha1} {getSecondDate(ingreso)}</TableCell>
                            <TableCell className="tableCellBody">{getPeriod(ingreso, data.fechaLiquidacion, data)}</TableCell>
                            <TableCell className="tableCellBody">{getMoneyFormat(getGastoActualizado(ingreso, false))}</TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell className="tableCellBody"></TableCell>
                        <TableCell className="tableCellBody"></TableCell>
                        <TableCell className="tableCellBody"></TableCell>
                        <TableCell className="tableCellBody"></TableCell>
                        <TableCell className="tableCellBody"></TableCell>
                        <TableCell className="textTableHead blue3">Total a liquidar</TableCell>
                        <TableCell className="tableCellBody">{getMoneyFormat(calculoPasado + calculoFuturo)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <div className='navBtnsContainer'>
                {/* <BackButton onClick={() => dispatch(backStep()) } /> */}
                <ContinueButton onClick={() => dispatch(nextStep())} text="Siguiente"/>
            </div>
        </div>
    )
}

export default LucroTable