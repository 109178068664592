import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React, { useState } from "react"
import "./preguntaIngresos.css"
import { useAppSelector } from "../../../store/hooks";
import Modal from "../../../sharedComponents/modal/Modal";
import AlertPregunta from "./alertPregunta/AlertPregunta";
import { firtsMayus } from "../../../libs/helpers";

function PreguntaIngresos({ setPreguntaIngresos, setShowModalAlert }){
    const data = useAppSelector((state) => state.app.data)
    const [showAlertPregunta, setShowAlertPregunta] = useState(false)

    const handleYes = () => {
        if (data.situacionLaboral === 'independiente'){
            setShowAlertPregunta(true)
        } else {
            setPreguntaIngresos(false)
        }
    }

    return(
        <div className="preguntaContainer">
            <div className="preguntaHeader">
                <label className="titlePregunta">¿La tabla muestra su <b>salario o renta mensual?</b></label>
                <div className="buttonsContainer">
                    <button onClick={() => handleYes()}>SI</button>
                    <button onClick={() => {
                                            setShowModalAlert(true)
                                            setPreguntaIngresos(false)}}>NO</button>
                </div>
            </div>
            <Table className="tablePregunta mt-20">
                <TableHead className="tableHead">
                    <TableRow>
                        <TableCell className="textTableHead blue1">Ingreso de suma</TableCell>
                        <TableCell className="textTableHead blue2">Tipo de Ingreso</TableCell>
                        <TableCell className="textTableHead blue3">Fecha</TableCell>
                        <TableCell className="textTableHead blue4">Monto</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.ingresos.map((ingreso, index) => (
                        <TableRow>
                            <TableCell className="tableCellBody">{ingreso.unico ? 'Única' : 'Periódica'}</TableCell>
                            <TableCell className="tableCellBody">{firtsMayus(ingreso.tipo)}</TableCell>
                            <TableCell className="tableCellBody">{ingreso.fecha1}</TableCell>
                            <TableCell className="tableCellBody">${ingreso.monto}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Modal open={showAlertPregunta} showClose={false} onClose={() => setShowAlertPregunta(false)} content={<AlertPregunta setShowAlertPregunta={setShowAlertPregunta} setPreguntaIngresos={setPreguntaIngresos} setShowModalAlert={setShowModalAlert} />} background="#0C77BC"/>
        </div>
    )
}

export default PreguntaIngresos;