import React, { useEffect, useState } from "react"
import { Button, Dialog, Divider, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material"
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import ContinueButton from '../../sharedComponents/continueButton/ContinueButton'
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { backStep, nextStep, setReclamantes } from "../../store/features/appSlice";
import ReclamantesTable from "./reclamantesTable/ReclamanteTable";
import InsertReclamante from "./insertReclamante/InsertReclamante";
import BackButton from "../../sharedComponents/backButton/BackButton";
import './reclamante.css'

const ReclamanteDaño = () => {
    const dispatch = useAppDispatch()

    const step = useAppSelector(state => state.app.step)
    const data = useAppSelector(state => state.app.data)

    const [insertReclamante, setInsertReclamante] = useState(false)

    const [pregunta, setPregunta] = useState(1)

    const [condicion, setCondicion] = useState('')
    const [muerte, setMuerte] = useState(false)
    const [showMuerte, setShowMuerte] = useState(false)
    const [dependientesEconomicos, setDependientesEconomicos] = useState(false)
    const [ingreso, setIngreso] = useState(0)
    const [InsertDepe, setInsertDepe] = useState(false)
    const [dependientesList, setDependientesList] = useState([])
    const reclamantes = useAppSelector(state => state.app.data.reclamantes)

    const [reclamante, setReclamante] = useState({monto: 0})

    const save = () => {
        dispatch(setReclamantes([...reclamantes, reclamante]))
        setReclamante({
            nombre: '',
            fechaNacimiento: '',
            sexoChecked: false,
            condicion: false
        })
        setInsertReclamante(false)
    }

    const setCondicionInfo = (ev) => {
        const {name, value} = ev.target
        setCondicion(name)
        setPregunta(2)
    }

    const handleBack = () => {
        dispatch(backStep())
    }

    useEffect(() => {

        if (!muerte && pregunta === 3) {
            setPregunta(2)
        }

        if (condicion === 'No está en edad' || condicion === 'desempleado') {
            setPregunta(2)
            setDependientesEconomicos(false)
        }
        //agregar 2 casos donde no hay lucro
    }, [pregunta])

    useEffect(() => {
        if (reclamante.dependenciaEconomica === 'parcialmente' || reclamante.dependenciaEconomica === 'absolutamente') {
            setInsertReclamante(true)
        }
    }, [reclamante.dependenciaEconomica])


    return (
        <div className="reclamanteContainer">
            <div className="titleHeaderUser titleContainer">
                <h1>INFORMACIÓN DEL RECLAMANTE</h1>
                <p className='labelOption'>Por favor ingrese a continuación los datos relevantes del reclamante del daño</p>
            </div>

            <div className="cardContainerReclamante">
                <div className="subContainerReclamante">
                    <div className="space-betwen mr-reclamante">
                        <div className='inputLabelContainer'>
                            <label className='labelOptionBold'>Nombre completo</label>
                            <input className='input infoInput' type="text" name="nombre" value={reclamante.nombre} onChange={ev => setReclamante({...reclamante, nombre: ev.target.value})} />
                        </div>
                        <div className='inputLabelContainer'>
                            <label className='labelOptionBold'>Fecha de Nacimiento</label>
                            <input className='dateSelect' type="date"  value={reclamante.fechaNacimiento} name="nombre" onChange={ev => setReclamante({...reclamante, fechaNacimiento: ev.target.value})} />
                        </div>
                        <div className='inputLabelContainer'>
                            <label className='labelOptionBold'>Sexo</label>
                            <div className='radiosContainer'>
                                <div className='radioContainer'>
                                    <input style={{ margin: '0 0 0 0' }} checked={reclamante.sexo === 'femenino' && reclamante.sexoChecked}   className='radioInput' type="radio" id="femenino" name="sexo" value="femenino" 
                                        onChange={ev => setReclamante({...reclamante, sexo: ev.target.value, sexoChecked: true })}
                                    />
                                    <label style={{ margin: '0 0 0 10px' }} className='labelOption' for="femenino">Femenino</label>
                                </div>
                                <div className='radioContainer'>
                                    <input style={{ margin: '0 0 0 10px' }} checked={reclamante.sexo === 'masculino' && reclamante.sexoChecked} className='radioInput' type="radio" id="masculino" name="sexo" value="masculino" 
                                        onChange={ev => setReclamante({...reclamante, sexo: ev.target.value, sexoChecked: true })}
                                    />
                                    <label style={{ margin: '0 0 0 10px' }} className='labelOption' for="masculino">Masculino</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Divider style={{ backgroundColor: '#A4A4A4', width: '100%', marginTop: 30}}  />

                    <div className="space-betwen mr-reclamante mt-reclamante align-center">
                        <div className='inputLabelContainer'>
                            <label className="labelOptionBold">Parentesco con la víctima</label>
                            <p className="p-reclamante">¿cuál es el parentesco del reclamante con la víctima?</p>
                        </div>
                        <div className='inputLabelContainer'>
                            <div className='radiosContainer'>
                                <div className='radioContainer'>
                                    <input style={{ margin: '0 0 0 0' }}  checked={reclamante.parentesco === 'hijos'}  className='radioInput' type="radio" id="hijos" name="parentesco" value="hijos" 
                                        onChange={ev => setReclamante({...reclamante, parentesco: ev.target.value })}
                                    />
                                    <label style={{ margin: '0 0 0 10px' }} className='labelOption' for="hijos">Hijos</label>
                                </div>
                                <div className='radioContainer'>
                                    <input style={{ margin: '0 0 0 10px' }} checked={reclamante.parentesco === 'padres'} className='radioInput' type="radio" id="padres" name="parentesco" value="padres" 
                                        onChange={ev => setReclamante({...reclamante, parentesco: ev.target.value })}
                                    />
                                    <label style={{ margin: '0 0 0 10px' }} className='labelOption' for="padres">Padres</label>
                                </div>
                                <div className='radioContainer'>
                                    <input style={{ margin: '0 0 0 10px' }} checked={reclamante.parentesco === 'hermanos'}  className='radioInput' type="radio" id="hermanos" name="parentesco" value="hermanos" 
                                        onChange={ev => setReclamante({...reclamante, parentesco: ev.target.value })}
                                    />
                                    <label style={{ margin: '0 0 0 10px' }} className='labelOption' for="hermanos">Hermanos</label>
                                </div>
                                <div className='radioContainer'>
                                    <input style={{ margin: '0 0 0 10px' }} checked={reclamante.parentesco === 'abuelos'} className='radioInput' type="radio" id="abuelos" name="parentesco" value="abuelos" 
                                        onChange={ev => setReclamante({...reclamante, parentesco: ev.target.value })}
                                    />
                                    <label style={{ margin: '0 0 0 10px' }} className='labelOption' for="abuelos">Abuelos</label>
                                </div>
                                <div className='radioContainer'>
                                    <input style={{ margin: '0 0 0 10px' }} checked={reclamante.parentesco === 'conyuge'} className='radioInput' type="radio" id="conyuge" name="parentesco" value="conyuge" 
                                        onChange={ev => setReclamante({...reclamante, parentesco: ev.target.value })}
                                    />
                                    <label style={{ margin: '0 0 0 10px' }} className='labelOption' for="conyuge">Conyuge</label>
                                </div>
                                <div className='radioContainer'>
                                    <input style={{ margin: '0 0 0 10px' }} checked={reclamante.parentesco === 'otros'}  className='radioInput' type="radio" id="otros" name="parentesco" value="otros" 
                                        onChange={ev => setReclamante({...reclamante, parentesco: ev.target.value })}
                                    />
                                    <label style={{ margin: '0 0 0 10px' }} className='labelOption ' for="otros">Otros</label>
                                </div>
                                <input className='input infoInput ml-reclamante' type="text" name="otro" disabled={reclamante.parentesco === 'otros' ? false : true } onChange={ev => setReclamante({...reclamante, otros: ev.target.value})} />
                            </div>
                        </div>
                    </div>

                    <Divider style={{ backgroundColor: '#A4A4A4', width: '100%', marginTop: 30}}  />

                    <div className="space-betwen mr-reclamante">
                        <div className='inputLabelContainer' style={{ width: '43%'}}>
                            <label className='labelOptionBold'>Condición</label>
                            <p className="p-reclamante">¿Cuál era la condición del reclamante en el momento de los hechos que ocasionaron el daño a la víctima?</p>
                            <div className='radiosContainer'>
                                <div className='radioContainer'>
                                    <input style={{ margin: '0 0 0 0' }} checked={reclamante.condicion === 'capacidad'}   className='radioInput' type="radio" id="capacidad" name="capacidad" value="capacidad" onChange={ev => setReclamante({...reclamante, condicion: ev.target.value })} />
                                    <label style={{ margin: '0 0 0 10px' }} className='labelOption' for="capacidad">En condición de capacidad</label>
                                </div>
                                <div className='radioContainer'>
                                    <input style={{ margin: '0 0 0 10px' }} checked={reclamante.condicion === 'discapacidad'} className='radioInput' type="radio" id="discapacidad" name="capacidad" value="discapacidad" onChange={ev => setReclamante({...reclamante, condicion: ev.target.value })} 
                                    />
                                    <label style={{ margin: '0 0 0 10px' }} className='labelOption' for="discapacidad">En condición de dispacidad</label>
                                </div>
                            </div>
                        </div>
                        <div className='inputLabelContainer' style={{ width: '50%'}}>
                            <label className='labelOptionBold'>Condición de dependencia</label>
                            <p className="p-reclamante">¿El reclamante depende/dependía económicamente de la víctima?</p>
                            <div className='radiosContainer' style={{ marginTop: 15 }}>
                                <div className='radioContainer' >
                                    <input style={{ margin: '0 0 0 0' }} checked={reclamante.dependenciaEconomica === 'absolutamente'} className='radioInput' type="radio" id="absolutamente" name="dependencia" value="absolutamente" 
                                        onChange={ev => setReclamante({...reclamante, dependenciaEconomica: ev.target.value })}
                                    />
                                    <label style={{ margin: '0 0 0 10px' }} className='labelOption' for="absolutamente">Si, absolutamente                 
                                        <Tooltip title={'Por “dependencia absoluta” se entiende que el ingreso que le daba la víctima al reclamante era el único o principal que lo mantenía. En caso de que no fuera su única o principal fuente de sustento, entonces usted se debe considerar dependiente no absoluto (Si, pero no absolutamente)'}>
                                            <IconButton style={{ paddingLeft: 0 }}>
                                                <ErrorOutlineIcon style={{ height: 14, color: '#338dc7' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </label>
                                </div>
                                <div className='radioContainer'>
                                    <input style={{ margin: '0 0 0 10px' }} checked={reclamante.dependenciaEconomica === 'parcialmente'} className='radioInput' type="radio" id="parcialmente" name="dependencia" value="parcialmente" 
                                        onChange={ev => setReclamante({...reclamante, dependenciaEconomica: ev.target.value })}
                                    />
                                    <label style={{ margin: '0 0 0 10px', fontSize: 12 }} className='labelOption' for="parcialmente">Si, pero no absolutamente</label>
                                </div>
                                <div className='radioContainer'>
                                    <input style={{ margin: '0 0 0 10px',  }} checked={reclamante.dependenciaEconomica === 'no'}  className='radioInput' type="radio" id="no" name="dependencia" value="no" 
                                        onChange={ev => setReclamante({...reclamante, dependenciaEconomica: ev.target.value })}
                                    />
                                    <label style={{ margin: '0 0 0 10px', fontSize: 12 }} className='labelOption' for="no">No</label>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="añadirVictima">
                       <button className="añadirVictimaBtn" onClick={() => save()}>AÑADIR VÍCTIMA</button>
                    </div>
                </div>
            </div>
            <ReclamantesTable />
            <div className='navBtnsContainer'>
                {/* <BackButton onClick={() => dispatch(backStep()) } /> */}
                <ContinueButton onClick={() => dispatch(nextStep())}  text="Siguiente"/>
            </div>
            <InsertReclamante insertReclamante={insertReclamante} setReclamante={setReclamante} reclamante={reclamante} setInsertReclamante={setInsertReclamante} />
            {/* <div className='navBtnsContainer'>
                <ContinueButton onClick={() => dispatch(nextStep())} />
                <BackButton onClick={handleBack} />
            </div> */}
        </div>
    )
};

export default ReclamanteDaño