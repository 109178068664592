import { Divider } from "@mui/material"
import React, { useEffect, useState } from "react"
import './victimasIndirectasModal.css'
import { capitalize } from "../../../libs/helpers"
import ContinueButton from "../../../sharedComponents/continueButton/ContinueButton"
import { useAppDispatch } from "../../../store/hooks"
import { setVictimasIndirectas } from "../../../store/features/appSlice"

function VictimasIndirectas({ question, setOpen, setQuestion }){
    const dispatch = useAppDispatch()
    const [yes, setYes] = useState(false)

    const [victimas, setVictimas] = useState([
        {nombre: 'padres', selected: false, value: 0, liquidation: 0},
        {nombre: 'hijos', selected: false, value: 0, liquidation: 0},
        {nombre: 'hermanos', selected: false, value: 0, liquidation: 0},
        {nombre: 'abuelos', selected: false, value: 0, liquidation: 0},
        {nombre: 'conyuge', selected: false, value: 0, liquidation: 0}
    ])

    const [victimasSelected, setVictimasSelected] = useState()

    const handleNo = () => {
        setQuestion(question + 2)
        setOpen(false)
    }

    const handleSelect = (index) => {
        let victimasCopy = [...victimas]
        victimasCopy[index].selected = !victimasCopy[index].selected
        setVictimas(victimasCopy)
    }
    
    const handleText = (ev, index) => {
        let victimasCopy = [...victimas]
        victimasCopy[index].value = ev.target.value
        setVictimas(victimasCopy)
    }

    const handleContinue = () => {
        dispatch(setVictimasIndirectas(victimas))
        setQuestion(question + 1)
        setOpen(false)
    }

    useEffect(() => {
        const victimasSelectedFiltereds = victimas.filter(victima => victima.selected)
        setVictimasSelected(victimasSelectedFiltereds)
    }, [victimas])

    return (
        <div className="modalContainer">
            <div className="insertHeader">
                <h1>VÍCTIMAS DE REBOTE</h1>
                <Divider style={{ width: '100%', backgroundColor: '#686868'}}  />
            </div>
            <label className="labelOptionBold">¿Considera que existen víctimas de rebote?</label>
            <div className="btnsContainer">
                <button onClick={() => setYes(true)}>SI</button>
                {!yes && (
                    <button onClick={() => handleNo()}>NO</button>
                )}
            </div>
            {yes && (
                <>
                    <label className="labelOptionBold">Seleccione a continuación cuáles son las víctimas de rebote del daño:</label>
                    <div className="radiosContainer">
                        {victimas.map((victima, index) => (
                            <div className='radioContainer'>
                                <input 
                                    style={{ margin: '0 0 0 10px' }} 
                                    className='radioInput' type="radio" 
                                    id={victima.nombre}
                                    name={victima.nombre} 
                                    value={victima.selected}
                                    checked={victima.selected}
                                    onClick={() => handleSelect(index)}/>
                                <label style={{ margin: '0 0 0 10px' }} className='labelOption' for={victima.nombre}>{capitalize(victima.nombre)}</label>
                            </div>
                        ))}
                    </div>
                </>
            )}
            {yes && (
                <>
                    <label className="labelOptionBold">Cantidad</label>
                    <div className="radiosContainerVictimas backgroundVictimasIndirectas" style={{height: victimasSelected.length === 5 ? '100px' : '40px'}}>
                        {victimas.map((victima, index) => victima.selected && (
                            <div className='radioContainer'>
                                <label style={{ margin: '0 0 0 10px' }} className='labelOption' for={victima.nombre}>{capitalize(victima.nombre)}</label>
                                <input 
                                    style={{ margin: '0 0 0 10px' }} 
                                    className='miniInput' type="number" 
                                    id={victima.nombre}
                                    name={victima.nombre} 
                                    value={victima.value}
                                    checked={victima.selected}
                                    onChange={(ev) => handleText(ev, index)}/>
                            </div>
                        ))}
                    </div>
                </>
            )}
            <div className='continueBtnContainer mt-20 fullWidth'>
                <ContinueButton onClick={() => handleContinue()} text="AÑADIR"/>
            </div>
        </div>
    )
}
export default VictimasIndirectas