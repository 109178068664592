import React from "react"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { Delete } from '@mui/icons-material'
import { setReclamantes } from "../../../store/features/appSlice"

const ReclamantesTable = () => {
    const dispatch = useAppDispatch()
    const reclamantes = useAppSelector(state => state.app.data.reclamantes)

    const handleDelete = indexReclamante => {
        dispatch(setReclamantes(reclamantes.filter((r, index) => index !== indexReclamante)))
    }

    return (
        <div className="table">
            <h1>Lista de Reclamantes</h1>
            <Table className="tablePregunta tableSelect mt-20">
                <TableHead className="tableHead">
                    <TableRow>
                        <TableCell className="textTableHead blue1">Nombre</TableCell>
                        <TableCell className="textTableHead blue2">Parentesco</TableCell>
                        <TableCell className="textTableHead blue3">Cuota de dependencia</TableCell>
                        <TableCell className="textTableHead blue4">Acciones</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {reclamantes.map((reclamante, index) => (
                        <TableRow>
                            <TableCell className="tableCellBody">{reclamante.nombre}</TableCell>
                            <TableCell className="tableCellBody">{reclamante.parentesco}</TableCell>
                            <TableCell className="tableCellBody">${reclamante.monto}</TableCell>
                            <TableCell className="tableCellBody deleteCell" onClick={() => handleDelete(index)}><div className="deleteContainerTable"><Delete style={{ height: 14 }}/>Eliminar</div></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

export default ReclamantesTable