import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import React, { useEffect } from "react"
import { getMoneyFormat } from "../../../libs/helpers"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"

function ResultadosTable() {
    const dispatch = useAppDispatch()

    const data = useAppSelector(state => state.app.data)
    console.log('resultados:', data)

    const getDañoVictimas = () => {
        const victimasCopy = [...data.victimasIndirectas]
        let daño = 0
        victimasCopy.forEach(victima => {
            daño += victima.liquidation
        })
        return daño
    }

    return(
        <Table className="tablePregunta mt-20">
            <TableHead className="tableHead">
                <TableRow>
                    <TableCell className="textTableHead blue1">Daño heredado del causante</TableCell>
                    <TableCell className="textTableHead blue2">Daño moral causado a la víctima por la lesión</TableCell>
                    <TableCell className="textTableHead blue3">Daño moral causado por víctimas de rebote</TableCell>
                    <TableCell className="textTableHead blue2">{data.tipoLiquidacion === 'jurisdiccionAdministrativa' ? 'Daño a la salud' : 'Daño a la vida de relación'}</TableCell>
                    <TableCell className="textTableHead blue1">Total a liquidar</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell className="tableCellBody">{getMoneyFormat(data.consecuencia === 'muerte' && data.dañoMoral)}</TableCell>
                    <TableCell className="tableCellBody">{getMoneyFormat(data.tipoDeIncapacidad === 'permanente' ? data.dañoMoral : 0)}</TableCell>
                    <TableCell className="tableCellBody">{getMoneyFormat(data.tipoDeIncapacidad === 'temporal' ? 0 : getDañoVictimas())}</TableCell>
                    <TableCell className="tableCellBody">{getMoneyFormat(data.tipoDeIncapacidad === 'temporal' ? 0 : data.dañoVidaRelacion )}</TableCell>
                    <TableCell className="tableCellBody">{
                        getMoneyFormat(
                            (data.consecuencia === 'muerte' ? data.dañoMoral : 0) + (data.tipoDeIncapacidad === 'permanente' ? data.dañoMoral : 0) +
                            (data.tipoDeIncapacidad === 'temporal' ? 0 : getDañoVictimas()) + (data.tipoDeIncapacidad === 'temporal' ? 0 : data.dañoVidaRelacion )
                        )
                    }</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default ResultadosTable