import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import React from "react"
import { firtsMayus, getSecondDate } from "../../libs/helpers"

function TableIngresosView({ ingresos }) {

    return (
            <Table className="tablePregunta tableSelect mt-20">
                <TableHead className="tableHead">
                    <TableRow>
                        <TableCell className="textTableHead blue1">Ingreso de suma</TableCell>
                        <TableCell className="textTableHead blue2">Tipo de Ingreso</TableCell>
                        <TableCell className="textTableHead blue3">Rango de fechas</TableCell>
                        <TableCell className="textTableHead blue4">Monto</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ingresos.map((ingreso, index) => (
                        <TableRow>
                            <TableCell className="tableCellBody">{ingreso.unico ? 'Única' : 'Periódica'}</TableCell>
                            <TableCell className="tableCellBody">{firtsMayus(ingreso.tipo)}</TableCell>
                            <TableCell className="tableCellBody">{ingreso.fecha1} {getSecondDate(ingreso)}</TableCell>
                            <TableCell className="tableCellBody">${ingreso.monto}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
    )
}

export default TableIngresosView