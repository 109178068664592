import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { createdStatus, statusOk } from '../../libs/constants'
import config from '../../libs/config'

const initialState = {}

export const createUser = userData =>  async (dispatch) => {
    const response = await axios.post(config.api + '/api/usuario', userData)

    try {
        if (response.status === createdStatus) {
            return response.data
        } 

        throw new Error(response.data.message)

    } catch (error) {
        return error
    }
    

    
}

export const login = userData =>  async (dispatch) => {
    const response = await axios.post(config.api + '/api/usuario/login', userData)

    try {
        if (response.status === statusOk) {
            localStorage.setItem('tokenDla', response.data.token)
            return response.data
        } 

        throw new Error(response.data.message)

    } catch (error) {
        return error
    }   
}

export const verifyUser = token =>  async (dispatch) => {

    try {
        const response = await axios.post(config.api + '/api/usuario/verify', {token})

        if (response.status === statusOk) {
            return response.data
        } 

        throw new Error(response.data.message)

    } catch (error) {
        throw new Error('Error with token')
    }
}

export const sendPin = email =>  async (dispatch) => {
    
        try {
            const response = await axios.post(config.api + '/api/usuario/sendPin', {email})
    
            if (response.status === statusOk) {
                return response.data
            } 
    
            throw new Error(response.data.message)
    
        } catch (error) {
            throw new Error('Error with token')
        }
}

export const resetPassword = userData =>  async (dispatch) => {
    const response = await axios.post(config.api + '/api/usuario/resetPassword', userData)

    try {
        if (response.status === statusOk) {
            return response.data
        } 

        throw new Error(response.data.message)

    } catch (error) {
        return error
    }
}

export const userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {},
    extraReducers: {}
});


export const {  } = userSlice.actions

export default userSlice.reducer
