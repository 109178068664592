import React from "react"
import "./WhatsappButton.css"
import WhatsappButtonIMG from "../../imgs/Icono WA.png"

export default function WhatsappButton() {
    const [open, setOpen] = React.useState(false)

    const handleOpenWhatsapp = () => {
        setOpen(false)
        window.open("https://wa.me/573023938160", "_blank")
    }
    return <div className="ButtonWhatsappContainer">
        {/*  */}
            <img src={WhatsappButtonIMG} className="WhatsappButton" onClick={() => setOpen(!open)} />
            {open && (
                <div className="textContainerWpp">
                    <p>Haga clic <b style={{ cursor: 'pointer', fontWeight: 600 }} onClick={handleOpenWhatsapp}>aquí</b> parasolicitar ayuda porWhatsApp</p>
                </div>
            )}
        {/* </a> */}
    </div>
}