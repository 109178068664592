export const mortalidadHombres = [
    {
        edad: 0,
        anos:  79.8
    },
    {
        edad: 1,
        anos:  78.8
    },
    {
        edad: 2,
        anos:  77.8
    },
    {
        edad: 3,
        anos:  76.8
    },
    {
        edad: 4,
        anos:  75.8
    },
    {
        edad: 5,
        anos:  74.8
    },
    {
        edad: 6,
        anos:  73.8
    },
    {
        edad: 7,
        anos:  72.8
    },
    {
        edad: 8,
        anos:  71.8
    },
    {
        edad: 9,
        anos:  70.8
    },
    {
        edad: 10,
        anos:  69.8
    },
    {
        edad: 11,
        anos:  68.8
    },
    {
        edad: 12,
        anos:  67.8
    },
    {
        edad: 13,
        anos:  66.8
    },
    {
        edad: 14,
        anos:  65.8
    },
    {
        edad: 15,
        anos:  64.8
    },
    {
        edad: 16,
        anos:  63.9
    },
    {
        edad: 17,
        anos:  62.9
    },
    {
        edad: 18,
        anos:  61.9
    },
    {
        edad: 19,
        anos:  60.9
    },
    {
        edad: 20,
        anos:  60.0
    },
    {
        edad: 21,
        anos:  59.0
    },
    {
        edad: 22,
        anos:  58.0
    },
    {
        edad: 23,
        anos:  57.1
    },
    {
        edad: 24,
        anos:  56.1
    },
    {
        edad: 25,
        anos:  55.1
    },
    {
        edad: 26,
        anos:  54.2
    },
    {
        edad: 27,
        anos: 53.2
    },
    {
        edad: 28,
        anos:  52.3
    },
    {
        edad: 29,
        anos: 51.3
    },
    {
        edad: 30,
        anos: 50.3
    },
    {
        edad: 31,
        anos: 49.4
    },
    {
        edad: 32,
        anos: 48.4
    },
    {
        edad: 33,
        anos: 47.5
    },
    {
        edad: 34,
        anos: 46.5 
    },
    {
        edad: 35,
        anos: 45.6
    },
    {
        edad: 36,
        anos: 44.6
    },
    {
        edad: 37,
        anos: 43.7
    },
    {
        edad: 38,
        anos: 42.7
    },
    {
        edad: 39,
        anos: 41.8
    },
    {
        edad: 40,
        anos: 40.8
    },
    {
        edad: 41,
        anos: 39.9
    },
    {
        edad: 42,
        anos: 39.0
    },
    {
        edad: 43,
        anos: 38.0
    },
    {
        edad: 44,
        anos: 37.1
    },
    {
        edad: 45,
        anos: 36.2
    },
    {
        edad: 46,
        anos: 35.3
    },
    {
        edad: 47,
        anos:  34.4 
    },
    {
        edad: 48,
        anos: 33.4
    },
    {
        edad: 49,
        anos: 32.5
    },
    {
        edad: 50,
        anos: 31.6
    },
    {
        edad: 51,
        anos: 30.7
    },
    {
        edad: 52,
        anos: 29.9
    },
    {
        edad: 53,
        anos: 29.0
    },
    {
        edad: 54,
        anos: 28.1
    },
    {
        edad: 55,
        anos: 27.2
    },
    {
        edad: 56,
        anos: 26.4
    },
    {
        edad: 57,
        anos: 25.5
    },
    {
        edad: 58,
        anos: 24.6
    },
    {
        edad: 59,
        anos: 23.8
    },
    {
        edad: 60,
        anos: 23.0
    },
    {
        edad: 61,
        anos: 22.1
    },
    {
        edad: 62,
        anos: 21.3
    },
    {
        edad: 63,
        anos: 20.5
    },
    {
        edad: 64,
        anos: 19.7
    },
    {
        edad: 65,
        anos: 19.0
    },
    {
        edad: 66,
        anos: 18.2
    },
    {
        edad: 67,
        anos: 17.4
    },
    {
        edad: 68,
        anos: 16.7
    },
    {
        edad: 69,
        anos: 16.0
    },
    {
        edad: 70,
        anos: 15.3
    },
    {
        edad: 71,
        anos: 14.6
    },
    {
        edad: 72,
        anos: 14.0
    },
    {
        edad: 73,
        anos: 13.3
    },
    {
        edad: 74,
        anos: 12.7
    },
    {
        edad: 75,
        anos: 12.1
    },
    {
        edad: 76,
        anos: 11.5
    },
    {
        edad: 77,
        anos: 10.9 
    },
    {
        edad: 78,
        anos: 10.4
    },
    {
        edad: 79,
        anos: 9.8
    },
    {
        edad: 80,
        anos: 9.3
    },
    {
        edad: 81,
        anos: 8.8
    },
    {
        edad: 82,
        anos: 8.3
    },
    {
        edad: 83,
        anos: 7.8
    },
    {
        edad: 84,
        anos: 7.4
    },
    {
        edad: 85,
        anos: 7.0
    },
    {
        edad: 86,
        anos: 6.6
    },
    {
        edad: 87,
        anos: 6.2
    },
    {
        edad: 88,
        anos: 5.8
    },
    {
        edad: 89,
        anos: 5.4
    },
    {
        edad: 90,
        anos: 5.1
    },
    {
        edad: 91,
        anos:  4.8
    },
    {
        edad: 92,
        anos: 4.5
    },
    {
        edad: 93,
        anos: 4.2
    },
    {
        edad: 94,
        anos: 3.9
    },
    {
        edad: 95,
        anos: 3.6
    },
    {
        edad: 96,
        anos: 3.3
    },
    {
        edad: 97,
        anos: 3.1
    },
    {
        edad: 98,
        anos: 2.9
    },
    {
        edad: 99,
        anos: 2.6
    },
    {
        edad: 100,
        anos: 2.4
    },
    {
        edad: 101,
        anos: 2.2
    },
    {
        edad: 102,
        anos: 2.1
    },
    {
        edad: 103,
        anos: 1.9
    },
    {
        edad: 104,
        anos: 1.7
    },
    {
        edad: 105,
        anos: 1.6
    },
    {
        edad: 106,
        anos: 1.4
    },
    {
        edad: 107,
        anos: 1.3
    },
    {
        edad: 108,
        anos: 1.1
    },
    {
        edad: 109,
        anos: 0.9
    },
    {
        edad: 110,
        anos: 0.5
    }
]
export const mortalidadMujeres = [
    {
        edad: 0,
        anos:  85.0
    },
    {
        edad: 1,
        anos:  84.0
    },
    {
        edad: 2,
        anos:  83.0
    },
    {
        edad: 3,
        anos:  82.0
    },
    {
        edad: 4,
        anos:  81.0
    },
    {
        edad: 5,
        anos:  80.0
    },
    {
        edad: 6,
        anos:  79.0
    },
    {
        edad: 7,
        anos:  78.0
    },
    {
        edad: 8,
        anos:  77.0
    },
    {
        edad: 9,
        anos:  76.0
    },
    {
        edad: 10,
        anos:  75.0
    },
    {
        edad: 11,
        anos:  74.0
    },
    {
        edad: 12,
        anos:  73.0
    },
    {
        edad: 13,
        anos:  72.0
    },
    {
        edad: 14,
        anos:  71.0
    },
    {
        edad: 15,
        anos:  70.0
    },
    {
        edad: 16,
        anos:  69.1
    },
    {
        edad: 17,
        anos: 68.1
    },
    {
        edad: 18,
        anos: 67.1
    },
    {
        edad: 19,
        anos: 66.1
    },
    {
        edad: 20,
        anos: 65.1
    },
    {
        edad: 21,
        anos: 64.2
    },
    {
        edad: 22,
        anos: 63.2
    },
    {
        edad: 23,
        anos: 62.2
    },
    {
        edad: 24,
        anos: 61.2
    },
    {
        edad: 25,
        anos: 60.2
    },
    {
        edad: 26,
        anos: 59.3
    },
    {
        edad: 27,
        anos: 58.3
    },
    {
        edad: 28,
        anos: 57.3
    },
    {
        edad: 29,
        anos: 56.3
    },
    {
        edad: 30,
        anos: 55.4
    },
    {
        edad: 31,
        anos: 54.4
    },
    {
        edad: 32,
        anos: 53.4
    },
    {
        edad: 33,
        anos: 52.4
    },
    {
        edad: 34,
        anos: 51.5
    },
    {
        edad: 35,
        anos: 50.5
    },
    {
        edad: 36,
        anos: 49.5
    },
    {
        edad: 37,
        anos: 48.6
    },
    {
        edad: 38,
        anos: 47.6
    },
    {
        edad: 39,
        anos: 46.6
    },
    {
        edad: 40,
        anos: 45.7
    },
    {
        edad: 41,
        anos: 44.7
    },
    {
        edad: 42,
        anos: 43.7 
    },
    {
        edad: 43,
        anos: 42.8
    },
    {
        edad: 44,
        anos: 41.8
    },
    {
        edad: 45,
        anos: 40.9
    },
    {
        edad: 46,
        anos: 39.9 
    },
    {
        edad: 47,
        anos: 39.0 
    },
    {
        edad: 48,
        anos: 38.0
    },
    {
        edad: 49,
        anos: 37.1
    },
    {
        edad: 50,
        anos: 36.2
    },
    {
        edad: 51,
        anos: 35.2
    },
    {
        edad: 52,
        anos: 34.3
    },
    {
        edad: 53,
        anos: 33.4
    },
    {
        edad: 54,
        anos: 32.5
    },
    {
        edad: 55,
        anos: 31.6
    },
    {
        edad: 56,
        anos: 30.6
    },
    {
        edad: 57,
        anos: 29.7
    },
    {
        edad: 58,
        anos: 28.8
    },
    {
        edad: 59,
        anos: 27.9
    },
    {
        edad: 60,
        anos: 27.0
    },
    {
        edad: 61,
        anos: 26.2
    },
    {
        edad: 62,
        anos: 25.3
    },
    {
        edad: 63,
        anos: 24.4
    },
    {
        edad: 64,
        anos: 23.5
    },
    {
        edad: 65,
        anos: 22.7
    },
    {
        edad: 66,
        anos: 21.8
    },
    {
        edad: 67,
        anos: 21.0
    },
    {
        edad: 68,
        anos: 20.2
    },
    {
        edad: 69,
        anos: 19.4
    },
    {
        edad: 70,
        anos: 18.6
    },
    {
        edad: 71,
        anos: 17.8
    },
    {
        edad: 72,
        anos: 17.0
    },
    {
        edad: 73,
        anos: 16.2
    },
    {
        edad: 74,
        anos: 15.5
    },
    {
        edad: 75,
        anos: 14.7
    },
    {
        edad: 76,
        anos: 14.0
    },
    {
        edad: 77,
        anos: 13.3
    },
    {
        edad: 78,
        anos: 12.6
    },
    {
        edad: 79,
        anos: 11.9
    },
    {
        edad: 80,
        anos: 11.3
    },
    {
        edad: 81,
        anos: 10.6
    },
    {
        edad: 82,
        anos: 10.0
    },
    {
        edad: 83,
        anos: 9.4
    },
    {
        edad: 84,
        anos: 8.9
    },
    {
        edad: 85,
        anos: 8.3
    },
    {
        edad: 86,
        anos: 7.8
    },
    {
        edad: 87,
        anos: 7.3
    },
    {
        edad: 88,
        anos: 6.8
    },
    {
        edad: 89,
        anos: 6.3
    },
    {
        edad: 90,
        anos: 5.8
    },
    {
        edad: 91,
        anos: 5.4
    },
    {
        edad: 92,
        anos: 5.0
    },
    {
        edad: 93,
        anos: 4.6
    },
    {
        edad: 94,
        anos: 4.2
    },
    {
        edad: 95,
        anos: 3.9
    },
    {
        edad: 96,
        anos: 3.5
    },
    {
        edad: 97,
        anos: 3.2
    },
    {
        edad: 98,
        anos: 3.0
    },
    {
        edad: 99,
        anos: 2.7
    },
    {
        edad: 100,
        anos: 2.5
    },
    {
        edad: 101,
        anos: 2.3
    },
    {
        edad: 102,
        anos: 2.1
    },
    {
        edad: 103,
        anos: 1.9
    },
    {
        edad: 104,
        anos: 1.7
    },
    {
        edad: 105,
        anos: 1.6
    },
    {
        edad: 106,
        anos: 1.4
    },
    {
        edad: 107,
        anos: 1.3
    },
    {
        edad: 108,
        anos: 1.1
    },
    {
        edad: 109,
        anos: 0.9
    },
    {
        edad: 110,
        anos: 0.5
    }
]
