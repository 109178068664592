import React, { useState } from 'react'
import { Link, useNavigate  } from 'react-router-dom'
import './home.css'
import logo from '../../imgs/logoLight.png'
import { Button, Divider } from '@mui/material'
import Modal from '../../sharedComponents/modal/Modal'
import Alert from './Alert/Alert'

function Home () {
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false)
    const [acept, setAccept] = useState(false)

    const closeModal = () => {
        setShowModal(false)
        navigate('/simulador')

    }

    return (
        <div className='container'>
            <div className='dlaLogo'><img src={logo} /></div>
            <div className='welcomeContainer'>
                <h1>Bienvenido(a) a Liquilex</h1>
                <p>Liquilex es un simulador que tiene el objetivo de cuantificar el monto de la indemnización
                    que usted como usuario puede recibir por causa de uno o varios perjuicios sufridos,
                    derivados de hechos constitutivos de responsabilidad civil extracontractual.
                </p>
                <Divider style={{ width: '55%', backgroundColor: '#fff'}}  />
                <p>
                    A continuación, usted deberá diligenciar unos formularios que determinarán, con base en los criterios
                    de la Corte Suprema de Justicia y el Consejo de Estado, cuánto dinero podría eventualmente
                    reclamar por concepto de daño emergente, lucro cesante y perjuicios extrapatrimoniales.
                </p>
                <Divider style={{ width: '55%', backgroundColor: '#fff'}}  />
                {/* <div className='checkContainer'>
                    <input className='checkbox' type='checkbox' checked={acept} onChange={() => setAccept(!acept)}/>
                    <Link to='/politicas-privacidad' className='link' disabledCol>Póliticas de privacidad</Link>
                </div> */}
                <Button style={{ background: acept ? '#ffffff' : '#ffffff' }} variant='contained' className='button-local' onClick={() => setShowModal(true)}>Iniciar simulación</Button>
            </div>
            <Modal showClose={false} open={showModal} onClose={closeModal} content={<Alert closeModal={closeModal} />}/>
        </div>
    )
}

export default Home