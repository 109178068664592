import React, { useEffect, useState } from "react"
import { Divider, IconButton, Tooltip } from "@mui/material"
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { setReclamantes } from "../../../store/features/appSlice"
import Modal from "../../../sharedComponents/modal/Modal"
import { mortalidadHombres, mortalidadMujeres } from '../../../sharedComponents/mortalidades/mortalidades'
import { getFutureDate, getYearsOld } from "../../../libs/helpers"


const InsertReclamante = ({ insertReclamante, setInsertReclamante, setReclamante, reclamante}) => {
    const dispatch = useAppDispatch()

    const data = useAppSelector(state => state.app.data)


    useEffect(() => {
        const mortalidad = reclamante.sexo === 'masculino' ? mortalidadHombres : mortalidadMujeres

        if (reclamante.parentesco === 'hijos'){
            if (reclamante.condicion === 'discapacidad'){
                const yearsOld = getYearsOld(reclamante.fechaNacimiento)
                const expectYears = mortalidad.find(item => item.edad === yearsOld)
                const finalDate = getFutureDate(data.fechaLiquidacion, expectYears.anos)

                setReclamante({...reclamante, fechaInicial: data.fechaHechos, fechaFinal: finalDate}) 
            } else {
                const yearsOld = getYearsOld(reclamante.fechaNacimiento)
                const expectYears = mortalidad.find(item => item.edad === yearsOld)

                const yearsOldFather = getYearsOld(data.fechaNacimiento)
                const expectYearsFather = mortalidad.find(item => item.edad === yearsOldFather)

                if ((25 - yearsOld) < expectYearsFather.anos){
                    const finalDate = getFutureDate(reclamante.fechaNacimiento, 25)
                    setReclamante({...reclamante, fechaInicial: data.fechaHechos, fechaFinal: finalDate})
                } else {
                    const finalDate = getFutureDate(data.fechaLiquidacion, expectYearsFather.anos)
                    setReclamante({...reclamante, fechaInicial: data.fechaHechos, fechaFinal: finalDate})
                }
            }
        }

        if (reclamante.parentesco && reclamante.parentesco === 'padres'){

            if (getYearsOld(data.fechaNacimiento) > 24){
                setReclamante({...reclamante, fechaInicial: '', fechaFinal: '', monto: 0})
            } else if (reclamante.condicion === 'discapacidad'){
                const yearsOld = getYearsOld(reclamante.fechaNacimiento)
                const expectYears = mortalidad.find(item => item.edad === yearsOld)
                const finalDate = getFutureDate(data.fechaLiquidacion, expectYears.anos)

                setReclamante({...reclamante, fechaInicial: data.fechaHechos, fechaFinal: finalDate})
            } else {
                const finalDate = getFutureDate(data.fechaNacimiento, 25)
                
                setReclamante({...reclamante, fechaInicial: data.fechaHechos, fechaFinal: finalDate})
            }
            
        }

        if (reclamante.parentesco && reclamante.parentesco === 'hermanos'){
            if (reclamante.condicion === 'discapacidad') {
                const yearsOld = getYearsOld(reclamante.fechaNacimiento)
                const expectYears = mortalidad.find(item => item.edad === yearsOld)
                const finalDate = getFutureDate(data.fechaLiquidacion, expectYears.anos)

                setReclamante({...reclamante, fechaInicial: data.fechaHechos, fechaFinal: finalDate})
            } else if (getYearsOld(reclamante.fechaNacimiento) > 17){
                setReclamante({...reclamante, fechaInicial: '', fechaFinal: '', monto: 0})
            } else {
                const finalDate = getFutureDate(reclamante.fechaNacimiento, 18)
                
                setReclamante({...reclamante, fechaInicial: data.fechaHechos, fechaFinal: finalDate})
            }  
        }

        if (reclamante.parentesco && reclamante.parentesco === 'conyuge'){
            const yearsOld = getYearsOld(reclamante.fechaNacimiento)
            const expectYears = mortalidad.find(item => item.edad === yearsOld)
            const finalDate = getFutureDate(data.fechaLiquidacion, expectYears.anos)

            setReclamante({...reclamante, fechaInicial: data.fechaHechos, fechaFinal: finalDate})
        }


    }, [insertReclamante])

    const getRuleForReclamante = () => {
        if (reclamante.parentesco && reclamante.parentesco === 'hermanos' && reclamante.condicion !== 'discapacidad'){
            if (getYearsOld(reclamante.fechaNacimiento) > 17){
                return true
            }
        }
        return false
    }

    return <Modal open={insertReclamante} onClose={() => setInsertReclamante(!insertReclamante)} content={
        <div className="modalContainer">
            <div className="modalHeader">
                <h2>CONDICIÓN DE DEPENDENCIA</h2>
            </div>
            <Divider style={{ width: '100%', backgroundColor: '#656565'}}  />
            <label className="labelOptionBold">Monto</label>
            <label className="labelOption">¿Cuánto recibía al mes por parte de la víctima? Ingrese el monto en pesos colombianos, sin puntos ni comas:</label>
            {getRuleForReclamante() && <label className="labelOptionBold" style={{marginTop: 20}}>AVISO: No es dependiente económico dado mayoría de edad</label>}
            <input 
                className="input mt-modalInsert" 
                type="number" 
                onChange={(e) => setReclamante({...reclamante, monto: e.target.value})}
                disabled={getRuleForReclamante()}
                 />
            <label className="labelOptionBold">Rango de Fechas</label>
            <label className="labelOption">Seleccione las fechas en la que víctima debió darle ese ingreso reclamante:</label>
            <div className="dateContainer">
                <div className="dateContainerItem">
                    <label className="labelOption">Fecha inicial</label>
                    <input className="dateSelect widthParcial" type="date" value={reclamante.fechaInicial} disabled={!(reclamante.parentesco === 'otros' || reclamante.parentesco === 'abuelos')} onChange={(e) => setReclamante({...reclamante, fechaInicial: e.target.value})}/>
                </div>
                <div className="dateContainerItem">
                    <label className="labelOption">Fecha final</label>
                    <input className="dateSelect widthParcial" type="date" value={reclamante.fechaFinal} disabled={!(reclamante.parentesco === 'otros' || reclamante.parentesco === 'abuelos')}  onChange={(e) => setReclamante({...reclamante, fechaFinal: e.target.value})}/>
                    <div className="radioContainer" style={{ marginTop: 10 }}>
                        <input type="radio" id="test1" name="radio-group" disabled={!(reclamante.parentesco === 'otros' || reclamante.parentesco === 'abuelos')}  className='radioInput' checked={reclamante.vitalicia} onChange={() => setReclamante({...reclamante, vitalicia: true})}/>
                        <label className="labelOption labelModalReclamante" htmlFor="test1">De forma vitalicia</label>
                    </div>
                </div>
            </div>
            <Divider style={{ width: '100%', backgroundColor: '#656565', marginTop: 20}}  />
            <div className="noAplicaContainer">
                <div className="radioContainer" >
                    <input type="radio" id="test1" name="radio-group" className='radioInput' checked={reclamante.vitalicia} onChange={() => setInsertReclamante(false)}/>
                    <label className="labelOption labelModalReclamante" htmlFor="test1">No aplica</label>
                </div>
            </div>
            <div className="nextBTN">
                <button onClick={() => setInsertReclamante(false)}>SIGUIENTE</button>
            </div>
            
        </div>
    }></Modal>
}

export default InsertReclamante