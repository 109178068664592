import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import './dañoVidaRelacion.css'
import { nextStep, setDañoMoral, setDañoVidaRelacion } from '../../../store/features/appSlice'
import { getMoneyFormat } from '../../../libs/helpers'
import ContinueButton from '../../../sharedComponents/continueButton/ContinueButton'
import { capitalize } from '../../../libs/helpers'
import config from '../../../libs/config'

function DañoVidaRelacion({ question, setQuestion }) {

    const dispatch = useAppDispatch()

    const data = useAppSelector(state => state.app.data)

    const getDañoVidaRelacion= () => {
        let  daño = 0

        if (data.tipoLiquidacion === 'jurisdiccionAdministrativa') {
            const porcentaje = data.porcentajeIncapacidad / 100

            if (porcentaje >= 0.50) {
                daño = config.salarioMinimo * 100
            }

            if (porcentaje < 0.50 && porcentaje >= 0.40) {
                daño = config.salarioMinimo * 80
            }

            if (porcentaje < 0.40 && porcentaje >= 0.30) {
                daño = config.salarioMinimo * 60
            }

            if (porcentaje < 0.30 && porcentaje >= 0.20) {
                daño = config.salarioMinimo * 40
            }

            if (porcentaje < 0.20 && porcentaje >= 0.10) {
                daño = config.salarioMinimo * 20
            }

            if (porcentaje < 0.10 && porcentaje >= 0.01) {
                daño = config.salarioMinimo * 10
            }

        } else {
            if (data.impactoVida === 'alto') {
                daño = 140000000
            }
    
            if (data.impactoVida === 'medio') {
                daño = 70000000
            }
    
            if (data.impactoVida === 'bajo') {
                daño = 20000000
            }
        }

        dispatch(setDañoVidaRelacion(daño))

        return daño
    }


    return (
        <div className='cardPerjuicios'>
            <div className='subContainerPerjuicios'>
                <label className='labelOptionBold'>{data.tipoLiquidacion === 'jurisdiccionAdministrativa' ? 'Daño a la salud' : 'Daño a la vida de relación'}
                            <Tooltip title="El daño a la vida de relación es la imposibilidad de relacionarse en situación de normalidad y disfrutar los placeres de la vida como consecuencias de una afectación física o psíquica.">
                                <IconButton style={{ paddingLeft: 5}}>
                                    <ErrorOutlineIcon style={{ width: 15, color: '#338dc7' }}  />
                                </IconButton>
                            </Tooltip>
                </label>
                <label className='labelOption'>Esto será el daño moral a pagar:</label> 
            </div>
            <Table className="tablePregunta mt-20">
                <TableHead className="tableHead">
                    <TableRow>
                        <TableCell className="textTableHead blue1">Consecuencia del daño para la víctima</TableCell>
                        {data.tipoLiquidacion === 'jurisdiccionAdministrativa' ? 
                            <></> 
                            : 
                            <TableCell className="textTableHead blue2">Afectación a la vida normal de relación</TableCell>
                        }
                        <TableCell className="textTableHead blue3">{data.tipoLiquidacion === 'jurisdiccionAdministrativa' ? 'Daño a la salud' : 'Daño a la vida de relación'}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell className="tableCellBody">Incapacidad permanente</TableCell>
                        {data.tipoLiquidacion === 'jurisdiccionAdministrativa' ? 
                            <></> 
                            : 
                            <TableCell className="tableCellBody">{capitalize(data.impactoVida)}</TableCell>
                        }
                        <TableCell className="tableCellBody">{getMoneyFormat(getDañoVidaRelacion())}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <div className='continueBtnContainer'>
                <ContinueButton onClick={() => setQuestion(question + 1)} />
            </div>
        </div>
    )
}

export default DañoVidaRelacion