import { ArrowDropDown, Delete } from "@mui/icons-material"
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { setLucroCensateFuturos, setLucroCesantePasados, setReclamantes, setStep } from "../../../store/features/appSlice"
import { getMoneyFormat } from "../../../libs/helpers"
import AlertToBack from "./alertToBack/AlertToBack"
import Modal from "../../../sharedComponents/modal/Modal"
import ContinueButton from "../../../sharedComponents/continueButton/ContinueButton"
import moment from "moment"

function ReclamantesGestor({ setPregunta }) {
    const dispatch = useAppDispatch()

    const data = useAppSelector(state => state.app.data)

    const [insert, setInsert] = useState(false)
    const [openModal, setOpenModal] = useState(false)

    const selectReclamante = index => {
        const newReclamantes = data.reclamantes.map((reclamante, i) => {
            if (i === index) {
                const newReclamante = {...reclamante, selected: true}
                return newReclamante
            }
            return reclamante
        })
        dispatch(setReclamantes(newReclamantes))
    }

    const deleteReclamante = index => {
        const newReclamantes = data.reclamantes.filter((reclamante, i) => i !== index)
        dispatch(setReclamantes(newReclamantes))
    }

    const handleNext = () => {
        const newLucroPasado = []
        const newLucroFuturo = []

        data.reclamantes.forEach(reclamante => {
            if (reclamante.selected) {
                if (moment(data.fechaLiquidacion).diff(moment(reclamante.fechaInicial), 'days') > 0) {
                    if (moment(data.fechaLiquidacion).isBefore(moment(reclamante.fechaFinal))) {
                        const newReclamanteLucro1 = {
                            fecha1: reclamante.fechaInicial,
                            fecha2: data.fechaLiquidacion,
                            monto: reclamante.monto,
                            tipo: `Reclamante - ${reclamante.nombre}`,
                            unico: false
                        }
                        const newReclamanteLucro2 = {
                            fecha1: data.fechaLiquidacion,
                            fecha2: reclamante.fechaFinal,
                            monto: reclamante.monto,
                            tipo: `Reclamante - ${reclamante.nombre}`,
                            unico: false
                        }
                        
                        newLucroPasado.push(newReclamanteLucro1)
                        newLucroFuturo.push(newReclamanteLucro2)
                    } else {
                        const newReclamanteLucro = {
                            fecha1: reclamante.fechaInicial,
                            fecha2: reclamante.fechaFinal,
                            monto: reclamante.monto,
                            tipo: `Reclamante - ${reclamante.nombre}`,
                            unico: false
                        }

                        newLucroPasado.push(newReclamanteLucro)
                    }
                } else {
                    const newReclamanteLucro = {
                        fecha1: reclamante.fechaInicial,
                        fecha2: reclamante.fechaFinal,
                        monto: reclamante.monto,
                        tipo: `Reclamante - ${reclamante.nombre}`,
                        unico: false
                    }
                        newLucroFuturo.push(newReclamanteLucro)
                }
            }
        })

        dispatch(setLucroCesantePasados(newLucroPasado))
        dispatch(setLucroCensateFuturos(newLucroFuturo))

        setPregunta(null)
    }

    useEffect(() => {
        if (insert) {
            dispatch(setStep(3))
            setInsert(false)
        }
    }, [insert])

    return (
        <div className="cardContainerTable">
             <div className="rowTable">
                <label className="labelOptionBold">Lista de Reclamantes</label>
                <label className="labelOption">A continuación, seleccione o añada los montos que quiere liquidar</label>
             </div>
            <Table className="tablePregunta tableSelect mt-20">
                <TableHead className="tableHead">
                    <TableRow>
                        <TableCell className="textTableHead blue1">Nombre</TableCell>
                        <TableCell className="textTableHead blue3">Parentesco con la víctima</TableCell>
                        <TableCell className="textTableHead blue1">Condición de dependencia</TableCell>
                        <TableCell className="textTableHead blue3">Monto</TableCell>
                        <TableCell className="textTableHead blue2">Acciones</TableCell>
                        <TableCell className="textTableHead blue1 cellCustomWidth"><ArrowDropDown style={{ fontSize: '36px' }}/></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.reclamantes.map((reclamante, index) => (
                        <TableRow>
                            <TableCell className="tableCellBody">{reclamante.nombre}</TableCell>
                            <TableCell className="tableCellBody">{reclamante.parentesco}</TableCell>
                            <TableCell className="tableCellBody">{reclamante.dependenciaEconomica}</TableCell>
                            <TableCell className="tableCellBody">{getMoneyFormat(reclamante.monto)}</TableCell>
                            <TableCell className="tableCellBody deleteCell" onClick={() => deleteReclamante(index)}><div className="deleteContainerTable"><Delete style={{ height: 14 }}/>Eliminar</div></TableCell>
                            <TableCell className="tableCellBody cellCustomWidth"><input type="radio" className="radioInput" onChange={() => selectReclamante(index)} /></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div className='editBtnsDaño'>
                <button onClick={() => setOpenModal(true)} className="insertButtonDaño" style={{ marginTop: 0 }}>AÑADIR INGRESOS</button>
                <ContinueButton onClick={() => handleNext()} text="Siguiente"/>
            </div>
            <Modal open={openModal} onClose={() => setOpenModal(false)} showClose={false} background="#0D78BC" content={
                <AlertToBack setInsert={setInsert} setOpenModal={setOpenModal} />
            } />
        </div>
    )
}

export default ReclamantesGestor