import React from "react"
import './alertCapacidad.css'
import iconAlert from '../../../../imgs/ic7.png'
import { Divider } from "@mui/material"

function AlertCapacidad({onClose}) {
    return (
        <div className="alertCapacidadContainer">
            <img src={iconAlert} alt="logo" className="iconAlert"/>
            <h1>¡ATENCIÓN!</h1>
            <Divider style={{ width: '100%', backgroundColor: '#fff'}}  />
            <p>Es importante buscar la opnión de un abogado para certificar la pérdida de capacidad laboral.</p>
            <button className="blueBTN" onClick={() => onClose()}>ENTENDIDO</button>
        </div>  
    )
}

export default AlertCapacidad