import React from 'react'
import Header from '../../sharedComponents/Header'
import './resetPassword.css'
import ResetPasswordForm from './ResetPasswordForm'

function ResetPassword() {
    return (
        <div className='resetPasswordLayout'>
            <Header />
            <div className='resetContainer'>
                <ResetPasswordForm />
            </div>
        </div>
    )
}

export default ResetPassword