import React, { useState } from "react"
import './login.css'
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { login } from "../../store/features/userSlice";

function LoginForm() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [userData, setUserData] = useState({
        acepta_terminos:  false,
        email: '',
        contrasena: ''
    });

    const handleRedirectRegister = () => {
        navigate('/register')
    }

    const handleLogin = () => {
        dispatch(login(userData)).then((res) => {
            navigate('/')
        }).catch((err) => {
            alert('Error al iniciar sesión')
        })
    }

    return (
        <div className="LoginFormContainer">
            <div className="loginForm">
                <h1 className="loginFormTitle">INICIAR SESIÓN</h1>
                <label className="labelOption loginFormMargin">Correo electrónico</label>
                <input className="loginFormInput loginFormMargin" type="email" onChange={ev => setUserData({...userData, email: ev.target.value})}></input>

                <label className="labelOption loginFormMargin">Contraseña</label>
                <input className="loginFormInput loginFormMargin" type="password" onChange={ev => setUserData({...userData, contrasena: ev.target.value})}></input>

                <div className="loginFormButtonContainer">
                    <button className="loginFormButton" onClick={handleLogin}>Iniciar sesión</button>
                    <a className="loginFormLink" href="/resetPassword">¿Olvidó su contraseña?</a>
                    {/* <Divider style={{ width: '80%', backgroundColor: '#455064', height: '0.3px', marginTop: '20px'}}  /> */}
                </div>
                {/* <button className="loginFormButtonCreate" onClick={handleRedirectRegister}>CREAR UNA CUENTA</button> */}
            </div>
        </div>
    )
}

export default LoginForm;