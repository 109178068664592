import React, { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { nextStep, setTotalPerjuicios } from "../../../store/features/appSlice"
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import ContinueButton from "../../../sharedComponents/continueButton/ContinueButton"
import { getMoneyFormat } from "../../../libs/helpers"
import ResultadosTable from "./resultadosTable"
import ProbabilityLoss from "../ProbabilityLoss/ProbabilityLoss"

function Resultados() {
    const dispatch = useAppDispatch()
    const data = useAppSelector(state => state.app.data)

    const [openQuestion, setOpenQuestion] = useState(false)

    const getDañoVictimas = () => {
        const victimasCopy = [...data.victimasIndirectas]
        let daño = 0
        victimasCopy.forEach(victima => {
            daño += victima.liquidation
        })
        return daño
    }

    const handleNext = () => {
        
        dispatch(setTotalPerjuicios((data.consecuencia === 'muerte' ? data.dañoMoral : 0) + (data.tipoDeIncapacidad === 'permanente' ? data.dañoMoral : 0) +
        (data.tipoDeIncapacidad === 'temporal' ? 0 : getDañoVictimas()) + (data.tipoDeIncapacidad === 'temporal' ? 0 : data.dañoVidaRelacion )))
        dispatch(nextStep())
    }

    const handleOpenQuestion = () => {
        setOpenQuestion(true)
    }

    return(
        <div className='cardPerjuicios'>
            <div className='subContainerPerjuicios'>
                <label className='labelOptionBold'>Resultados</label>
            </div>
            <ResultadosTable />
            <div className='continueBtnContainer'>
                <ContinueButton onClick={() => handleOpenQuestion()} />
            </div>
            <ProbabilityLoss open={openQuestion} setOpen={setOpenQuestion} next={handleNext} />
        </div>
    )
    
}

export default Resultados