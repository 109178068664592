import React from 'react'
import { Dialog, DialogContent, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import './modal.css'

function Modal({content, open, onClose, showClose = true, background, width = 'auto'}){
    return (
        <Dialog open={open} onClose={onClose}  PaperComponent="div" fullWidth >
            {showClose && (
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={onClose}
                    aria-label="close"
                    sx={{
                    position: 'absolute',
                    right: 30,
                    top: 10,
                    backgroundColor: '#DFDFDF',
                    color: '#606060'
                    }}
                >
                    <CloseIcon />
                </IconButton>
            )}
            <DialogContent style={{ backgroundColor: background || '#fffffff5' }} className='modal-content'>
                {content}
            </DialogContent>
        </Dialog>
    )
}

export default Modal;