import React, { useState } from "react";
import IpcInsert from "./insertipc";
import IpcTable from "./ipcTable";

function Ipc() {
    const [recargar, setRecargar] = useState(0)
    return (
        <div style={{ padding : 20}}>
            <IpcTable recargar={recargar} setRecargar={setRecargar}/>
            <IpcInsert recargar={recargar} setRecargar={setRecargar}/>
        </div>
    )
}

export default Ipc;