import React from "react";
import logo from '../../imgs/logoLight.png'
import './initialWelcome.css'

function InitialWelcome() {
    return (
        <div className="initialWelcomeContainer">
            <div className='InitialWelcomelogoContainer'><img src={logo} /></div>
            <div className="InitialWelcomeseparator">.</div>
            <div className="initialWelcomeTextContainer">
                <h1>Bienvenido(a) a Liquilex</h1>
                <p>Una herramienta virtual de liquidación de pérdidas que permite a los
                    usuarios establecer el monto más probable de las indemnizaciones que
                    ordenaría una corte en Colombia, por eventos de muerte, lesión
                    permanente o lesión temporal de una persona.
                </p>
            </div>
        </div>
    )
}

export default InitialWelcome;