import React, { useEffect, useState, useRef } from "react"
import './dañoTableEdit.css'
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import moment from "moment"
import Axios from "axios"
import config from "../../../libs/config"
import { backStep, nextStep, setDañoEmergenteFuturos, setDañoEmergentePasados } from "../../../store/features/appSlice"
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import ContinueButton from "../../../sharedComponents/continueButton/ContinueButton"
import { getMoneyFormat, getSecondDate } from "../../../libs/helpers"
import { Delete } from '@mui/icons-material'

function DañoTableEdit({setInsert, setQuoted}){
    const dispatch = useAppDispatch()

    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    const miDiv = useRef(null)

    const gastos = useAppSelector(state => state.app.data.dañoEmergente.pasados)
    const gastosFuturos = useAppSelector(state => state.app.data.dañoEmergente.futuros)

    const [ipcs, setIpcs] = useState([])
    const [calculoPasado, setCalculoPasado] = useState(0)
    const [calculoFuturo, setCalculoFuturo] = useState(0)

    const getIpcs = async () => {
        await Axios.get(`${config.api}/api/ipc`). then(response => {
            setIpcs(response.data)
        })
    }

    const handleNext = () => {
        dispatch(nextStep())
    }

    const handleBack = () => {
        dispatch(backStep())
    }

    function diffMonths(date1, date2) {
        var diffDays = (date2 - date1) / (1000 * 60 * 60 * 24);
        var diffMonths = diffDays / 30;
        return diffMonths.toFixed(2); // redondear a 2 decimales
    }

    const getPeriod = ingreso => {
        //meses
        if (ingreso.unico) {
            return '1 mes'
        } else {
            const diff = Math.ceil(moment(ingreso.fecha2).diff(moment(ingreso.fecha1), 'months', true))
            console.log({diff})
            return `${diff} meses`
        }
    }

    const handleDelete = (index, type) => {
        if (type === 'pasado') {
            dispatch(setDañoEmergentePasados(gastos.filter((r, indexGasto) => indexGasto !== index)))
        } else {
            dispatch(setDañoEmergenteFuturos(gastosFuturos.filter((r, indexGasto) => indexGasto !== index)))
        }
    }

    const getGastoActualizado = (gasto) => {
        if (ipcs.length === 0) return gasto.monto

        if (moment().diff(moment(gasto.fecha1), 'days') > 0) {
            if (gasto.unico){
                const fecha = moment(gasto.fecha1) 
                const mes = moment(fecha).format('MM');
                const anio = moment(fecha).format('YYYY');

                const fechaHoy = moment() 
                const mesHoy = moment(fechaHoy).format('MM');
                const anioHoy = moment(fechaHoy).format('YYYY');

                const diferenciaEnMeses = diffMonths(fecha, fechaHoy);

                console.log({diferenciaEnMesesPasadoUnico: diferenciaEnMeses})

                const ipcInicial = ipcs.filter(ipc => ipc.ano === parseInt(anio) && ipc.mes === meses[parseInt(mes) - 1])[0]
                const ipcFinal = ipcs.filter(ipc => ipc.ano === parseInt(anioHoy) && ipc.mes === meses[parseInt(mesHoy) - 1])[0]

                let sa = gasto.monto * (ipcFinal.valor / ipcInicial.valor)

                let s = sa * (1 + 0.004867)** (diferenciaEnMeses + 1)

                return s
            } else {

                const fecha1 = moment(gasto.fecha1) 
                const mes1 = moment(fecha1).format('MM');
                const anio1 = moment(fecha1).format('YYYY');

                const fecha2 = moment(gasto.fecha2) 
                const mes2 = moment(fecha2).format('MM');
                const anio2 = moment(fecha2).format('YYYY');

                const diferenciaEnMeses = diffMonths(fecha1, fecha2);

                console.log({diferenciaEnMesesPasadoPeriodico: diferenciaEnMeses})

                const ipcInicial = ipcs.filter(ipc => ipc.ano === parseInt(anio1) && ipc.mes === meses[parseInt(mes1) - 1])[0]
                const ipcFinal = ipcs.filter(ipc => ipc.ano === parseInt(anio2) && ipc.mes === meses[parseInt(mes2) - 1])[0]

                let sa = gasto.monto * (ipcFinal.valor / ipcInicial.valor)

                let s = sa * ((((1 + 0.004867)** diferenciaEnMeses) - 1) / 0.004867)
                return s
                
            }
        } else {
            if (gasto.unico){
                const fecha = moment(gasto.fecha1) 
                const mes = moment(fecha).format('MM');
                const anio = moment(fecha).format('YYYY');
                const dia = moment(fecha).format('DD');
                
                const fechaHoy = moment() 
                const mesHoy = moment(fechaHoy).format('MM');
                const anioHoy = moment(fechaHoy).format('YYYY');
                const diahoy = moment(fechaHoy).format('DD');

                const diferenciaEnMeses = (anioHoy - anio) * 12 + (mesHoy - mes) + (diahoy - dia) * 30;

                console.log({diferenciaEnMesesUnicoFuturo: diferenciaEnMeses})

                let s = gasto.monto * (1 / (1 + 0.004867) ** (diferenciaEnMeses + 1))

                return s
            } else {

                const fecha1 = moment(gasto.fecha1) 
                const mes1 = moment(fecha1).format('MM');
                const anio1 = moment(fecha1).format('YYYY');
                const dia1 = moment(fecha1).format('DD');
                
                const fecha2 = moment(gasto.fecha2) 
                const mes2 = moment(fecha2).format('MM');
                const anio2 = moment(fecha2).format('YYYY');
                const dia2 = moment(fecha1).format('DD');

                const diferenciaEnMeses = (anio2 - anio1) * 12 + (mes2 - mes1) + (dia2 - dia1) * 30

                console.log({diferenciaEnMesesPeriodicoFuturo: meses})

                let s = gasto.monto * ((
                    ((1 + 0.004867)** diferenciaEnMeses) - 1) / (0.004867 * ((1 + 0.004867)** diferenciaEnMeses)))

                return s

            }
        }
    }


    useEffect(() => {
        getIpcs()
    }, [])

    
    useEffect(() => {
        let localPasados = 0
        let localFuturos = 0

        if (ipcs.length > 0 && gastos.length > 0) {
            gastos.forEach(gasto => {
                if (gasto.unico){
                    const fecha = moment(gasto.fecha1) 
                    const mes = moment(fecha).format('MM');
                    const anio = moment(fecha).format('YYYY');
    
                    const fechaHoy = moment() 
                    const mesHoy = moment(fechaHoy).format('MM');
                    const anioHoy = moment(fechaHoy).format('YYYY');
    
                    const diferenciaEnMeses = diffMonths(fecha, fechaHoy);

                    console.log({diferenciaEnMesesPasadoUnico: diferenciaEnMeses})
    
                    const ipcInicial = ipcs.filter(ipc => ipc.ano === parseInt(anio) && ipc.mes === meses[parseInt(mes) - 1])[0]
                    const ipcFinal = ipcs.filter(ipc => ipc.ano === parseInt(anioHoy) && ipc.mes === meses[parseInt(mesHoy) - 1])[0]
    
                    let sa = gasto.monto * (ipcFinal.valor / ipcInicial.valor)
    
                    let s = sa * (1 + 0.004867)** (diferenciaEnMeses + 1)
    
                    localPasados += s
                } else {

                    const fecha1 = moment(gasto.fecha1) 
                    const mes1 = moment(fecha1).format('MM');
                    const anio1 = moment(fecha1).format('YYYY');

                    const fecha2 = moment(gasto.fecha2) 
                    const mes2 = moment(fecha2).format('MM');
                    const anio2 = moment(fecha2).format('YYYY');
    
                    const diferenciaEnMeses = diffMonths(fecha1, fecha2);

                    console.log({diferenciaEnMesesPasadoPeriodico: diferenciaEnMeses})
    
                    const ipcInicial = ipcs.filter(ipc => ipc.ano === parseInt(anio1) && ipc.mes === meses[parseInt(mes1) - 1])[0]
                    const ipcFinal = ipcs.filter(ipc => ipc.ano === parseInt(anio2) && ipc.mes === meses[parseInt(mes2) - 1])[0]
    
                    let sa = gasto.monto * (ipcFinal.valor / ipcInicial.valor)
    
                    let s = sa * ((((1 + 0.004867)** diferenciaEnMeses) - 1) / 0.004867)
                    localPasados += s
                    
                }
            })
        }

        setCalculoPasado(localPasados)

        if (ipcs.length > 0 && gastosFuturos.length > 0) {
            gastosFuturos.forEach(gasto => {
                if (gasto.unico){
                    const fecha = moment(gasto.fecha1) 
                    const mes = moment(fecha).format('MM');
                    const anio = moment(fecha).format('YYYY');
                    const dia = moment(fecha).format('DD');
                    
                    const fechaHoy = moment() 
                    const mesHoy = moment(fechaHoy).format('MM');
                    const anioHoy = moment(fechaHoy).format('YYYY');
                    const diahoy = moment(fechaHoy).format('DD');
    
                    const diferenciaEnMeses = (anioHoy - anio) * 12 + (mesHoy - mes) + (diahoy - dia) * 30;
    
                    console.log({diferenciaEnMesesUnicoFuturo: diferenciaEnMeses})
    
                    let s = gasto.monto * (1 / (1 + 0.004867) ** (diferenciaEnMeses + 1))
    
                    localFuturos += s
                } else {

                    const fecha1 = moment(gasto.fecha1) 
                    const mes1 = moment(fecha1).format('MM');
                    const anio1 = moment(fecha1).format('YYYY');
                    const dia1 = moment(fecha1).format('DD');
                    
                    const fecha2 = moment(gasto.fecha2) 
                    const mes2 = moment(fecha2).format('MM');
                    const anio2 = moment(fecha2).format('YYYY');
                    const dia2 = moment(fecha1).format('DD');
    
                    const diferenciaEnMeses = (anio2 - anio1) * 12 + (mes2 - mes1) + (dia2 - dia1) * 30

                    console.log({diferenciaEnMesesPeriodicoFuturo: meses})
    
                    let s = gasto.monto * ((
                        ((1 + 0.004867)** diferenciaEnMeses) - 1) / (0.004867 * ((1 + 0.004867)** diferenciaEnMeses)))
    
                    localFuturos += s
    
                }
            })
        }

        setCalculoFuturo(localFuturos)
        
    }, [ipcs, gastos, gastosFuturos])

    return (
    <div className="cardContainerTable">
        <div className="rowTable">
            <label className="labelOptionBold">Gastos</label>
            <label className="labelOption">Por favor, inserte los gastos efectuados y la información correspondiente para cada uno</label>
        </div>
            <Table className="tablePregunta tableSelect mt-20">
                <TableHead className="tableHead">
                    <TableRow>
                        <TableCell className="textTableHead blue1">Gasto</TableCell>
                        <TableCell className="textTableHead blue1">Tipo de gasto</TableCell>
                        <TableCell className="textTableHead blue2">Fecha</TableCell>
                        <TableCell className="textTableHead blue1">Monto</TableCell>
                        <TableCell className="textTableHead blue4">Acciones</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {gastos.map((ingreso, index) => (
                        <TableRow>
                            <TableCell className="tableCellBody">{ingreso.unico ? 'Único' : 'Periódico'}</TableCell>
                            <TableCell className="tableCellBody">{ingreso.tipo}</TableCell>
                            <TableCell className="tableCellBody">{ingreso.fecha1} {getSecondDate(ingreso)}</TableCell>
                            <TableCell className="tableCellBody">{getMoneyFormat(ingreso.monto)}</TableCell>
                            <TableCell className="tableCellBody deleteCell" onClick={() => handleDelete(index, 'pasado')}><div className="deleteContainerTable"><Delete style={{ height: 14 }}/>Eliminar</div></TableCell>
                        </TableRow>
                    ))}
                    {gastosFuturos.map((ingreso, index) => (
                        <TableRow>
                            <TableCell className="tableCellBody">{ingreso.unico ? 'Único' : 'Periódico'}</TableCell>
                            <TableCell className="tableCellBody">{ingreso.tipo}</TableCell>
                            <TableCell className="tableCellBody">{ingreso.fecha1} {getSecondDate(ingreso)}</TableCell>
                            <TableCell className="tableCellBody">{getMoneyFormat(ingreso.monto)}</TableCell>
                            <TableCell className="tableCellBody deleteCell" onClick={() => handleDelete(index, 'futuro')}><div className="deleteContainerTable"><Delete style={{ height: 14 }}/>Eliminar</div></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div className='editBtnsDaño'>
                {/* <BackButton onClick={() => dispatch(backStep()) } /> */}
                <button onClick={() => setInsert(true)} className="insertButtonDaño">AÑADIR GASTOS</button>
                <ContinueButton onClick={() => setQuoted(true)} text="Siguiente"/>
        </div>
    </div>)
}

export default DañoTableEdit