import React from "react"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { setTipoDeLiquidacion } from "../../../store/features/appSlice"


function LiquidationType() {
    const dispatch = useAppDispatch()

    const data = useAppSelector(state  => state.app.data)

    return (
        <>
            <label className="labelOptionBold smallWidth2" >¿Este es un caso que se tramita o debe tramitarse por la jurisdicción civil o la jurisdicción contencioso-administrativa?</label>
            <div className='radiosContainer'>
                <div className='radioContainer'>
                    <input 
                        style={{ margin: '0 0 0 0' }} 
                        // checked={reclamante.sexoChecked}   
                        className='radioInput' 
                        type="radio" 
                        id="jurisdiccionCivil" 
                        name="tipoLiquidacion" 
                        value="jurisdiccionCivil" 
                        onChange={ev => dispatch(setTipoDeLiquidacion(ev.target.value))}
                    />
                    <label style={{ margin: '0 0 0 10px' }} className='labelOption' for="jurisdiccionCivil">Jurisdicción civil</label>
                </div>
                <div className='radioContainer'>
                    <input 
                        style={{ margin: '0 0 0 10px' }} 
                        // checked={reclamante.sexoChecked} 
                        className='radioInput' 
                        type="radio" 
                        id="jurisdiccionAdministrativa" 
                        name="tipoLiquidacion" 
                        value="jurisdiccionAdministrativa" 
                        onChange={ev => dispatch(setTipoDeLiquidacion(ev.target.value))}
                    />
                    <label style={{ margin: '0 0 0 10px' }} className='labelOption' for="jurisdiccionAdministrativa">Jurisdicción contencioso-administrativa</label>
                </div>
            </div>
        </>
    )
}

export default LiquidationType