import React, { useEffect } from "react"
import './dañoVictimas.css'
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { firtsMayus, getMoneyFormat } from "../../../libs/helpers"
import ContinueButton from "../../../sharedComponents/continueButton/ContinueButton"
import { setVictimasIndirectas } from "../../../store/features/appSlice"
import config from "../../../libs/config"

function DañoVictimas({ question, setQuestion }) {
    const dispatch = useAppDispatch()

    const data = useAppSelector(state => state.app.data)

    const getDañoMoral = () => {
        let  dañoMoralReturn = 0
        let victimasCopy = [...data.victimasIndirectas]
        
        if (victimasCopy.length > 0) {
            victimasCopy.forEach((victima, index) => {
                if (victima.selected) {
                    if (victima.nombre === 'padres' || victima.nombre === 'hijos' || victima.nombre === 'conyuge') {
                        let initValor = 60000000

                        if (data.tipoLiquidacion === 'jurisdiccionAdministrativa') {
                            const porcentaje = data.porcentajeIncapacidad / 100
                            initValor = 100 * config.salarioMinimo

                            //si se lesiona
                            if (data.consecuencia === 'incapacidad') {
                                if (porcentaje >= 0.50) {
                                    initValor = 100 * config.salarioMinimo
                                }

                                if (porcentaje < 0.50 && porcentaje >= 0.40) {
                                    initValor = 80 * config.salarioMinimo
                                }

                                if (porcentaje < 0.40 && porcentaje >= 0.30) {
                                    initValor = 60 * config.salarioMinimo
                                }

                                if (porcentaje < 0.30 && porcentaje >= 0.20) {
                                    initValor = 40 * config.salarioMinimo
                                }

                                if (porcentaje < 0.20 && porcentaje >= 0.10) {
                                    initValor = 20 * config.salarioMinimo
                                }

                                if (porcentaje < 0.10) {
                                    initValor = 10 * config.salarioMinimo
                                }

                                dañoMoralReturn = initValor
                            } else {
                                dañoMoralReturn = initValor
                            }
                        } else {
                            //si se incapacita en liquidación normal
                            if (data.consecuencia === 'incapacidad') {
                                dañoMoralReturn = initValor * data.porcentajeIncapacidad / 100
                            } else {
                                const porcentaje = data.consecuencia === 'muerte' ? 1 : (data.porcentajeIncapacidad / 100)
                                dañoMoralReturn = initValor * porcentaje
                            } 
                        }
                    }
                    if (victima.nombre === 'abuelos' || victima.nombre === 'hermanos') {
                        let initValor = 30000000

                        if (data.tipoLiquidacion === 'jurisdiccionAdministrativa') {
                            const porcentaje = data.porcentajeIncapacidad / 100
                            initValor = 50 * config.salarioMinimo

                            //si se lesiona
                            if (data.consecuencia === 'incapacidad') {
                                if (porcentaje >= 0.50) {
                                    initValor = 50 * config.salarioMinimo
                                }

                                if (porcentaje < 0.50 && porcentaje >= 0.40) {
                                    initValor = 40 * config.salarioMinimo
                                }

                                if (porcentaje < 0.40 && porcentaje >= 0.30) {
                                    initValor = 30 * config.salarioMinimo
                                }

                                if (porcentaje < 0.30 && porcentaje >= 0.20) {
                                    initValor = 20 * config.salarioMinimo
                                }

                                if (porcentaje < 0.20 && porcentaje >= 0.10) {
                                    initValor = 10 * config.salarioMinimo
                                }

                                if (porcentaje < 0.10) {
                                    initValor = 5 * config.salarioMinimo
                                }

                                dañoMoralReturn = initValor
                            } else {
                                dañoMoralReturn = initValor * data.porcentajeIncapacidad / 100
                            }
                        } else {
                            if (data.consecuencia === 'incapacidad') {
                                dañoMoralReturn = initValor * data.porcentajeIncapacidad / 100
                            } else {
                                dañoMoralReturn = data.consecuencia === 'muerte' ? 30000000 : initValor
                            }
                        }

                    }
                    victimasCopy[index] = {...victima, liquidation: (dañoMoralReturn * victima.value)}
                }
                
            })

            dispatch(setVictimasIndirectas(victimasCopy))
        }
    }

    useEffect(()=> {
        getDañoMoral()
    }, [])

    return(
        <div className='cardPerjuicios'>
            <div className='subContainerPerjuicios'>
                <label className='labelOptionBold'>Daño moral causado por víctimas de rebote
                            {/* <Tooltip title="El daño moral es la tristeza, aflicción o angustia derivada del daño.">
                                <IconButton style={{ paddingLeft: 5}}>
                                    <ErrorOutlineIcon style={{ width: 15, color: '#338dc7' }}  />
                                </IconButton>
                            </Tooltip> */}
                </label>
                <label className='labelOption'>Este será el daño moral a pagar:</label> 
            </div>
            <Table className="tablePregunta mt-20">
                <TableHead className="tableHead">
                    <TableRow>
                        <TableCell className="textTableHead blue1">Parentesco</TableCell>
                        <TableCell className="textTableHead blue2">Cantidad</TableCell>
                        <TableCell className="textTableHead blue3">Daño moral causado por víctimas de rebote</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.victimasIndirectas.map((victima, index) => (
                        victima.selected && (
                            <>
                                <TableRow>
                                    <TableCell className="tableCellBody">{firtsMayus(victima.nombre)}</TableCell>
                                    <TableCell className="tableCellBody">{victima.value}</TableCell>
                                    <TableCell className="tableCellBody">{victima.liquidation === 0 ? 'No aplica' : getMoneyFormat(victima.liquidation)}</TableCell>
                                </TableRow>
                            </>
                        )
                    ))}
                </TableBody>
            </Table>
            <div className='continueBtnContainer'>
                <ContinueButton onClick={() => setQuestion(question + 1)} />
            </div>
        </div>
    )
}

export default DañoVictimas