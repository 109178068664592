import React from 'react'
import './alert.css'
import { Button, Divider } from '@mui/material'
import AlertIcon from '../../../imgs/ic5.png'
import { Link } from 'react-router-dom'

function Alert({closeModal}) {
    return (
        <div className='alertContainer'>
            <div className='AlertHeaderContainer'>
                <img src={AlertIcon} />
                <h1>AVISO LEGAL:</h1>
            </div>
            <Divider style={{ width: '90%', backgroundColor: '#686868'}}  />
            <p className='AlertText'>
                La liquidación realizada por Liquilex tiene efectos meramente ilustrativos y no constituye un
                examen actuarial, financiero y/o pericial ni es vinculante para las autoridades. La
                cuantificación hecha por la plataforma se basa en la normatividad vigente, especialmente
                desarrollada por los jueces. Sin embargo, cada caso tiene una variable o vicisitud que puede modificar por completo el análisis.
            </p>
            <p className='alertSubText'>Por ello, Liquilex no se hace responsable por el uso incorrecto de los resultados obtenidos.</p>
            <div className='AlertSubTitle'>Al continuar, acepta la <Link className='alertLink' to='/politicas-privacidad'> Política de Tratamiento de Datos Personales.</Link></div>
            <Button className='alertContinueButton' onClick={closeModal}>Continuar</Button>
        </div>
    )
}

export default Alert