import React, { useState, useEffect } from "react"
import './capacidad.css'
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { setDesarrolloVida, setFechasIncapacidad, setImpactoVida, setIncapacidad, setPorcentajeIncapacidad } from "../../../store/features/appSlice"
import { Button, Divider, IconButton, Select, TextField, Tooltip, Modal, Box, Slider } from "@mui/material"
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import SelectIngresos from "./selectorIngresos/selectIngresos"
import TableIngresosView from "../../../sharedComponents/TableIngresosView/TableIngresosView"
import AlertCapacidad from "./alertCapacidad/alertCapacidad"
import ContinueButton from "../../../sharedComponents/continueButton/ContinueButton"

const Capacidad = ({setOpenSelectIngresos, onClose}) => {
    const dispatch = useAppDispatch()
    const [subSteps, setSubSteps] = useState(1)
    const [open, setOpen] = useState(false)
    const [openAlert, setOpenAlert] = useState(false)

    const { tipoDeIncapacidad, puedeDesarrollarVidaNormal, fechasIncapacidad, ingresosPorIncapacidad, porcentajeIncapacidad,impactoVida, tipoLiquidacion } = useAppSelector(state => state.app.data)

    const getSecondStep = () => {
        if (tipoDeIncapacidad === 'permanente') {
            setOpen(true)
            return <Modal open={open}>
                <Box>
                    <SelectIngresos setOpen />
                </Box>
            </Modal>
        } else if (tipoDeIncapacidad.length > 0 && tipoDeIncapacidad === 'temporalmente') {
            return (
            <div>
                <div className="row">
                    <label>Fecha inicial en la que perdió sus capacidades</label>
                    <input className="input" type="date" onChange={ev => dispatch(setFechasIncapacidad({...fechasIncapacidad, inicial: ev.target.value}))}/>
                </div>
                <div className="row">
                    <label>Fecha inicial en la que perdió sus capacidades</label>
                    <input className="input" type="date" onChange={ev => dispatch(setFechasIncapacidad({...fechasIncapacidad, final: ev.target.value}))}/>
                </div>
                {fechasIncapacidad.final.length > 0 && fechasIncapacidad.inicial.length > 0 && (
                    <SelectIngresos setSubSteps={setSubSteps} />
                )}
            </div>)
        }
    }


    useEffect(() => {
        if (tipoDeIncapacidad === 'permanente') {
            // setOpen(true)
        } else if (tipoDeIncapacidad.length > 0 && tipoDeIncapacidad === 'temporalmente') {}
    }, [tipoDeIncapacidad])

    useEffect(() => {
        if (porcentajeIncapacidad === null) {
            setOpenAlert(true)
        }
    }, [porcentajeIncapacidad])
    


    return ( 
        <div className="stepsContainer">
            <h1>INCAPACIDAD</h1>
            <Divider style={{ width: '500px', backgroundColor: '#000'}}  />
            <div classname>
                <div className='radiosContainer'>
                    <div className='radioContainer'>
                        <input style={{ margin: '0 0 0 0' }}    className='radioInput' type="radio" id="permanente" name="incapacidad" value="permanente" onChange={ev => dispatch(setIncapacidad(ev.target.value))} />
                        <label style={{ margin: '0 0 0 10px' }} className='labelOption' for="permanente">Permanente</label>
                    </div>
                    <div className='radioContainer'>
                        <input style={{ margin: '0 0 0 10px' }} className='radioInput' type="radio" id="temporalmente" name="incapacidad" value="temporalmente" onChange={ev => dispatch(setIncapacidad(ev.target.value))} />
                        <label style={{ margin: '0 0 0 10px' }} className='labelOption' for="temporalmente">Temporalmente</label>
                    </div>
                </div>
            </div>

            {tipoDeIncapacidad === 'permanente' && ingresosPorIncapacidad.length === 0 && (
                <>
                    <label className='labelOptionBold'>Ingresos</label>
                    <label className='labelOption'>¿Como consecuencia de dicha incapacidad, la víctima dejará de percibir ingresos?</label>
                    <div classname="yesOrno">
                        <button className="yesOrnoBTN" onClick={() => setOpen(true)}>SI</button>
                        <button className="yesOrnoBTN" onClick={() => onClose()}>NO</button>
                    </div>
                </>
            )}

            {tipoDeIncapacidad === 'temporalmente' && (
                <>
                    <label className="labelOptionBold">Rango de fechas</label>
                    <label className="labelOption">Por favor, seleccione de qué fecha a qué fecha dura la incapacidad:</label>
                    <div className="flex mt-20">
                        <div>
                            <label className="labelOption">Fecha inicial</label>
                            <input className="dateSelect" type="date" onChange={ev => dispatch(setFechasIncapacidad({...fechasIncapacidad, inicial: ev.target.value}))}/>
                        </div>
                        <div>
                            <label className="labelOption">Fecha final</label>
                            <input className="dateSelect" type="date" onChange={ev => dispatch(setFechasIncapacidad({...fechasIncapacidad, final: ev.target.value}))}/>
                        </div>
                    </div>
                </>
            )}

            {tipoDeIncapacidad === 'temporalmente' && fechasIncapacidad.final.length > 0 && (
                <>
                    <label className='labelOptionBold'>Ingresos</label>
                    <label className='labelOption'>¿Como consecuencia de dicha incapacidad, la víctima dejará de percibir ingresos?</label>
                    <div classname="yesOrno">
                        <button className="yesOrnoBTN" onClick={() => setOpen(true)}>SI</button>
                        <button className="yesOrnoBTN" onClick={() => onClose()}>NO</button>
                    </div>
                </>
            )}

            {ingresosPorIncapacidad.length > 0 && (
                <>
                    <TableIngresosView ingresos={ingresosPorIncapacidad} />
                    <button className="editSelectBtn" onClick={() => setOpen(true)}>Editar Selección</button>
                </>
            )}

            {tipoDeIncapacidad === 'permanente' && tipoDeIncapacidad === 'permanente' && (
                <>
                    <label className='labelOptionBold'>Perdida de capacidad laboral</label>
                        <div className="flex">
                            <label className="labelOption">¿En porcentaje, cuánto perdió de capacidad laboral?</label>
                            <div className='radioContainer ml-20'>
                                <input style={{ margin: '0 0 0 10px' }} className='radioInput' type="radio" id="nosabe" name="tipo" value="nosabe"
                                 onChange={() => dispatch(setPorcentajeIncapacidad(null))}
                                  />
                                <label style={{ margin: '0 0 0 10px' }} className='labelOption mt-10' for="nosabe">No sabe</label>
                            </div>
                        </div>
                    <Slider
                        aria-label="Restricted values"
                        // defaultValue={20}
                        // valueLabelFormat={valueLabelFormat}
                        // getAriaValueText={valuetext}
                        // ste  p={null}
                        valueLabelDisplay="auto"
                        // marks={marks}
                        style={{ marginTop: '20px' }}
                        onChange={(ev, value) => dispatch(setPorcentajeIncapacidad(value))}
                        disabled={porcentajeIncapacidad === null ? true : false}
                    />
                </>
            )}

            {porcentajeIncapacidad && tipoDeIncapacidad === 'permanente' && tipoLiquidacion === 'jurisdiccionCivil' && (
                <div>
                    <div className="column">
                        <label className='labelOptionBold'>Capacidades y vida de relación</label>
                        <label className="labelOption">¿Puede desarrollar sus capacidades y su vida normal de relación?</label>
                    </div>
                    <div className="flex align-center margin-top-20">
                        <div classname="yesOrno">
                            <button className="yesOrnoBTN" style={{ marginTop: 0}} onClick={() => dispatch(setDesarrolloVida('si'))}>SI</button>
                            <button className="yesOrnoBTN" style={{ marginTop: 0}} onClick={() => dispatch(setDesarrolloVida('no'))}>NO</button>
                        </div>
                        <div>
                            {puedeDesarrollarVidaNormal === 'no' && (
                                <>
                                    <label className="labelOption">¿Qué tan grave impactó su vida el accidente?</label>
                                    <div className='radios'>
                                        <input style={{ margin: '0 0 0 10px' }} className='radioInput' type="radio" id="Alto" name="impactoVida" value="alto"
                                            onChange={() => dispatch(setImpactoVida('alto'))}
                                        />
                                        <label style={{ margin: '0 0 0 10px' }} className='labelOption mt-10' for="variable">Alto</label>
                                        <input style={{ margin: '0 0 0 10px' }} className='radioInput' type="radio" id="Medio" name="impactoVida" value="medio"
                                            onChange={() => dispatch(setImpactoVida('medio'))}
                                        />
                                        <label style={{ margin: '0 0 0 10px' }} className='labelOption mt-10' for="variable">Medio</label>
                                        <input style={{ margin: '0 0 0 10px' }} className='radioInput' type="radio" id="Bajo" name="impactoVida" value="bajo"
                                            onChange={() => dispatch(setImpactoVida('bajo'))}
                                        />
                                        <label style={{ margin: '0 0 0 10px' }} className='labelOption mt-10' for="variable">Bajo</label>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}


            { (porcentajeIncapacidad > 0 || (ingresosPorIncapacidad.length > 0 && tipoDeIncapacidad === 'temporalmente')) && (
                <div style={{ marginTop: tipoDeIncapacidad === 'jurisdiccionCivil' ? 0 : 40 }}>
                    <button onClick={() => onClose()} className="continuarImpactoVida">CONTINUAR</button>
                </div>
            )}

            <Modal open={open} onClose={() => setOpen(false)}>
                <Box style={{ backgroundColor: '#0d78bc', width: '70vw', heigh: 'auto', margin: 'auto', marginTop: '200px', borderRadius: '7px' }}>
                    <SelectIngresos setOpen={setOpen} />
                </Box>
            </Modal>
            <Modal open={openAlert} onClose={() => setOpenAlert(false)}>
                <Box style={{ backgroundColor: '#0c78bce6', width: '35vw', heigh: 'auto', margin: 'auto', marginTop: '100px', borderRadius: '7px' }}>
                    <AlertCapacidad onClose={() => setOpenAlert(false)}/>
                </Box>
            </Modal>
        </div>
    )
}

export default Capacidad