import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import '../index.css'
import logo from '../imgs/logoLight.png'
// import home from '../imgs/ic-home.svg'
import { Stepper,Step,StepButton } from '@mui/material'
import { useAppDispatch, useAppSelector } from "../store/hooks"
import { styled } from '@mui/material/styles'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'
import { backStep, resetAppData, setStep } from '../store/features/appSlice'
import UserIcon from './sessionControl/UserIcon'
import { resetUrl } from '../libs/constants'

const Connector = ({color}) => {
  return (
    <div className='connector' style={{ background: color}}>.</div>
  )
}

function Header() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const steps = [1 , 2, 3, 4, 5, 6, 7]

  const step = useAppSelector(state => state.app.step)
  const [reseting, setReseting] = useState(false)

  const handleStep = label => {
    dispatch(setStep(label))
  }

  const handleClickLogo = () => {
    dispatch(resetAppData())
    navigate('/')
  }

  const handleLogin = () => {
    navigate('/login')
  }

  const getNatStyle = () => {
    let style = {
      display: 'flex',
      justifyContent: 'space-between',
    }

    if (reseting) {
      return style
    }

    return {}
  }


  useEffect(() => {
    const url = window.location.pathname
    if (url === resetUrl) {
      setReseting(true)
    }
  },[])

  return (
    <header className='header' style={getNatStyle()}>
      <div className='logoContainer'><img src={logo} onClick={handleClickLogo}/></div>
      <div className='nav'>
        {!reseting && (
          <>
            <div className='stepper'>
              {steps.map((label, index) => (
                <div className='step'>
                  <button onClick={() => handleStep(label)} style={{ background: label <= step ? '#1E7CB3' : '#C5D6EF', color: label <= step ? '#fff' : '#1E7CB3'}}>{label}</button>
                  {steps[index + 1] && (
                    <Connector color={label <= step ? '#1E7CB3' : '#C5D6EF'} />
                  )}
                </div>
              ))}
            </div>  
            <button onClick={() => dispatch(backStep())} className='btn btn-primary'><div className='backIcon'>{'<'}</div>Anterior</button>
            <UserIcon />
          </>
        )}
      </div>
      {reseting && (
        <div>
          <button onClick={handleLogin} className='loginButtonReset'>INICIAR SESIÓN</button>
        </div>
      )}
    </header>
  )
}

export default Header