import React, { useState } from "react"
import Modal from "../../../sharedComponents/modal/Modal"
import { useAppDispatch } from "../../../store/hooks"
import { setProbabilityLoss } from "../../../store/features/appSlice"

function ProbabilityLoss({open, next}){
    const dispatch = useAppDispatch()

    const [showOptions, setShowOptions] = useState(false)


    const handleOption = (option) => {
        dispatch(setProbabilityLoss(option))
        next()
    }

    return(
        <Modal open={open} onClose={() => next()} content={
            <div>
                <label className="labelOptionBold">¿Existe una calificación de probabilidad de perdida?</label>
                <div className="yesOrno">
                    <button className="yesOrnoBTN" onClick={() => setShowOptions(true)}>SI</button>
                    <button className="yesOrnoBTN" onClick={() => next()}>NO</button>
                </div>
                {showOptions && (
                    <div className='radiosContainer'>
                        <div className='radioContainer'>
                            <input 
                                style={{ margin: '0 0 0 0' }}  
                                className='radioInput' 
                                type="radio" 
                                id="probable" 
                                name="perdida" 
                                value="probable" 
                                onChange={ev => handleOption(ev.target.value)}
                            />
                            <label style={{ margin: '0 0 0 10px' }} className='labelOption' for="probable">Probable</label>
                        </div>
                        <div className='radioContainer'>
                            <input 
                                style={{ margin: '0 0 0 10px' }}
                                className='radioInput' 
                                type="radio" 
                                id="posible" 
                                name="perdida" 
                                value="posible" 
                                onChange={ev => handleOption(ev.target.value)}
                            />
                            <label style={{ margin: '0 0 0 10px' }} className='labelOption' for="posible">Posible</label>
                        </div>
                        <div className='radioContainer'>
                            <input 
                                style={{ margin: '0 0 0 10px' }}
                                className='radioInput' 
                                type="radio" 
                                id="eventual" 
                                name="perdida" 
                                value="eventual" 
                                onChange={ev => handleOption(ev.target.value)}
                            />
                            <label style={{ margin: '0 0 0 10px' }} className='labelOption' for="eventual">Eventual</label>
                        </div>
                    </div>
                )}
            </div>
        } />
    )
}

export default ProbabilityLoss