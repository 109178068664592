import React from 'react'
import { useEffect, useState } from 'react'
import './informacionUsuario.css'
import ContinueButton from '../../sharedComponents/continueButton/ContinueButton'
import BackButton from '../../sharedComponents/backButton/BackButton'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { backStep, nextStep, setCondicion, setConsecuencia, setEdad, setFechaNacimiento, setNombre, setSexo, setSituacionLaboral, setSufrio } from '../../store/features/appSlice'
import Insert from '../../sharedComponents/insertMoney/Insert'
import Capacidad from './capacidad/capacidad'
import Modal from '../../sharedComponents/modal/Modal'
import PreguntaIngresos from './preguntaIngresos/preguntaIngresos'
import AlertIngresos from './AlertIngresos/AlertIngresos'
import GestorDeIngresos from './GestorIngresos/GestorIngresos'
import { TryRounded } from '@mui/icons-material'

function MuerteContent({onClose, setShowSufrio}) {
    const dispatch = useAppDispatch();
    return (
        <div className='muerteAlert'>
            <label className='sufrioTExt'>¿La víctima sufrió antes de morir?</label>
            <div className="buttonsContainer">
                <button onClick={() => {
                    dispatch(setSufrio('si'))
                    setShowSufrio(false)
                }}
                    >SI</button>
                <button onClick={() => {
                    dispatch(setSufrio('no'))
                    setShowSufrio(false)
                }}>NO</button>
            </div>
        </div>
    )
}

function InformacionUsuario() {

    const dispatch = useAppDispatch();
    const step = useAppSelector(state  => state.app.step)
    const data = useAppSelector(state => state.app.data)
    const consecuencia = useAppSelector(state => state.app.data.consecuencia)
    const fechaNacimiento = useAppSelector(state => state.app.data.fechaNacimiento)

    const [openInsert, setOpenInsert] = useState(false)
    const [openCapacidad, setOpenCapacidad] = useState(false)
    const [preguntaIngresos, setPreguntaIngresos] = useState(false)
    const [showModalAlert, setShowModalAlert] = useState(false)
    const [showGestorIngresos, setShowGestorIngresos] = useState(false)
    const [showSufrio, setShowSufrio] = useState(false)

    // const setInformacion = (ev) => {
    //     const {name, value} = ev.target
    //     setData({...data, [name]: value })
    // }
    
    const siguiente = () => {
        dispatch(nextStep())
    }

    const handleCloseModalAlert = () => {
        setShowModalAlert(false)
        setShowGestorIngresos(true)
    }


    useEffect( () => {
        if (consecuencia === 'incapacidad') {
            setOpenCapacidad(true)
        } else if (consecuencia === 'muerte') {
            setShowSufrio(true)
        }
    }, [consecuencia])

    //para setear edad
    useEffect(() => {
        if (fechaNacimiento) {
            const today = new Date()
            const birthDate = new Date(fechaNacimiento)
            const edad = today.getFullYear() - birthDate.getFullYear()
            dispatch(setEdad(edad))
        }
    }, [fechaNacimiento])

    useEffect(() => {
        if (data.situacionLaboral === 'independiente' || data.situacionLaboral === 'empleado') {
            setPreguntaIngresos(TryRounded)
        }
    }, [data.situacionLaboral])

    return (
    <div className='userInfoContainer'>
        <div className="titleHeaderUser">
            <h1>INFORMACIÓN DE LA VÍCTIMA</h1> 
            <p className='labelOption'>Por favor ingrese a continuación los datos de la víctima del daño</p>
        </div>
        <div className='cardUserInfo'>
            <div className='usuarioSpace'>
                <div className='inputLabelContainer'>
                    <label className='labelOptionBold'>Nombre completo</label>
                    <input className='input infoInput' type="text" name="nombre" onChange={ev => dispatch(setNombre(ev.target.value))}/>
                </div>
                <div className='inputLabelContainer'>
                    <label className='labelOptionBold'>Fecha de nacimiento</label>
                    <input className="dateSelect" type="date" onChange={e => dispatch(setFechaNacimiento(e.target.value))} />
                </div>

                <div className='inputLabelContainer'>
                    <label className='labelOptionBold'>Sexo</label>
                    <div className='radiosContainer'>
                        <div className='radioContainer'>
                            <input style={{ margin: '0 0 0 0' }}    className='radioInput' type="radio" id="femenino" name="sexo" value="femenino" onChange={ev => dispatch(setSexo(ev.target.value))} />
                            <label style={{ margin: '0 0 0 10px' }} className='labelOption' for="femenino">Femenino</label>
                        </div>
                        <div className='radioContainer'>
                            <input style={{ margin: '0 0 0 10px' }} className='radioInput' type="radio" id="masculino" name="sexo" value="masculino" onChange={ev => dispatch(setSexo(ev.target.value))} />
                            <label style={{ margin: '0 0 0 10px' }} className='labelOption' for="masculino">Masculino</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='cardUserInfo'>
            <div className='usuarioSpace'>
                <div className='inputLabelContainer'>
                    <label className='labelOptionBold'>Ingresos</label>
                    <label className='labelOption'>A continuación, añada los ingresos de la víctima</label>
                    <button className='buttonIngreso mt-20' onClick={() => setOpenInsert(true)}>AÑADIR INGRESOS</button>
                </div>
                <div className='inputLabelContainer'>
                    <button style={{ color: 'transparen', border: 'none', background: 'transparent', width: '220px' }} ></button>
                </div>
                <div className='inputLabelContainer'>
                    <button style={{ color: 'transparen', border: 'none', background: 'transparent', width: '251px' }} ></button>
                </div>
            </div>
        </div>

        {(data.ingresos.length > 0 ? true : showGestorIngresos) && (
            <GestorDeIngresos setShowGestorIngresos={setShowGestorIngresos}/>
        )}

        <div className='cardUserInfo'>
            <div className='usuarioSpace'>
                <div className='inputLabelContainer'>
                    <label className='labelOptionBold'>Situación laboral</label>
                    <div className='radiosContainer'>
                        <div className='radioContainer'>
                            <input style={{ margin: '0 5px 0 10px' }} className="radioInput" type="radio" id="empleado" name="situacionLaboral" value="empleado" onChange={ev => dispatch(setSituacionLaboral(ev.target.value))} />
                            <label className="labelOption" for="empleado">Empleado</label>
                        </div>
                        <div className='radioContainer'>
                            <input style={{ margin: '0 5px 0 10px' }} className="radioInput" type="radio" id="independiente" name="situacionLaboral" value="independiente" onChange={ev => dispatch(setSituacionLaboral(ev.target.value))} />
                            <label className="labelOption" for="independiente" >Independiente</label>
                        </div>
                        <div className='radioContainer'>
                            <input style={{ margin: '0 5px 0 10px' }} className="radioInput" type="radio" id="desempleado" name="situacionLaboral" value="desempleado" onChange={ev => dispatch(setSituacionLaboral(ev.target.value))} />
                            <label className="labelOption" for="desempleado" >Desempleado</label>
                        </div>
                    </div>
                    {/* <select onChange={ev => dispatch(setSituacionLaboral(ev.target.value)) } className='selectInput'>
                        <option value="empleado">Empleado</option>
                        <option value="independiente">Independiente</option>
                        <option value="desempleado">Desempleado</option>
                    </select> */}
                </div>
                <div className='inputLabelContainer'>
                    <button style={{ color: 'transparen', border: 'none', background: 'transparent', width: '220px' }} ></button>
                </div>
                <div className='inputLabelContainer'>
                    <button style={{ color: 'transparen', border: 'none', background: 'transparent', width: '251px' }} ></button>
                </div>
            </div>
        </div>

        {preguntaIngresos && (
            <PreguntaIngresos setPreguntaIngresos={setPreguntaIngresos} setShowModalAlert={setShowModalAlert} />
        )}
        
        <div className='cardUserInfo'>
            <div className='usuarioSpace'>
                <div className='inputLabelContainer'>
                    <label className='labelOptionBold'>Condición</label>
                    <div className='radiosContainer'>
                        <div className='radioContainer'>
                            <input style={{ margin: '0 5px 0 10px' }} className="radioInput" type="radio" id="capacidad" name="condicion" value="capacidad" onChange={ev => dispatch(setCondicion(ev.target.value))} />
                            <label className="labelOption widthUser" for="capacidad">En condición de capacidad</label>
                        </div>
                        <div className='radioContainer'>
                            <input style={{ margin: '0 5px 0 10px' }} className="radioInput" type="radio" id="discapacidad" name="condicion" value="discapacidad" onChange={ev => dispatch(setCondicion(ev.target.value))} />
                            <label className="labelOption widthUser" for="discapacidad" >En condicion de discapacidad</label>
                        </div>
                    </div>
                </div>
                <div className='inputLabelContainer'>
                    <button style={{ color: 'transparen', border: 'none', background: 'transparent', width: '220px' }} ></button>
                </div>
                <div className='inputLabelContainer'>
                    <button style={{ color: 'transparen', border: 'none', background: 'transparent', width: '251px' }} ></button>
                </div>
            </div>
        </div>
        <div className='cardUserInfo'>
            <div className='usuarioSpace'>
                <div className='inputLabelContainer'>
                    <label className='labelOptionBold'>Consecuencia del daño</label>
                    <div className='radiosContainer'>
                        <div className='radioContainer'>
                            <input style={{ margin: '0 5px 0 10px' }} className="radioInput" type="radio" id="ninguna" name="consecuencia" value="ninguna" onChange={ev => dispatch(setConsecuencia(ev.target.value))} />
                            <label className="labelOption" for="ninguna">Ninguna</label>
                        </div>
                        <div className='radioContainer'>
                            <input style={{ margin: '0 5px 0 10px' }} className="radioInput" type="radio" id="muerte" name="consecuencia" value="muerte" onChange={ev => dispatch(setConsecuencia(ev.target.value))} />
                            <label className="labelOption" for="muerte" >Muerte</label>
                        </div>
                        <div className='radioContainer'>
                            <input style={{ margin: '0 5px 0 10px' }} className="radioInput" type="radio" id="incapacidad" name="consecuencia" value="incapacidad" onChange={ev => dispatch(setConsecuencia(ev.target.value))} />
                            <label className="labelOption" for="incapacidad" >Incapacidad</label>
                        </div>
                    </div>
                </div>
                <div className='inputLabelContainer'>
                    <button style={{ color: 'transparen', border: 'none', background: 'transparent', width: '220px' }} ></button>
                </div>
                <div className='inputLabelContainer'>
                    <button style={{ color: 'transparen', border: 'none', background: 'transparent', width: '251px' }} ></button>
                </div>
            </div>
        </div>
        <div className='navBtnsContainer'>
            {/* <BackButton onClick={() => dispatch(backStep()) } /> */}
            <ContinueButton onClick={siguiente} disabled={!(data.nombre && data.fechaNacimiento && data.sexo)} text="Siguiente"/>
        </div>
        <Modal background="#0c77bcd4" showClose={false} open={showSufrio} content={<MuerteContent setShowSufrio={setShowSufrio} />} />
        <Modal content={<Insert setInsert={setOpenInsert} action="ingresos" allowAny={true} />} open={openInsert} onClose={() => setOpenInsert(false)} />
        <Modal content={<Capacidad onClose={() => setOpenCapacidad(false)} />}  open={openCapacidad} onClose={() => setOpenCapacidad(false)} />
        <Modal background="#0c77bcd4" showClose={false} content={<AlertIngresos handleCloseModalAlert={() => handleCloseModalAlert()} />} open={showModalAlert} onClose={() => handleCloseModalAlert()} />
    </div>)
}

export default InformacionUsuario