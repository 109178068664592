import React from 'react';
import { useState, useEffect } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom';
import RevisionDate from './pages/revisionDate/revisionDate';
import InformacionUsuario from './pages/informacionUsuario/informacionUsuario';
import DañoEmergente from './pages/dañoEmergente/dañoEmergent';
import Ipc from './pages/ipcDashboard/ipc';
import Axios from "axios";
import config from './libs/config';
import Header from './sharedComponents/Header';
import Home from './pages/Home/Home';
import LucroCesante from './pages/lucroCesante/lucroCesante';
import WhatsappButton from "./sharedComponents/whatsappButton/WhatsappButton"
import ReclamanteDaño from './pages/reclamanteDaño/ReclamanteDaño';
import { Politicas } from './pages/politicasPrivacidad/Politicas';
import { useAppDispatch, useAppSelector } from './store/hooks';
import Perjuicios from './pages/perjuciosExtrapatrimoniales/Perjuicios';
import Liquidacion from './pages/Liquidacion/Liquidacion';
import { nextStep, setEdad } from './store/features/appSlice';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import { verifyUser } from './store/features/userSlice';
import ResetPassword from './pages/resetPassword/ResetPassword';
import Creditos from './pages/Creditos/Creditos';


function App() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const token = localStorage.getItem('tokenDla')

  const step = useAppSelector(state => state.app.step)
  const data1 = useAppSelector(state => state.app.data)
  console.log(data1)

  const [data, setData] = useState({
    fechaHechos: "",
    fechaNacimiento: "",
    nombre: "",
    edad: 0,
    genero: 'masculino',
    email: '',
    totalIndemnizacion: 0,
    // gastos: [],
    // gastosFuturos: []
})
  const [imgTable, setImgTable] = useState()
  const [imgTable2, setImgTable2] = useState()
  const [imgInformation, setInformation] = useState()

  const getSteps = () => {
    const pages = {
      1: <RevisionDate />,
      2: <InformacionUsuario />,
      3: <ReclamanteDaño />,
      4: <DañoEmergente  setImgTable={setImgTable} />,
      5: <LucroCesante  setImgTable2={setImgTable2} />,
      6: <Perjuicios/>,
      7: <Liquidacion />
    }
    return pages[step]
  }

  
  const blobToBase64 = blob => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = error => reject(error);
    });
  }
  
  const saveData = async () => {
    const formData = new FormData()
    formData.append('image', imgTable)
    formData.append('image', imgTable2)
    formData.append('image', imgInformation)
    formData.append('email', JSON.stringify(data.email))
    formData.append('nombre', JSON.stringify(data.nombre))
    
    await Axios.post(`${config.api}/api/cotizacion`, formData, {
      'Content-Type': 'multipart/form-data',
      responseType: 'blob'
    }).then(response => {
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'cotizacion.pdf';
      link.click();
    }).catch(err => console.error(err))
  }

  const verifySession = () => {
    let tokenDecoded;
    
    if (window.location.pathname === '/resetPassword') return

    dispatch(verifyUser(token)).then(res => {
      tokenDecoded = res.token
    }).catch(err => {
      console.error(err)
      localStorage.removeItem('tokenDla')

      navigate('/login')
    })
    
    const currentTime = Math.floor(Date.now() / 1000);
    if (tokenDecoded && tokenDecoded.exp < currentTime) {
      localStorage.removeItem('tokenDla')
      navigate('/login')
    }
  }

  useEffect(() => {
    if(step === 4){
      // saveData()
    }
  }, [imgTable, step])

  useEffect(() => {
    if (step === 3 && (data1.consecuencia === 'ninguna' || data1.consecuencia === 'incapacidad')) {
      dispatch(nextStep())
    }
  },[step]) 

  useEffect(() => {
    verifySession()
  },[token, step])

  return (
    <Routes>
        <Route path="/" element={<Home />} /> 
        <Route  path="/login" element={<Login />} />
        {/* <Route path="/Register" element={<Register />} /> */}
        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route path="/simulador" element={          
          <div className="App">
              <Header />
              <div className='layout'>
                <WhatsappButton />
                {getSteps()}
                {/* <Information data={data} setInformation={setInformation} /> */}
              </div>
          </div>} />
        <Route path="/ipc" element={<Ipc />} />      
        <Route path="/politicas-privacidad" element={<Politicas />} />
        <Route path='creditos' element={<Creditos />} />'      
    </Routes>
  );
}

export default App;
