import React from 'react'
import '../revisionDate.css'
import { Button, Divider } from '@mui/material'
import AlertIcon from '../../../imgs/ic7.png'
import banner from '../../../imgs/Banner SR.png'
import { Link } from 'react-router-dom'

function AlertDate({ closeModal }) {
    return(
        <div className='alertContainerDate'>
            <div className='AlertHeaderContainerDate'>
                <img src={AlertIcon} />
                <h1>¡ATENCIÓN!</h1>
            </div>
            <Divider style={{ backgroundColor: '#d3e7ff'}}  />
            <p className='AlertTextDate'>
                Liquilex detecta que el derecho a reclamar puede haber prescrito. Se le
                recomienda al usuario revisar el caso con su área legal o abogado de confianza,
                con la finalidad de verificar si el derecho a reclamar prescribió.
            </p>
            <div className='continueButtonContainer'>
                <Button className='alertContinueButton' onClick={closeModal}>ENTENDIDO</Button>
            </div>
            <div className='bannerContainer'>
                <img src={banner} />
            </div>
        </div>
    )
}

export default AlertDate