import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    step: 1,
    data: 
    {   
        tipoLiquidacion: '',
        nombre: '',
        fechaNacimiento: '',
        fechaHechos: '',
        fechaLiquidacion: '',
        situacionLaboral: '',
        edad: 0,
        sexo: 'femenino',
        dañoEmergente: {
            pasados: [],
            futuros: []
        },
        lucroCesante: {
            pasados: [],
            futuros: []
        },
        ingresos: [
            // {
            //     "unico": true,
            //     "tipo": "test",
            //     "fecha1": "2023-09-01",
            //     "monto": 100000
            // },
            // {
            //     "unico": true,
            //     "tipo": "test",
            //     "fecha1": "2023-08-01",
            //     "monto": "2"
            // },
            // {
            //     "unico": false,
            //     "tipo": "fijo",
            //     "fecha1": "2029-01-01",
            //     "fecha2": "2080-01-01",
            //     "monto": "1000000"
            // }
        ],
        fechasIncapacidad: {
            inicial: '',
            final: ''
        },
        ingresosPorIncapacidad: [], //estos ingresos serán seleccionados de los ingresos normales y su fecha final será la fecha de expectativa de vida
        situacionLaboral: '',
        condicion: 'capacidad', //condicion de la victima en el momento de los hechos que ocasionaron el daño.
        consecuencia: '', //consecuencia de los hechos que ocasionaron el daño.
        tipoDeIncapacidad: '', //permanente o temporal
        porcentajeIncapacidad: 0,
        puedeDesarrollarVidaNormal: '',
        impactoVida: '', //bajo, medio, alto
        sufrio: null, //si o no
        totalCalculo: 0,
        totalIndemnizacion: 0,
        reclamantes: [
            // {
            //     "nombre": "duvier reinosa",
            //     "fechaNacimiento": "2023-09-01",
            //     "sexo": "femenino",
            //     "condicion": "capacidad",
            //     "dependenciaEconomica": "absolutamente",
            //     "monto": "1000000",
            //     "fechaInicial": "2023-08-31",
            //     "fechaFinal": "2023-09-02",
            //     "parentesco": "hijos"
            // },
            // {
            //     "nombre": "duvier reinosa",
            //     "fechaNacimiento": "2023-09-01",
            //     "sexo": "femenino",
            //     "condicion": "capacidad",
            //     "dependenciaEconomica": "absolutamente",
            //     "monto": "1000000",
            //     "fechaInicial": "2023-08-31",
            //     "fechaFinal": "2023-09-02",
            //     "parentesco": "hijos"
            // },
            // {
            //     "nombre": "duvier reinosa",
            //     "fechaNacimiento": "2023-09-01",
            //     "sexo": "femenino",
            //     "condicion": "capacidad",
            //     "dependenciaEconomica": "absolutamente",
            //     "monto": "1000000",
            //     "fechaInicial": "2023-08-31",
            //     "fechaFinal": "2023-09-02",
            //     "parentesco": "hijos"
            // }
        ],
        dañoMoral: 0,
        dañoVictimasIndirectas: 0,
        dañoVidaRelacion: 0,
        victimasIndirectas: [],
        totalPerjuicios: 0,
        probabilityLoss: ''
    }
}



export const appSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        nextStep: (state, action) => {
			state.step += 1;
		},
        backStep: (state, action) => {
            state.step -= 1;
        },
        setLucroCesantePasados: (state, action) => {
            state.data.lucroCesante.pasados = action.payload
        },
        setLucroCensateFuturos: (state, action) => {
            state.data.lucroCesante.futuros = action.payload
        },
        setCondicion: (state, action) => {
            state.data.condicion = action.payload
        },
        setConsecuencia: (state, action) => {
            state.data.consecuencia = action.payload
            if (action.payload === 'muerte') {
                state.data.porcentajeIncapacidad = 100
            }
        },
        setIngresos: (state, action) => {
            state.data.ingresos = action.payload
        },
        setIngresosPorIncapacidad: (state, action) => {
            state.data.ingresosPorIncapacidad = action.payload
        },
        setIncapacidad: (state, action) => {
            state.data.tipoDeIncapacidad = action.payload
        },
        setFechaNacimiento: (state, action) => {
            state.data.fechaNacimiento = action.payload
        },
        setEdad: (state, action) => {
            state.data.edad = action.payload
        },
        setSexo: (state, action) => {
            state.data.sexo = action.payload
        },
        setPorcentajeIncapacidad: (state, action) => {
            state.data.porcentajeIncapacidad = action.payload
        },
        setDesarrolloVida: (state, action) => {
            state.data.puedeDesarrollarVidaNormal = action.payload
        },
        setImpactoVida: (state, action) => {
            state.data.impactoVida = action.payload
        },
        setFechasIncapacidad: (state, action) => {
            state.data.fechasIncapacidad = action.payload
        },
        setSufrio: (state, action) => {
            state.data.sufrio = action.payload
        },
        setNombre: (state, action) => {
            state.data.nombre = action.payload
        },
        setSituacionLaboral: (state, action) => {
            state.data.situacionLaboral = action.payload
        },
        setFechaHechos: (state, action) => {
            state.data.fechaHechos = action.payload
        },
        setFechaLiquidacion: (state, action) => {
            state.data.fechaLiquidacion = action.payload
        },
        setReclamantes: (state, action) => {
            state.data.reclamantes = action.payload
        },
        setDañoEmergentePasados: (state, action) => {
            state.data.dañoEmergente.pasados = action.payload
        },
        setDañoEmergenteFuturos: (state, action) => {
            state.data.dañoEmergente.futuros = action.payload
        },
        setStep: (state, action) => {
            state.step = action.payload
        },
        setDañoMoral: (state, action) => {
            state.data.dañoMoral = action.payload
        },
        setVictimasIndirectas: (state, action) => {
            state.data.victimasIndirectas = action.payload
        },
        setDañoVidaRelacion: (state, action) => {
            state.data.dañoVidaRelacion = action.payload
        },
        setTotalPerjuicios: (state, action) => {
            state.data.totalPerjuicios = action.payload
        },
        resetAppData: (state, action) => {
            state.data = initialState.data
        },
        setTipoDeLiquidacion: (state, action) => {
            state.data.tipoLiquidacion = action.payload
        },
        setProbabilityLoss: (state, action) => {
            state.data.probabilityLoss = action.payload
        }
    },
    extraReducers: {
        // [login.fulfilled]: (state, action) => {
        //     state.user.token = action.payload.token
        // }
    }
})



export const { nextStep, setProbabilityLoss, setTipoDeLiquidacion, resetAppData, setStep, backStep, setVictimasIndirectas, setTotalPerjuicios, setDañoVidaRelacion, setDañoMoral, setDañoEmergentePasados, setDañoEmergenteFuturos, setReclamantes, setFechaHechos, setFechaLiquidacion, setSituacionLaboral, setNombre, setLucroCesantePasados, setLucroCensateFuturos, setCondicion, setConsecuencia, setIngresos, setIncapacidad, setFechaNacimiento, setEdad, setSexo, setIngresosPorIncapacidad, setPorcentajeIncapacidad, setDesarrolloVida, setImpactoVida, setFechasIncapacidad, setSufrio } = appSlice.actions

export default appSlice.reducer