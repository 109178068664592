import React, { useState } from "react"
import { useAppDispatch } from "../../store/hooks"
import { login, resetPassword, sendPin } from "../../store/features/userSlice"
import { useNavigate } from "react-router-dom"

function ResetPasswordForm() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [code, setCode] = useState(false)
    const [password, setPassword] = useState('')
    const [emailSended, setEmailSended] = useState(false)
    const [codeSended, setCodeSended] = useState(false)
    const [passwordSended, setPasswordSended] = useState(false)
    
    const handleSendEmail = () => {
        dispatch(sendPin(email)).then(res => {
                setEmailSended(true)
            })
            .catch(err => {
                console.error(err)
                alert('Este usuario no se encuentra registrado')
            })
        setEmailSended(true)
    }

    const handleSendCode = () => {
        dispatch(login({email, contrasena: code}))
            .then(res => {
                setCodeSended(true)
            })
            .catch(err => {
                console.error(err)
                alert('Este usuario no se encuentra registrado')
            })
    }

    const handleSendPassword = () => {
        dispatch(resetPassword({email, code, newPassword: password}))
            .then(res => {
                alert('Contraseña cambiada con éxito')
                navigate('/login')
            })
            .catch(err => {
                console.error(err)
                alert('Error al cambiar la contraseña')
            })
    }


    return (
        <div className="resetContainerForm">
            <h1 className="resetPasswordTitle">RECUPERE SU CONTRASEÑA</h1>
            {!emailSended && (
                <>
                    <p className="subTextResetPassword">A continuación, Introduzca su correo electrónico para
                        restablecer su contraseña
                    </p>
                    <input className="emailReset" onChange={ev => setEmail(ev.target.value)}/>
                    <button className="sendButton" onClick={handleSendEmail}>ENVIAR</button>
                </>
            )}

            {emailSended && !codeSended && (
                <>
                    <p className="subTextResetPassword">Ingrese el código de verificación que enviamos a su correo electrónico</p>
                    <input className="emailReset" onChange={ev => setCode(ev.target.value)}/>
                    <button className="sendButton" onClick={handleSendCode}>ENVIAR</button>
                </>
            )}

            {codeSended && (
                <>
                    <p className="subTextResetPassword">Ingrese su nueva contraseña</p>
                    <input className="emailReset" type="password" onChange={ev => setPassword(ev.target.value)}/>
                    <button className="sendButton" onClick={handleSendPassword}>ENVIAR</button>
                </>
            )}
        </div>
    )
}

export default ResetPasswordForm