import React from "react"
import logo from "../../imgs/logoLight.png"
import './Creditos.css'
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Creditos() {
    const navigate = useNavigate()

    const handleBackNavigate = () => {
        navigate('/simulador')
    }

    const handleHomeNavigate = () => {
        navigate('/')
    }

    return (
        <div className="creditsPage">
            <div className='creditsLogo'>
                <img src={logo}/>
            </div>
            <div className="textCreditsContainer">
                <h1 className="CreditsPrincipalTitle">Créditos</h1>
                <p className="subTextPrincipalCredits">El software de liquidación "Liquilex" ha sido creado por un equipo de profesionales cuyos roles y contribuciones se enumeran a continuación:</p>
                <h1 className="CreditsTitle">Idea original y dirección del proyecto</h1>
                <p>Sergio Rojas Quiñones</p>
                <Divider style={{ width: '70%', backgroundColor: '#fff'}}  />
                <div className="subContainerText">
                    <h1 className="CreditsTitle">Coordinador del proyecto</h1>
                    <p className="credistText" style={{ width: '240px'}}>Alejandro Nieto Hernández</p>
                </div>
                <div className="subContainerText">
                    <h1 className="CreditsTitle">Programación</h1>
                    <p className="credistText">Duvier Reinosa <br/> Nayith Urruchurtu</p>
                </div>
                <div className="subContainerText">
                    <h1 className="CreditsTitle">Liquidador</h1>
                    <p className="credistText">Mateo Barrera</p>
                </div>
                <div className="subContainerText">
                    <h1 className="CreditsTitle" style={{ width: '150px', textAlign: 'right'}}>Diseño</h1>
                    <p className="credistText">Gabriela Villegas C.<br />
                        Natalia Cabrera L. <br />
                        María Alejandra Reyes</p>
                </div>
                <div className="subContainerText">
                    <h1 className="CreditsTitle">Gestión de Operación y <br /> Propiedad Intelectual</h1>
                    <p className="credistText" style={{ width: '220px'}}>Daniela Fernández <br />Nashua Morante </p>
                </div>
            </div>
            <div className="creditsButtonsContainer">
                <button className="btn-credits" onClick={handleBackNavigate}>
                    REGRESAR
                </button>
                <button className="btn-credits" onClick={handleHomeNavigate}>
                    FINALIZAR SIMULACIÓN
                </button>
            </div>
        </div>
    );
}

export default Creditos;