import React, { useEffect, useState, useRef } from "react"
import './dañoTable.css'
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import moment from "moment"
import Axios from "axios"
import config from "../../../libs/config"
import { backStep, nextStep } from "../../../store/features/appSlice"
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import ContinueButton from "../../../sharedComponents/continueButton/ContinueButton"
import { getDañoEmergente, getMoneyFormat, getPeriod, getSecondDate, getValorAbsoluto } from "../../../libs/helpers"

function DañoTable(){
    const dispatch = useAppDispatch()

    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    const miDiv = useRef(null)

    const data = useAppSelector(state => state.app.data)
    const gastos = useAppSelector(state => state.app.data.dañoEmergente.pasados)
    const gastosFuturos = useAppSelector(state => state.app.data.dañoEmergente.futuros)

    const [insert, setInsert] = useState(false)
    const [ipcs, setIpcs] = useState([])
    const [calculoPasado, setCalculoPasado] = useState(0)
    const [calculoFuturo, setCalculoFuturo] = useState(0)

    const getIpcs = async () => {
        await Axios.get(`${config.api}/api/ipc`). then(response => {
            setIpcs(response.data)
        })
    }

    const handleNext = () => {
        dispatch(nextStep())
    }

    const handleBack = () => {
        dispatch(backStep())
    }

    function diffMonths(date1, date2) {
        var diffDays = (date2 - date1) / (1000 * 60 * 60 * 24);
        var diffMonths = diffDays / 30;
        return diffMonths.toFixed(2); // redondear a 2 decimales
    }

 

    const getGastoActualizado = (gasto, actualizado = true) => {
        if (ipcs.length === 0) return gasto.monto

        return getDañoEmergente(gasto, ipcs, data, actualizado)



        if (moment().diff(moment(gasto.fecha1), 'days') > 0) {
            if (gasto.unico){
                const fecha = moment(gasto.fecha1) 
                const mes = moment(fecha).format('MM');
                const anio = moment(fecha).format('YYYY');

                const fechaHoy = moment() 
                const mesHoy = moment(fechaHoy).format('MM');
                const anioHoy = moment(fechaHoy).format('YYYY');

                const diferenciaEnMeses = diffMonths(fecha, fechaHoy);

                console.log({diferenciaEnMesesPasadoUnico: diferenciaEnMeses})

                const ipcInicial = ipcs.filter(ipc => ipc.ano === parseInt(anio) && ipc.mes === meses[parseInt(mes) - 1])[0]
                const ipcFinal = ipcs.filter(ipc => ipc.ano === parseInt(anioHoy) && ipc.mes === meses[parseInt(mesHoy) - 1])[0]

                let sa = gasto.monto * (ipcFinal.valor / ipcInicial.valor)

                let s = sa * (1 + 0.004867)** (diferenciaEnMeses + 1)

                return getDañoEmergente(gasto, ipcs, data)
            } else {

                const fecha1 = moment(gasto.fecha1) 
                const mes1 = moment(fecha1).format('MM');
                const anio1 = moment(fecha1).format('YYYY');

                const fecha2 = moment(gasto.fecha2) 
                const mes2 = moment(fecha2).format('MM');
                const anio2 = moment(fecha2).format('YYYY');

                const diferenciaEnMeses = diffMonths(fecha1, fecha2);

                console.log({diferenciaEnMesesPasadoPeriodico: diferenciaEnMeses})

                const ipcInicial = ipcs.filter(ipc => ipc.ano === parseInt(anio1) && ipc.mes === meses[parseInt(mes1) - 1])[0]
                const ipcFinal = ipcs.filter(ipc => ipc.ano === parseInt(anio2) && ipc.mes === meses[parseInt(mes2) - 1])[0]

                let sa = gasto.monto * (ipcFinal.valor / ipcInicial.valor)

                let s = sa * ((((1 + 0.004867)** diferenciaEnMeses) - 1) / 0.004867)
                return getDañoEmergente(gasto, ipcs, data)
                
            }
        } else {
            if (gasto.unico){
                const fecha = moment(gasto.fecha1) 
                const mes = moment(fecha).format('MM');
                const anio = moment(fecha).format('YYYY');
                const dia = moment(fecha).format('DD');
                
                const fechaHoy = moment() 
                const mesHoy = moment(fechaHoy).format('MM');
                const anioHoy = moment(fechaHoy).format('YYYY');
                const diahoy = moment(fechaHoy).format('DD');

                const diferenciaEnMeses = (anioHoy - anio) * 12 + (mesHoy - mes) + (diahoy - dia) * 30;

                console.log({diferenciaEnMesesUnicoFuturo: diferenciaEnMeses})

                let s = gasto.monto * (1 / (1 + 0.004867) ** (diferenciaEnMeses + 1))

                return getDañoEmergente(gasto, ipcs, data)
            } else {
                return getDañoEmergente(gasto, ipcs, data)

            }
        }
    }


    useEffect(() => {
        getIpcs()
    }, [])

    
    useEffect(() => {
        let localPasados = 0
        let localFuturos = 0

        if (ipcs.length > 0 && gastos.length > 0) {
            gastos.forEach(gasto => {
                if (gasto.unico){
                    localPasados += getDañoEmergente(gasto, ipcs, data)
                } else {
                    localPasados += getDañoEmergente(gasto, ipcs, data)
                    
                }
            })
        }

        setCalculoPasado(localPasados)

        if (ipcs.length > 0 && gastosFuturos.length > 0) {
            gastosFuturos.forEach(gasto => {
                if (gasto.unico){
                    localFuturos += getDañoEmergente(gasto, ipcs, data)
                } else {
                    localFuturos += getDañoEmergente(gasto, ipcs, data)
    
                }
            })
        }

        setCalculoFuturo(localFuturos)
        
    }, [ipcs, gastos, gastosFuturos])

    return (
    <div className="cardContainerTable">
         <div className="rowTable">
            <label className="labelOptionBold">Resultado</label>
            <label className="labelOption">Indemnización por concepto de daño emergente</label>
         </div>
        <Table className="tablePregunta tableSelect mt-20">
            <TableHead className="tableHead">
                <TableRow>
                    <TableCell className="textTableHead blue1">Gasto</TableCell>
                    <TableCell className="textTableHead blue1">Tipo de gasto</TableCell>
                    <TableCell className="textTableHead blue1">Monto</TableCell>
                    <TableCell className="textTableHead blue1">Monto Actualizado</TableCell>
                    <TableCell className="textTableHead blue2">Fecha</TableCell>
                    <TableCell className="textTableHead blue3">Periodo indemnizable</TableCell>
                    <TableCell className="textTableHead blue4">Liquidación</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {gastos.map((ingreso, index) => (
                    <TableRow>
                        <TableCell className="tableCellBody">{ingreso.unico ? 'Único' : 'Periódico'}</TableCell>
                        <TableCell className="tableCellBody">{ingreso.tipo}</TableCell>
                        <TableCell className="tableCellBody">{getMoneyFormat(ingreso.monto)}</TableCell>
                        <TableCell className="tableCellBody">{getMoneyFormat(getGastoActualizado(ingreso))}</TableCell>
                        <TableCell className="tableCellBody">{ingreso.fecha1} {getSecondDate(ingreso)}</TableCell>
                        <TableCell className="tableCellBody">{getPeriod(ingreso, data.fechaLiquidacion, data)}</TableCell>
                        <TableCell className="tableCellBody">{getMoneyFormat(getGastoActualizado(ingreso, false))}</TableCell>
                    </TableRow>
                ))}
                {gastosFuturos.map((ingreso, index) => (
                    <TableRow>
                        <TableCell className="tableCellBody">{ingreso.unico ? 'Único' : 'Periódico'}</TableCell>
                        <TableCell className="tableCellBody">{ingreso.tipo}</TableCell>
                        <TableCell className="tableCellBody">{getMoneyFormat(ingreso.monto)}</TableCell>
                        <TableCell className="tableCellBody">{getMoneyFormat(getGastoActualizado(ingreso))}</TableCell>
                        <TableCell className="tableCellBody">{ingreso.fecha1} {getSecondDate(ingreso)}</TableCell>
                        <TableCell className="tableCellBody">{getPeriod(ingreso, data.fechaLiquidacion, data)}</TableCell>
                        <TableCell className="tableCellBody">{getMoneyFormat(getGastoActualizado(ingreso, false))}</TableCell>
                    </TableRow>
                ))}
                <TableRow>
                    <TableCell className="tableCellBody"></TableCell>
                    <TableCell className="tableCellBody"></TableCell>
                    <TableCell className="tableCellBody"></TableCell>
                    <TableCell className="tableCellBody"></TableCell>
                    <TableCell className="tableCellBody"></TableCell>
                    <TableCell className="textTableHead blue3">Total a liquidar</TableCell>
                    <TableCell className="tableCellBody">{getMoneyFormat(calculoPasado + calculoFuturo)}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
        <div className='navBtnsContainer'>
            {/* <BackButton onClick={() => dispatch(backStep()) } /> */}
            <ContinueButton onClick={() => dispatch(nextStep())} text="Siguiente"/>
        </div>
    </div>)
}

export default DañoTable