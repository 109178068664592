import React from "react";
import { useEffect, useState } from "react"
import moment from 'moment'
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import './revisionDate.css'
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { nextStep, setFechaHechos, setFechaLiquidacion } from "../../store/features/appSlice";
import Modal from "../../sharedComponents/modal/Modal"
import AlertDate from "./Alert/AlertDate";
import { IconButton, Tooltip } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import ErrorDate from "./errorDate/erroDate";
import LiquidationType from "./liquidationType/LiquidationType";

function RevisionDate() {
    const dispatch = useAppDispatch();
    const step = useAppSelector(state  => state.app.step)
    const data = useAppSelector(state  => state.app.data)
    
    const today = moment().format('YYYY-MM-DD');
    const [date, setDate] = useState(null)
    const [dateLiquidation, setDateLiquidation] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [modalError, closeModalError] = useState(false)

    const handleNext = () => {
        const zero1 = dateLiquidation && dateLiquidation[0]
        const zero2 = date && date[0]
        if (moment(dateLiquidation).isAfter(today) || moment(date).isAfter(today) || date === null || dateLiquidation === null || zero1 === '0' || zero2 === '0') return closeModalError(true)



        const diff = moment(dateLiquidation).diff(moment(date), 'years')
        if (diff >= 2) {
            setShowModal(true)
        } else if (date) {
            dispatch(setFechaHechos(date))
            dispatch(setFechaLiquidacion(dateLiquidation))
            dispatch(nextStep())
        }
    }

    const closeModal = () => {
        dispatch(setFechaHechos(date))
        dispatch(setFechaLiquidacion(dateLiquidation))
        setShowModal(false)
        dispatch(nextStep())
    }

    return (
        <div className="revisionDateContainer">
            <div className="titleStepContainerDate">
                <h1>DEFINICIÓN DE FECHAS</h1> 
            </div>
            <div className="cardStepBodyDate">
                <label className="labelOptionBold">Fecha del Evento DOL</label>
                <label className="labelOption mt-10 smallWidth">Por favor seleccione la fecha en la que ocurrieron los hechos:</label>
                <input className="dateSelect mt" type="date" onChange={e => setDate(e.target.value)}  required/>
                <label className="labelOptionBold">Fecha de Liquidación
                <Tooltip title={'La Fecha de Liquidación no puede ser posterior a la fecha actual.'}>
                    <IconButton style={{ paddingLeft: 0 }}>
                        <ErrorOutlineIcon style={{ height: 14, color: '#338dc7' }} />
                    </IconButton>
                </Tooltip>
                </label>
                <label className="labelOption mt-10 smallWidth">Por favor seleccione la fecha en la cual se quiere realizar la liquidación:</label>
                <input className="dateSelect mt" type="date" onChange={e => setDateLiquidation(e.target.value)} required max={new Date().toISOString().split('T')[0]}/>
                <LiquidationType />
            </div>
            {date && dateLiquidation && (
                <div className="continueContainerDate">
                    <button disabled={!date} className="continueBtn date Btn mt" onClick={handleNext}>Continuar</button>
                </div>
            )}
            <Modal showClose={true} open={modalError} onClose={() => closeModalError(false)} content={<ErrorDate closeModal={closeModalError} />} />
            <Modal background='#338dc7f7' showClose={false} open={showModal} onClose={closeModal} content={<AlertDate closeModal={closeModal} />} />
        </div>)
}

export default RevisionDate;