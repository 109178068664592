import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import React, { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { Delete } from '@mui/icons-material'
import './GestorIngresos.css'
import { setIngresos } from "../../../store/features/appSlice"
import Modal from "../../../sharedComponents/modal/Modal"
import Insert from "../../../sharedComponents/insertMoney/Insert"
import { firtsMayus, getSecondDate } from "../../../libs/helpers"

function GestorDeIngresos({setShowGestorIngresos}) {
    const dispatch = useAppDispatch()
    const data = useAppSelector(state => state.app.data)

    const [openInsert,setOpenInsert] = useState(false)

    const handleDelete = indexIngreso => {
        const newIngresos = data.ingresos.filter((i, index) => index !== indexIngreso)

        dispatch(setIngresos(newIngresos))
    }

    return(        
    <div className="gestorIngresosContainer">
        <div className="gestorIngresosHeader">
            <label className="labelOptionBold">Ingresos</label>
            <label className="labelOption mt-10">A continuación añada los ingresos de la víctima</label>
        </div>
        <Table className="tablePregunta mt-20">
            <TableHead className="tableHead">
                <TableRow>
                    <TableCell className="textTableHead blue1">Ingreso de suma</TableCell>
                    <TableCell className="textTableHead blue2">Tipo de Ingreso</TableCell>
                    <TableCell className="textTableHead blue3">Rango de fechas</TableCell>
                    <TableCell className="textTableHead blue4">Monto</TableCell>
                    <TableCell className="textTableHead blue4">Acciones</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.ingresos.map((ingreso, index) => (
                    <TableRow>
                        <TableCell className="tableCellBody">{ingreso.unico ? 'Única' : 'Periódica'}</TableCell>
                        <TableCell className="tableCellBody">{firtsMayus(ingreso.tipo)}</TableCell>
                        <TableCell className="tableCellBody">{ingreso.fecha1} {getSecondDate(ingreso)}</TableCell>
                        <TableCell className="tableCellBody">${ingreso.monto}</TableCell>
                        <TableCell className="tableCellBody deleteCell" onClick={() => handleDelete(index)}><div className="deleteContainerTable"><Delete style={{ height: 14 }}/>Eliminar</div></TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        {/* <div className="footerGestor">
            <button className='buttonIngreso' onClick={() => setOpenInsert(true)}>AÑADIR INGRESOS</button>
            <button className='buttonIngreso blueGestor' onClick={() => setShowGestorIngresos(false)}>SIGUIENTE</button>
        </div> */}
        <Modal content={<Insert setInsert={setOpenInsert} action="ingresos" allowAny={true} />} open={openInsert} onClose={() => setOpenInsert(false)} />

    </div>
)
}

export default GestorDeIngresos