import { configureStore } from '@reduxjs/toolkit'
import appSlice from './features/appSlice'
import userSlice from './features/userSlice'

export const store = configureStore({
    reducer: {
        app: appSlice,
        user: userSlice,
        // [requestApi.reducerPath]: requestApi.reducer,
        // [profileApi.reducerPath]: profileApi.reducer,
    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    //     .concat(requestApi.middleware)
    //     .concat(profileApi.middleware)
})
