import { Divider } from "@mui/material"
import React from "react"
import './alertIngresos.css'
import alertIcon from '../../../imgs/ic7.png'

function AlertIngresos({handleCloseModalAlert}) {
    return (
        <div className="containerAlertIngresos">
            <img src={alertIcon} className="alertIcon"/>
            <h1>¡ATENCIÓN!</h1>
            <Divider style={{ width: '100%', backgroundColor: '#fff'}}  />
            <p className="textAlertIngresos">
                Para continuar con la simulación, por favor añada el
                ingreso correspondiente a su salario o renta mensual.
            </p>
            <button className="blueBTN" onClick={handleCloseModalAlert}>ENTENDIDO</button>

        </div>
    )
}

export default AlertIngresos