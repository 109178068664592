import React, { useState } from 'react'
import { Icon } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';

function UserIcon() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleCloseSession = () => {
        localStorage.removeItem('tokenDla')
        navigate('/login')
    }

    const handleClickPanel = ev => {
        console.log(ev.target)
    }

    return (
        <>
            <div className='personIconContainer' onClick={() => setOpen(!open)}>
                <PersonIcon />
            </div>
            {open && (
                <div className='messageContainer'>
                    <p>Administra mi cuenta preferencias</p>
                    <button onClick={handleCloseSession}>Cerrar sesión</button>
                </div>
            )}
        </>
    )
}

export default UserIcon