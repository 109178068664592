import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import config from '../../libs/config'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'

function IpcTable({recargar, setRecargar}) {

    const [data, setData] = useState([])
    const [heads, setHeads] = useState([])
    const [bodyForMonth, setBodyForMonth] = useState([])

    const getData = async () => {
        await Axios.get(`${config.api}/api/ipc`). then(response => {
            setData(response.data)
        })
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        getData()
    },[recargar])

    useEffect(() => {
        let ultimo
        const headsToSet = []

        if (data.length > 0) {
            data.forEach((d) => {
                if (d.ano !== ultimo) {
                    headsToSet.push(d.ano)
                    ultimo = d.ano
                }
            })
        }
        setHeads([])
        setHeads(['Meses', ...headsToSet])

        const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
        const bodyToSet = []

        meses.forEach(mes => {
            const filtered = data.filter(d => d.mes === mes)

            bodyToSet.push({mes, data: filtered})
        })

        setBodyForMonth(bodyToSet)


    }, [data])
    
    return (
        <TableContainer >
            <Table >
                <TableHead>
                    <TableRow>
                        {heads.map(head => (
                            <TableCell key={head}>{head}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {bodyForMonth.map(body => (
                        <TableRow>
                            {<TableCell key={body.mes}>{body.mes}</TableCell> }
                            {body.data.map((d , index) => (
                                <TableCell key={index}>{d.valor}</TableCell> 
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
    
}

export default IpcTable