import { Divider } from "@mui/material"
import React from "react"
import AlertIcon from '../../../imgs/ic5.png'
import './errorDate.css'

function ErrorDate() {
    return (
        <div className="erroDateContainer">
            <img src={AlertIcon} />
            <h1>ERROR</h1>
            <Divider style={{ width: '100%', backgroundColor: '#606060'}}  />
            <p>La Fecha de liquidación no puede ser posterior a la fecha actual, por favor <b>Ingrese otra</b></p>
        </div>
        
    )
}

export default ErrorDate