import moment from 'moment'
import { mortalidadHombres, mortalidadMujeres } from '../sharedComponents/mortalidades/mortalidades'
import config from './config'

const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

export const getMoneyFormat = (value) =>{
    const valueFormated = Math.round(value).toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0, maximumSignificantDigits: 21, })
    return valueFormated
}

export const capitalize = (string) => {
    if (string.length === 0) return string

    return string[0].toUpperCase() + string.slice(1)
}

export const getFutureDate = (date, years) => {
    const newDate = moment(date).add(years, 'years').format('YYYY-MM-DD')
    return newDate
}

export const getYearsOld = (date) => {
    const yearsOld = moment().diff(date, 'years')
    return yearsOld
}
export const getValorAbsoluto = (value) => {
    if (value + value === 0) {
        return value
    } else {
        return Math.abs(value)
    }
}

export const diffMonths = (date1, date2) => {
    var diffDays = (date2 - date1) / (1000 * 60 * 60 * 24);
    var diffMonths = diffDays / 30;
    return diffMonths.toFixed(2); // redondear a 2 decimales
}

export const getDañoEmergente = (gasto, ipcs, data, montoActualizado = false) => {
    if (moment(data.fechaLiquidacion).diff(moment(gasto.fecha1), 'days') > 0) {
        if (gasto.unico){
            //pasado y unico
            const fecha = moment(gasto.fecha1) 
            const mes = moment(fecha).format('MM');
            const anio = moment(fecha).format('YYYY');

            const fechaHoy = moment(data.fechaLiquidacion) 
            const mesHoy = moment(fechaHoy).format('MM');
            const anioHoy = moment(fechaHoy).format('YYYY');

            const diferenciaEnMeses = diffMonths(fecha, fechaHoy);

            console.log('diferenciaEnMesesPasadoUnico', diferenciaEnMeses, gasto)

            const ipcInicial = ipcs.filter(ipc => ipc.ano === parseInt(anio) && ipc.mes === meses[parseInt(mes) - 1])[0]
            const ipcFinal = ipcs.filter(ipc => ipc.ano === parseInt(anioHoy) && ipc.mes === meses[parseInt(mesHoy) - 1])[0]

            let sa = gasto.monto * (ipcFinal.valor / ipcInicial.valor)


            if (montoActualizado) {
                return sa
            }

            let s = sa * (1 + 0.004867)** (diferenciaEnMeses + 1)

            return s
        } else {
            //pasado periodico

            const fecha1 = moment(gasto.fecha1) 
            const mes1 = moment(fecha1).format('MM');
            const anio1 = moment(fecha1).format('YYYY');
            const dia1 = moment(fecha1).format('DD');

            let anio2;
            let mes2;
            let dia2;

            let diferenciaEnMeses = 1;

            let diferenciaEnMeses2 = 1; // solo se usa si la fecha 2 es diferente a la fecha de liquidación
            let monto = gasto.monto;
            
            if (gasto.fecha2 === data.fechaLiquidacion) {
                const fecha2 = moment(data.fechaLiquidacion) 
                mes2 = moment(fecha2).format('MM');
                anio2 = moment(fecha2).format('YYYY');
                dia2 = moment(fecha2).format('DD');

                diferenciaEnMeses = getValorAbsoluto(diffMonths(fecha1, fecha2))
                // diferenciaEnMeses = getValorAbsoluto((anio2 - anio1) * 12 + (mes2 - mes1) + (dia2 - dia1) * 30)
            } else {
                const fecha2 = moment(gasto.fecha2) 
                mes2 = moment(fecha2).format('MM');
                anio2 = moment(fecha2).format('YYYY');
                dia2 = moment(fecha2).format('DD');

                diferenciaEnMeses = getValorAbsoluto(diffMonths(fecha1, fecha2))
                // diferenciaEnMeses = getValorAbsoluto(((anio2 - anio1) * 12) + (mes2 - mes1) + ((dia2 - dia1) * 30))

                const fecha3 = moment(data.fechaLiquidacion)
                let mes3 = moment(fecha3).format('MM');
                let anio3 = moment(fecha3).format('YYYY');
                let dia3 = moment(fecha3).format('DD');

                diferenciaEnMeses2 = getValorAbsoluto(diffMonths(fecha2, fecha3))

                console.log(diferenciaEnMeses, diferenciaEnMeses2, ((anio3 - anio2) * 12 )+ (mes3 - mes2) + ((dia3 - dia2) * 30))

                monto = monto * diferenciaEnMeses
            }



            console.log('diferenciaEnMesesPasadoPeriodico', diferenciaEnMeses, gasto)

            const ipcInicial = ipcs.filter(ipc => ipc.ano === parseInt(anio1) && ipc.mes === meses[parseInt(mes1) - 1])[0]
            const ipcFinal = ipcs.filter(ipc => ipc.ano === parseInt(anio2) && ipc.mes === meses[parseInt(mes2) - 1])[0]

            let sa = monto * (ipcFinal.valor / ipcInicial.valor)

            let s = 0

            if (gasto.fecha2 !== data.fechaLiquidacion) {
                s = sa * (1 + 0.004867)** (diferenciaEnMeses2 + 1)
            } else {
                s = sa * ((((1 + 0.004867)** diferenciaEnMeses) - 1) / 0.004867)
            }

            if (montoActualizado) {
                return sa
            }

            return s
            
        }
    } else {
        if (gasto.unico){
            // futuro y unico
            const fecha = moment(gasto.fecha1) 
            const mes = moment(fecha).format('MM');
            const anio = moment(fecha).format('YYYY');
            const dia = moment(fecha).format('DD');
            
            const fechaHoy = moment(data.fechaLiquidacion) 
            const mesHoy = moment(fechaHoy).format('MM');
            const anioHoy = moment(fechaHoy).format('YYYY');
            const diahoy = moment(fechaHoy).format('DD');

            // const diferenciaEnMeses = getValorAbsoluto((anioHoy - anio) * 12 + (mesHoy - mes) + (diahoy - dia) * 30);
            const diferenciaEnMeses = getValorAbsoluto(diffMonths(fecha, fechaHoy));

            console.log('diferenciaEnMesesUnicoFuturo', diferenciaEnMeses, gasto)

            if (montoActualizado) {
                return gasto.monto
            }


            let s = gasto.monto * (1 / (1 + 0.004867) ** (diferenciaEnMeses + 1))

            return s
        } else {
            //futuro periodico

            //para vitalicio
            const mortalidad = data.sexo === 'masculino' ? mortalidadHombres.find(mort => mort.edad === data.edad) : mortalidadMujeres.find(mort => mort.edad === data.edad)
            const fecha2Copy = getFutureDate(data.fechaLiquidacion, mortalidad.anos)


            if (gasto.fecha1 === data.fechaLiquidacion) {
                const fecha1 = moment(data.fechaLiquidacion) 
                const mes1 = moment(fecha1).format('MM');
                const anio1 = moment(fecha1).format('YYYY');
                const dia1 = moment(fecha1).format('DD');

                const fecha2 = (gasto.vitalicio && JSON.parse(gasto.vitalicio)) ? moment(fecha2Copy) : moment(gasto.fecha2)

                const mes2 = moment(fecha2).format('MM');
                const anio2 = moment(fecha2).format('YYYY');
                const dia2 = moment(fecha1).format('DD');
    
                // const diferenciaEnMeses = getValorAbsoluto((anio2 - anio1) * 12 + (mes2 - mes1) + (dia2 - dia1) * 30)
                const diferenciaEnMeses = getValorAbsoluto(diffMonths(fecha1, fecha2));
    
                console.log('diferenciaEnMesesPeriodicoFuturo', diferenciaEnMeses, gasto)
    
                let sa = gasto.monto
    
                if (montoActualizado) {
                    return sa
                }
    
                let s = sa * ((
                    ((1 + 0.004867)** diferenciaEnMeses) - 1) / (0.004867 * ((1 + 0.004867)** diferenciaEnMeses)))
    
                return s
            } else {
                const fecha1 = moment(gasto.fecha1) 
                const mes1 = moment(fecha1).format('MM');
                const anio1 = moment(fecha1).format('YYYY');
                const dia1 = moment(fecha1).format('DD');
    
                let fecha2 = (gasto.vitalicio && JSON.parse(gasto.vitalicio)) ? moment(fecha2Copy) : moment(gasto.fecha2)

                const mes2 = moment(fecha2).format('MM');
                const anio2 = moment(fecha2).format('YYYY');
                const dia2 = moment(fecha1).format('DD');

                let fecha3 = moment(data.fechaLiquidacion)
                const mes3 = moment(fecha3).format('MM');
                const anio3 = moment(fecha3).format('YYYY');
                const dia3 = moment(fecha1).format('DD');

                // const diferenciaEnMeses = getValorAbsoluto((anio2 - anio1) * 12 + (mes2 - mes1) + (dia2 - dia1) * 30)
                // const diferenciaEnMeses2 = getValorAbsoluto((anio3 - anio2) * 12 + (mes3 - mes2) + (dia3 - dia2) * 30)

                const diferenciaEnMeses = getValorAbsoluto(diffMonths(fecha1, fecha2));
                const diferenciaEnMeses2 = getValorAbsoluto(diffMonths(fecha2, fecha3));

                let sa = gasto.monto * diferenciaEnMeses

                if (montoActualizado) {
                    return sa
                }

                let s = sa * (1 / (1 + 0.004867) ** (diferenciaEnMeses2 + 1))

                return s


            }



        }
    }

}

export const getLucroCesnte = (gasto, ipcs, data, montoActualizado = false) => {

    if (moment(data.fechaLiquidacion).diff(moment(gasto.fecha1), 'days') > 0){
        if (gasto.unico){
            // pasado y unico
            const fecha = moment(gasto.fecha1) 
            const mes = moment(fecha).format('MM');
            const anio = moment(fecha).format('YYYY');

            const fechaHoy = moment(data.fechaLiquidacion) 
            const mesHoy = moment(fechaHoy).format('MM');
            const anioHoy = moment(fechaHoy).format('YYYY');

            const diferenciaEnMeses = getValorAbsoluto(diffMonths(fecha, fechaHoy));

            console.log({diferenciaEnMesesPasadoUnico: diferenciaEnMeses})

            const ipcInicial = ipcs.filter(ipc => ipc.ano === parseInt(anio) && ipc.mes === meses[parseInt(mes) - 1])[0]
            const ipcFinal = ipcs.filter(ipc => ipc.ano === parseInt(anioHoy) && ipc.mes === meses[parseInt(mesHoy) - 1])[0]

            
            
            let sa = gasto.monto * (ipcFinal.valor / ipcInicial.valor)

            if (montoActualizado) {
                return sa
            }
            

            let s = sa * (1 + 0.004867)** (diferenciaEnMeses + 1)


            if (data.consecuencia === 'incapacidad' && data.tipoDeIncapacidad === 'permanente') {
                s = s * (data.porcentajeIncapacidad / 100)
            }
            
            return s
        } else {
            // pasado periodico
            const fecha1 = moment(gasto.fecha1)
            const mes1 = moment(fecha1).format('MM');
            const anio1 = moment(fecha1).format('YYYY');
            
            let anio2;
            let mes2;

            let diferenciaEnMeses = 1;

            let diferenciaEnMeses2 = 1; // solo se usa si la fecha 2 es diferente a la fecha de liquidación
            let monto = gasto.monto;
            
            if (gasto.fecha2 === data.fechaLiquidacion) {
                const fecha2 = moment(data.fechaLiquidacion) 
                mes2 = moment(fecha2).format('MM');
                anio2 = moment(fecha2).format('YYYY');

                diferenciaEnMeses = getValorAbsoluto(diffMonths(fecha1, fecha2))
            } else {
                const fecha2 = moment(gasto.fecha2) 
                // mes2 = moment(fecha2).format('MM');
                // anio2 = moment(fecha2).format('YYYY');

                diferenciaEnMeses = getValorAbsoluto(diffMonths(fecha1, fecha2))

                const fecha3 = moment(data.fechaLiquidacion)
                mes2 = moment(fecha3).format('MM');
                anio2 = moment(fecha3).format('YYYY');

                diferenciaEnMeses2 = getValorAbsoluto(diffMonths(fecha2, fecha3))

                monto = monto * diferenciaEnMeses
            }

            
            const ipcInicial = ipcs.filter(ipc => ipc.ano === parseInt(anio1) && ipc.mes === meses[parseInt(mes1) - 1])[0]
            const ipcFinal = ipcs.filter(ipc => ipc.ano === parseInt(anio2) && ipc.mes === meses[parseInt(mes2) - 1])[0]
            console.log('diferenciaEnMesesPasadoPeriodico:', diferenciaEnMeses, 'f:', ipcFinal.valor, 'i:',  ipcInicial.valor)
            
            

            let sa = monto * (ipcFinal.valor / ipcInicial.valor)

            if (gasto.tipo === "Salario o Renta mensual" && data.situacionLaboral === 'empleado') {
                if (gasto.monto < config.salarioMinimo){ //a futuro agregar una variable en la db
                    sa = config.salarioMinimo * (ipcFinal.valor / ipcInicial.valor)
                }

                if (gasto.monto > config.salarioMinimo && gasto.monto < config.salarioMinimo) {
                    sa = sa * 1.25
                }

                if (data.consecuencia === 'muerte') {
                    sa = sa * 0.75
                }
            }


            if (montoActualizado) {
                return sa
            }
            
            let s = 0

            if (gasto.fecha2 !== data.fechaLiquidacion) {
                s = sa * (1 + 0.004867)** (diferenciaEnMeses2 + 1)
            } else {
                s = sa * ((((1 + 0.004867)** diferenciaEnMeses) - 1) / 0.004867)
            }

            if (data.consecuencia === 'incapacidad' && data.tipoDeIncapacidad === 'permanente') {
                s = s * (data.porcentajeIncapacidad / 100)
            }
            
            return s
            
        }
    } else {
        if (gasto.unico){
            // futuro unico
            const fecha = moment(gasto.fecha1) 
            const mes = moment(fecha).format('MM');
            const anio = moment(fecha).format('YYYY');

            const fechaHoy = moment(data.fechaLiquidacion) 
            const mesHoy = moment(fechaHoy).format('MM');
            const anioHoy = moment(fechaHoy).format('YYYY');

            let diferenciaEnMeses = getValorAbsoluto(diffMonths(fechaHoy, fecha));

            console.log('diferenciaEnMesesUnicoFuturo:', diferenciaEnMeses, gasto)

            if (montoActualizado) {
                return gasto.monto
            }

            let s = gasto.monto * (1 / (1 + 0.004867) ** (diferenciaEnMeses + 1))

            if (data.consecuencia === 'incapacidad' && data.tipoDeIncapacidad === 'permanente') {
                s = s * (data.porcentajeIncapacidad / 100)
            }

            return s
        } else {
            // futuro periodico
            if (gasto.fecha1 === data.fechaLiquidacion) {
                const fecha1 = moment(data.fechaLiquidacion)
            
                const mortalidad = data.sexo === 'masculino' ? mortalidadHombres.find(mort => mort.edad === data.edad) : mortalidadMujeres.find(mort => mort.edad === data.edad)
    
                const fecha2Copy = getFutureDate(data.fechaLiquidacion, mortalidad.anos)
    
                const fecha2 = (gasto.vitalicio && JSON.parse(gasto.vitalicio))  ? moment(fecha2Copy) : moment(gasto.fecha2)
    
                let diferenciaEnMeses = getValorAbsoluto(diffMonths(fecha1, fecha2));
    
    
                console.log('diferenciaEnMesesPeriodicoFuturo:', diferenciaEnMeses, gasto)
    
                let sa = gasto.monto
    
                if (gasto.tipo === "Salario o Renta mensual" && data.situacionLaboral === 'empleado') {
                    if (gasto.monto < config.salarioMinimo){ //a futuro agregar una variable en la db
                        sa = config.salarioMinimo
                    }
    
                    if (gasto.monto > config.salarioMinimo && gasto.monto < config.salarioMinimo) {
                        sa = sa * 1.25
                    }
    
                    if (data.consecuencia === 'muerte') {
                        sa = sa * 0.75
                    }
                }
    
                if (montoActualizado) {
                    return sa
                }
                
    
                let s = sa * ((((1 + 0.004867)** diferenciaEnMeses) - 1) / (0.004867 * ((1 + 0.004867)** diferenciaEnMeses)))

                if (data.consecuencia === 'incapacidad' && data.tipoDeIncapacidad === 'permanente') {
                    s = s * (data.porcentajeIncapacidad / 100)
                }
    
                return s
            } else {
                const fecha1 = moment(gasto.fecha1)
            
                const mortalidad = data.sexo === 'masculino' ? mortalidadHombres.find(mort => mort.edad === data.edad) : mortalidadMujeres.find(mort => mort.edad === data.edad)
    
                const fecha2Copy = getFutureDate(data.fechaLiquidacion, mortalidad.anos)
    
                const fecha2 = (gasto.vitalicio && JSON.parse(gasto.vitalicio))  ? moment(fecha2Copy) : moment(gasto.fecha2)

                const fecha3 = moment(data.fechaLiquidacion)
    
                let diferenciaEnMeses = getValorAbsoluto(diffMonths(fecha1, fecha2));
                let diferenciaEnMeses2 = getValorAbsoluto(diffMonths(fecha3, fecha2));
    
    
                console.log('diferenciaEnMesesPeriodicoFuturo2:', diferenciaEnMeses2, gasto)
    
                let sa = gasto.monto * diferenciaEnMeses
    
                if (gasto.tipo === "Salario o Renta mensual" && data.situacionLaboral === 'empleado') {
                    if (gasto.monto < config.salarioMinimo){ //a futuro agregar una variable en la db
                        sa = config.salarioMinimo * diferenciaEnMeses
                    }
    
                    if (gasto.monto > config.salarioMinimo && gasto.monto < config.salarioMinimo) {
                        sa = sa * 1.25
                    }
    
                    if (data.consecuencia === 'muerte') {
                        sa = sa * 0.75
                    }
                }
    
                if (montoActualizado) {
                    return sa
                }
                
    
                let s = sa * (1 / (1 + 0.004867) ** (diferenciaEnMeses2 + 1))

                if (data.consecuencia === 'incapacidad' && data.tipoDeIncapacidad === 'permanente') {
                    s = s * (data.porcentajeIncapacidad / 100)
                }
    
                return s
            }

    }
    }
}

export const getPeriod = (ingreso, fechaLiquidacion, data = {}) => {
    if (ingreso.unico) {
        let diff = getValorAbsoluto(moment(ingreso.fecha1).diff(moment(fechaLiquidacion), 'months', true))
        diff = Math.floor(diff)
        return `${diff} meses`
    } else {
        if (ingreso.vitalicio && JSON.parse(ingreso.vitalicio)){
            if (ingreso.fecha2) {
                // const futureDate = getFutureDate(ingreso.fecha1, ingreso.fecha2)
                let diff = getValorAbsoluto(moment(ingreso.fecha2).diff(moment(ingreso.fecha1), 'months', true))
                diff = Math.floor(diff)
                return `${diff} meses`
                
            } else {
                const mortalidad = data.sexo === 'masculino' ? mortalidadHombres.find(mort => mort.edad === data.edad) : mortalidadMujeres.find(mort => mort.edad === data.edad)
                const futureDate = getFutureDate(ingreso.fecha1, mortalidad.anos)
                let diff = getValorAbsoluto(moment(futureDate).diff(moment(ingreso.fecha1), 'months', true))
                diff = Math.floor(diff)
                return `${diff} meses`
            }
        } else {
            if (moment(ingreso.fecha2).isAfter(moment(fechaLiquidacion))) {
                let diff = getValorAbsoluto(moment(ingreso.fecha2).diff(moment(fechaLiquidacion), 'months', true))
                diff = Math.floor(diff)
                console.log('fecha futura', diff)
                return `${diff} meses`
            } else {
                let diff = getValorAbsoluto(moment(ingreso.fecha1).diff(moment(fechaLiquidacion), 'months', true))
                diff = Math.floor(diff)
                console.log('fecha pasada', diff)
                return `${diff} meses`
            }

        }
    }
}

export  const getSecondDate = (ingreso) => {
    if (ingreso.unico) {
        return ''
    } else {
        if (ingreso.vitalicio && JSON.parse(ingreso.vitalicio)){
            if (ingreso.fecha2) {
                return `- ${ingreso.fecha2}`
            }
            return '- Vitalicio'
        }
        return `- ${ingreso.fecha2}`
    }
}

export const firtsMayus = word => {
    return word.charAt(0).toUpperCase() + word.slice(1)
}

export const getProbability = (total, data) => {
    if (data.probabilityLoss === 'probable') {
        return total * 1
    }
    if (data.probabilityLoss === 'posible') {
        return  total * 0.5
    }
    if (data.probabilityLoss === 'eventual') {
        return  total * 0.1
    }
}