import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import './dañoMoral.css'
import { setDañoMoral } from '../../../store/features/appSlice'
import { firtsMayus, getMoneyFormat } from '../../../libs/helpers'
import ContinueButton from '../../../sharedComponents/continueButton/ContinueButton'
import config from '../../../libs/config'

function DañoMoral({ question, setQuestion }) {

    const dispatch = useAppDispatch()

    const data = useAppSelector(state => state.app.data)

    const getDañoMoral = reclamante => {
        let  dañoMoralReturn = 60000000

        // if (data.consecuencia === 'muerte' && data.sufrio === 'si') {
        //     if (reclamante.parentesco === 'padres' || reclamante.parentesco === 'hijos' || reclamante.parentesco === 'conyuge') {
        //         if (data.tipoLiquidacion === 'jurisdiccionAdministrativa') {
        //             dañoMoralReturn = config.salarioMinimo * 100
        //         } else {
        //             dañoMoralReturn = 60000000
        //         }
        //     }

        //     if (reclamante.parentesco === 'hermanos' || reclamante.parentesco === 'abuelos' ) {
        //         if (data.tipoLiquidacion === 'jurisdiccionAdministrativa') {
        //             dañoMoralReturn = config.salarioMinimo * 50
        //         } else {
        //             dañoMoralReturn = 30000000
        //         }
        //     }
        // }

        if (data.tipoLiquidacion === 'jurisdiccionAdministrativa') {
            dañoMoralReturn = config.salarioMinimo * 100
        }

        return dañoMoralReturn
    }

    useEffect(() => {
        let  dañoMoralTotal = 60000000
    
        // if (data.consecuencia === 'muerte' && data.sufrio === 'si') {
        //     data.reclamantes.forEach(reclamante => {
        //         if (reclamante.parentesco === 'padres' || reclamante.parentesco === 'hijos' || reclamante.parentesco === 'conyuge') {
        //             if (data.tipoLiquidacion === 'jurisdiccionAdministrativa') {
        //                 dañoMoralTotal += config.salarioMinimo * 100
        //             } else {
        //                 dañoMoralTotal += 60000000
        //             }
        //         }

        //         if (reclamante.parentesco === 'hermanos' || reclamante.parentesco === 'abuelos' ) {
        //             if (data.tipoLiquidacion === 'jurisdiccionAdministrativa') {
        //                 dañoMoralTotal += config.salarioMinimo * 50
        //             } else {
        //                 dañoMoralTotal += 30000000
        //             }
        //         }
        //     })
        // }

        if (data.tipoLiquidacion === 'jurisdiccionAdministrativa') {
            dañoMoralTotal = config.salarioMinimo * 100
        }

        dispatch(setDañoMoral(dañoMoralTotal))
    })

    return (
        <div className='cardPerjuicios'>
            <div className='subContainerPerjuicios'>
                <label className='labelOptionBold'>Daño moral
                            <Tooltip title="El daño moral es la tristeza, aflicción o angustia derivada del daño.">
                                <IconButton style={{ paddingLeft: 5}}>
                                    <ErrorOutlineIcon style={{ width: 15, color: '#338dc7' }}  />
                                </IconButton>
                            </Tooltip>
                </label>
                <label className='labelOption'>Esto será el daño moral a pagar:</label> 
            </div>
            <Table className="tablePregunta mt-20">
                <TableHead className="tableHead">
                    <TableRow>
                        <TableCell className="textTableHead blue1">Consecuencia del daño para la víctima</TableCell>
                        <TableCell className="textTableHead blue2">Nombre de la Víctima</TableCell>
                        <TableCell className="textTableHead blue4">Daño heredado del causante</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell className="tableCellBody">Muerte</TableCell>
                        <TableCell className="tableCellBody">{firtsMayus(data.nombre)}</TableCell>
                        <TableCell className="tableCellBody">{getMoneyFormat(getDañoMoral())}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <div className='continueBtnContainer'>
                <ContinueButton onClick={() => setQuestion(question + 1)} />
            </div>
        </div>
    )
}

export default DañoMoral