import React, { useEffect, useState } from "react";
import Axios from 'axios'
import config from "../../libs/config";

function IpcInsert({recargar, setRecargar}) {
    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    const [data, setData] = useState({ mes: 'Enero', ano: '', valor: ''})

    const setInformacion = (ev) => {
        const {name, value} = ev.target
        setData({...data, [name]: value })
    }
    
    const sendInformacion = async () => {
        await Axios.post(`${config.api}/api/ipc`, data). then(response => {
            setRecargar(recargar + 1)
        })
    }

    
    return (
        <div>
            <h1 style={{ color: '#000'}}>Insertar nuevo ipc</h1>
            <select name="select" onChange={ev => setData({...data, mes: ev.target.value })}>
                {meses.map(mes => (
                    <option value={mes}>{mes}</option>
                ))}
            </select>
            <input type="number" placeholder="año" name="ano" onChange={setInformacion}/>
            <input type="number" placeholder="valor" name="valor" onChange={setInformacion}/>
            <button onClick={sendInformacion}>Guardar</button>
        </div>
    )
}

export default IpcInsert