import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import './dañoMoral2.css'
import { setDañoMoral } from '../../../store/features/appSlice'
import { firtsMayus, getMoneyFormat } from '../../../libs/helpers'
import ContinueButton from '../../../sharedComponents/continueButton/ContinueButton'
import config from '../../../libs/config'

function DañoMoral2({ question, setQuestion }) {

    const dispatch = useAppDispatch()

    const data = useAppSelector(state => state.app.data)

    const getDañoMoral = () => {
        let  dañoMoralReturn = 0

        if (data.tipoDeIncapacidad === 'permanente') {
            let initValor = 60000000
            const porcentaje = data.porcentajeIncapacidad / 100

            if (data.tipoLiquidacion === 'jurisdiccionAdministrativa') {
                if (porcentaje >= 0.50) {
                    initValor = 100 * config.salarioMinimo
                }

                if (porcentaje < 0.50 && porcentaje >= 0.40) {
                    initValor = 80 * config.salarioMinimo
                }

                if (porcentaje < 0.40 && porcentaje >= 0.30) {
                    initValor = 60 * config.salarioMinimo
                }

                if (porcentaje < 0.30 && porcentaje >= 0.20) {
                    initValor = 40 * config.salarioMinimo
                }

                if (porcentaje < 0.20 && porcentaje >= 0.10) {
                    initValor = 20 * config.salarioMinimo
                }

                if (porcentaje < 0.10) {
                    initValor = 10 * config.salarioMinimo
                }

                dañoMoralReturn = initValor

            } else {
                dañoMoralReturn = initValor * porcentaje
            }
        }

        return dañoMoralReturn
    }

    useEffect(() => {
        let  dañoMoralTotal = 0
    
        if (data.tipoDeIncapacidad === 'permanente') {
            let initValor = 60000000
            const porcentaje = data.porcentajeIncapacidad / 100

            if (data.tipoLiquidacion === 'jurisdiccionAdministrativa') {
                if (porcentaje >= 0.50) {
                    initValor = 100 * config.salarioMinimo
                }

                if (porcentaje < 0.50 && porcentaje >= 0.40) {
                    initValor = 80 * config.salarioMinimo
                }

                if (porcentaje < 0.40 && porcentaje >= 0.30) {
                    initValor = 60 * config.salarioMinimo
                }

                if (porcentaje < 0.30 && porcentaje >= 0.20) {
                    initValor = 40 * config.salarioMinimo
                }

                if (porcentaje < 0.20 && porcentaje >= 0.10) {
                    initValor = 20 * config.salarioMinimo
                }

                if (porcentaje < 0.10 && porcentaje >= 0.01) {
                    initValor = 10 * config.salarioMinimo
                }

                dañoMoralTotal = initValor
            } else {
                dañoMoralTotal = initValor * porcentaje
            }

        }

        if (dañoMoralTotal !== 0) {
            dispatch(setDañoMoral(dañoMoralTotal))
        }

    })

    return (
        <div className='cardPerjuicios'>
            <div className='subContainerPerjuicios'>
                <label className='labelOptionBold'>Daño moral
                            <Tooltip title="El daño moral es la tristeza, aflicción o angustia derivada del daño.">
                                <IconButton style={{ paddingLeft: 5}}>
                                    <ErrorOutlineIcon style={{ width: 15, color: '#338dc7' }}  />
                                </IconButton>
                            </Tooltip>
                </label>
                <label className='labelOption'>Esto será el daño moral a pagar:</label> 
            </div>
            <Table className="tablePregunta mt-20">
                <TableHead className="tableHead">
                    <TableRow>
                        <TableCell className="textTableHead blue1">Consecuencia del daño para la víctima</TableCell>
                        <TableCell className="textTableHead blue2">Perdida de la capacidad laboral</TableCell>
                        <TableCell className="textTableHead blue3">Daño Moral causado a la víctima por la lesión</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell className="tableCellBody">{firtsMayus(data.consecuencia)}</TableCell>
                        <TableCell className="tableCellBody">{data.porcentajeIncapacidad === 0 ? 'No aplica' : `${data.porcentajeIncapacidad}%` }</TableCell>
                        <TableCell className="tableCellBody">{getDañoMoral() === 0 ? 'No aplica' : getMoneyFormat(getDañoMoral())}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <div className='continueBtnContainer'>
                <ContinueButton onClick={() => setQuestion(question + 1)} />
            </div>
        </div>
    )
}

export default DañoMoral2