import React, {useEffect, useState} from "react";
import moment from "moment";
import './insert.css'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setLucroCensateFuturos, setLucroCesantePasados, setIngresos } from "../../store/features/appSlice";
import { Divider } from "@mui/material";

function Insert({setInsert, allowAny, note, action}) {
    const dispatch = useAppDispatch();
    const [gasto, setGasto] = useState({})
    const [unico, setPagoUnico] = useState(false)
    const [otroTipo, setOtroTipo] = useState(true)

    const data = useAppSelector(state => state.app.data)
    const ingresos = useAppSelector(state => state.app.data.ingresos)
    const gastosFuturosLucroCesante = useAppSelector(state => state.app.data.lucroCesante.futuros)

    const agregar = () => {
        const localGasto = {
            unico,
            ...gasto
        }
        console.log(localGasto)
        if (action === 'ingresos') {
            if (!unico) {
                if (moment(localGasto.fecha2).isAfter(moment(data.fechaLiquidacion)) 
                    && moment(localGasto.fecha1).isBefore(moment(data.fechaLiquidacion))) {
                    const gasto1 = {...localGasto, fecha2: data.fechaLiquidacion}
                    const gasto2 = {...localGasto, fecha1: data.fechaLiquidacion}

                    dispatch(setIngresos([...ingresos, gasto1, gasto2]))
                } else if (gasto.vitalicio && moment(localGasto.fecha1).isBefore(moment(data.fechaLiquidacion))) {
                    const gasto1 = {...localGasto, fecha2: data.fechaLiquidacion}
                    const gasto2 = {...localGasto, fecha1: data.fechaLiquidacion}

                    dispatch(setIngresos([...ingresos, gasto1, gasto2]))
                }else {
                    dispatch(setIngresos([...ingresos, localGasto]))
                }
            } else {
                dispatch(setIngresos([...ingresos, localGasto]))
            }
        }


        setInsert(false)
    }

    const getRuleForName = () => {
        if (gasto.tipo === 'variable') {
            return true
        } else {
            return false
        }
    }

    const setInformacion = (ev) => {
        const {name, value} = ev.target
        setGasto({...gasto, [name]: value })
    }


    return (
        <div className="modalContainer">
            <div className="insertHeader">
                <h1>AÑADIR INGRESO</h1>
                <Divider style={{ width: '100%', backgroundColor: '#686868'}}  />
            </div>
            <div className="unicoContainer">
                    <div className='radioContainer'>
                        <input style={{ margin: '0 0 0 0' }}    className='radioInput' type="radio" id="unico" name="sexo" value="true" onChange={ev => setPagoUnico(!unico)} />
                        <label style={{ margin: '0 0 0 10px' }} className='labelOption mt-10' for="unico">De suma única
                            <Tooltip title="El ingreso de suma única es aquel que se paga una vez">
                                <IconButton style={{ paddingLeft: 5}}>
                                    <ErrorOutlineIcon style={{ width: 15, color: '#338dc7' }}  />
                                </IconButton>
                            </Tooltip>
                        </label>
                    </div>
                    <div className='radioContainer'>
                        <input style={{ margin: '0 0 0 10px' }} className='radioInput' type="radio" id="periodica" name="sexo" value="false" onChange={ev => setPagoUnico(false)} />
                        <label style={{ margin: '0 0 0 10px' }} className='labelOption mt-10' for="periodica">De suma periódica
                            <Tooltip style={{ width: 5 }} title="El ingreso de suma periódica es quel que se paga varias veces. (ej. semanal, mensual, trimestral, etc.)">
                                <IconButton style={{ paddingLeft: 5}}>
                                    <ErrorOutlineIcon  style={{ width: 15, color: '#338dc7' }} />
                                </IconButton>
                            </Tooltip>
                        </label>
                    </div>
            </div>
            {
                unico ? (
                <>
                        <label className="labelOptionBold">Tipo de ingreso</label>
                        <input className="input mt" type="text" placeholder="" name="tipo" onChange={setInformacion} />
                        <label className="labelOptionBold">Fecha</label>
                        <label className="labelOption mt-10 widthInsert" >A continuación, añada la fecha en la que la víctima debió o debería obtener ese ingreso:</label>
                        <input className="dateSelect" type="date" onChange={ev => setGasto({...gasto, fecha1: ev.target.value })}/>
                        <label className="labelOptionBold">Monto</label>
                        <label className="labelOption mt-10 widthInsert" >Ingrese el monto en pesos colombianos, sin puntos ni comas:</label>
                        <input className="input mt" type="number" name="monto" onChange={setInformacion}/>
                </>) 
                : (<>
                        <label className="labelOptionBold">Tipo de ingreso</label>
                        {/* <input className="input mt" type="text" placeholder="" name="tipo" onChange={setInformacion} /> */}
                        <div className="unicoContainer">
                            <div className='radioContainer'>
                                <input style={{ margin: '0 0 0 0' }}    className='radioInput' type="radio" id="fijo" name="tipo" value="fijo" onChange={setInformacion} />
                                <label style={{ margin: '0 0 0 10px' }} className='labelOption mt-10' for="fijo">Fijo</label>
                            </div>
                            <div className='radioContainer'>
                                <input style={{ margin: '0 0 0 10px' }} className='radioInput' type="radio" id="variable" name="tipo" value="variable" onChange={setInformacion} />
                                <label style={{ margin: '0 0 0 10px' }} className='labelOption mt-10' for="variable">Variable</label>
                                {(getRuleForName() || gasto.tipo !== 'fijo') && (
                                    <>
                                        <label style={{ margin: '0 0 0 10px' }} className='labelOption mt-10' for="otro">Nombre</label>
                                        <input className="input specialInput" type="text" placeholder="" name="tipo" onChange={setInformacion}/>
                                    </>
                                )}
                            </div>
                        </div>
                        {(gasto.tipo !== 'variable') && (
                        <div className="unicoContainer">
                            <div className='radioContainer'>
                                <input style={{ margin: '0 0 0 0' }}    className='radioInput' type="radio" id="salario" name="tipo" value="Salario o Renta mensual" onChange={setInformacion} />
                                <label style={{ margin: '0 0 0 10px' }} className='labelOption mt-10' for="salario">Salario o renta mensual</label>
                            </div>
                            <div className='radioContainer'>
                                <input style={{ margin: '0 0 0 10px' }} className='radioInput' type="radio" id="otro" name="tipo" value="Otro" onChange={ev => {
                                                                                                                                                                setInformacion(ev)
                                                                                                                                                                setOtroTipo(!otroTipo)
                                                                                                                                                            }} />
                                <label style={{ margin: '0 0 0 10px' }} className='labelOption mt-10' for="otro">Otro</label>
                                <input className="input specialInput" disabled={otroTipo} type="text" placeholder="" name="tipo" onChange={setInformacion}/>
                            </div>
                        </div>
                        )}
                        <label className="labelOptionBold">Rango de fechas</label>
                        <label className="labelOption mt-10">Seleccione las fechas en la que la víctima debió o debería obtener ese ingreso:</label>
                        <div className="datesContainerInsert">
                            <div className="dateContainerInsert">
                                <label className="labelOption mt-10">Inicio</label>
                                <input className="dateSelect insertWidth" type="date" onChange={ev => setGasto({...gasto, fecha1: ev.target.value })}/>
                            </div>
                            <div className="dateContainerInsert">
                                <label className="labelOption mt-10">Fin</label>
                                <input className="dateSelect insertWidth" type="date" onChange={ev => setGasto({...gasto, fecha2: ev.target.value })}/>
                                <div className="radioContainer mt">
                                    <input style={{ margin: '5px 5px 0 0' }}    className='radioInput' type="radio" id="vitalicio" name="vitalicio" value="true" onChange={setInformacion} />
                                    <label className="labelOption mt-10">De forma vitalicia</label>
                                </div>
                            </div>

                        </div>
                        <label className="labelOptionBold">Monto
                            <Tooltip style={{ width: 5 }} title="Al ser una suma periódica, el
                                                                monto ingresado debe ser un
                                                                promedio de lo recibido durante los últimos 12 meses">
                                <IconButton style={{ paddingLeft: 5}}>
                                    <ErrorOutlineIcon  style={{ width: 15, color: '#338dc7' }} />
                                </IconButton>
                            </Tooltip>
                        </label>
                        <label className="labelOption mt-10 widthInsert" >Ingrese el monto mensual en pesos colombianos, sin puntos ni comas:</label>
                        <input className="input mt" type="number" placeholder="" name="monto" onChange={setInformacion}/>
                </>)
            }
            <div className="addContainerInsert">
                <button className="buttonInsert" onClick={() => agregar()} disabled={!(gasto.fecha1 && gasto.tipo && gasto.monto)}>AÑADIR</button>
            </div>

        </div>
    )
}

export default Insert