import React, { useEffect, useState } from "react"
import PerjuiciosTable from "./PerjuiciosTable"
import BackButton from "../../sharedComponents/backButton/BackButton"
import ContinueButton from "../../sharedComponents/continueButton/ContinueButton"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { backStep, nextStep, setVictimasIndirectas } from "../../store/features/appSlice"
import './perjuicios.css'
import DañoMoral from "./dañoMoral/dañoMoral"
import Modal from "../../sharedComponents/modal/Modal"
import VictimasIndirectas from "./vicitimasIndirectas/victimasIndicrectasModal"
import DañoVictimas from "./dañoVictimas/dañoVictimas"
import DañoMoral2 from "./dañoMoral2/dañoMoral2"
import DañoVidaRelacion from "./dañoVidalRelacion/dañoVidaRelacion"
import Resultados from "./resultados/resultados"

function Perjuicios() {
    const dispatch = useAppDispatch()

    const data = useAppSelector(state => state.app.data)

    const [question, setQuestion] = useState(1)
    const [open, setOpen] = useState(false)

    const [victimas, setVictimas] = useState([
        {nombre: 'padres', selected: false, value: 0, liquidation: 0},
        {nombre: 'hijos', selected: false, value: 0, liquidation: 0},
        {nombre: 'hermanos', selected: false, value: 0, liquidation: 0},
        {nombre: 'abuelos', selected: false, value: 0, liquidation: 0},
        {nombre: 'conyuge', selected: false, value: 0, liquidation: 0}
    ])

    useEffect(() => {
        if (question === 1 && data.consecuencia !== 'muerte'){
            setQuestion(2)
        }

        if (question === 2 && data.tipoDeIncapacidad === 'temporal'){
            setQuestion(3)
        }

        if (question === 3 && data.tipoDeIncapacidad === 'temporal'){
            setQuestion(6)
        } else if (question === 3 && (data.tipoDeIncapacidad === 'permanente')){
            setOpen(true)
        } else if (question === 3  && data.consecuencia === 'muerte') {
            const newVictimas = victimas.map((victima, index) => {
                const reclamantes = data.reclamantes.filter(reclamante => reclamante.parentesco === victima.nombre)
                const newVictima = {...victima, value: reclamantes.length, selected: reclamantes.length > 0 ? true : false}
                console.log(data.reclamantes, newVictima)
                return newVictima
            })
            dispatch(setVictimasIndirectas(newVictimas))
            setQuestion(4)
        }

        if (question === 5 && data.tipoDeIncapacidad === 'temporal'){
            dispatch(nextStep())
        }

    }, [question])

    return (
        <div className="lucroCesanteContainer">
            <div className="dañoEmergenteHeader">
                <h1>PERJUICIOS EXTRAPATRIMONIALES
                    {/* <Tooltip title={'Recuerde que si piensa efectuar un reclamo necesita documentos (facturas, recibos, etc.) o en general alguna prueba de haberlos sufrido'}>
                        <IconButton style={{ marginLeft: 10, color: '#338dc7'  }}>
                            <ErrorOutlineIcon />
                        </IconButton>
                    </Tooltip> */}
                </h1>
            </div>
            {question === 1 && (
                <DañoMoral setQuestion={setQuestion} question={question} />
            )}

            {question === 2 && (
                <DañoMoral2 setQuestion={setQuestion} question={question} />
            )}

            {question === 4 && (
                <DañoVictimas setQuestion={setQuestion} question={question} />
            )}
            
            {question === 5 && (
                <DañoVidaRelacion setQuestion={setQuestion} question={question} />
            )}

            {question === 6 && (
                <Resultados setQuestion={setQuestion} question={question} />
            )}

            <Modal open={open} onClose={() => {
                                            setOpen(false)
                                            setQuestion(question + 1)
                                        }} content={
                <VictimasIndirectas question={question} setOpen={setOpen}  setQuestion={setQuestion}/>
            }/>
        </div>
    )
}
export default Perjuicios