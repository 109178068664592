import React, { useEffect, useState } from 'react'
import { Dialog, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import InsertDependientes from './insertDependientes'
import LucroTable from './lucroTable'
import ContinueButton from '../../sharedComponents/continueButton/ContinueButton'
import Modal from '../../sharedComponents/modal/Modal'
import SelectIngresos from './selectIngresos/SelectIngresos'
import ReclamantesGestor from './reclamantesGestor/ReclamantesGestor'
import { useAppSelector } from '../../store/hooks'

function  LucroCesante ({saveData, setImgTable2}){
    const [pregunta, setPregunta] = useState(true)

    const data = useAppSelector(state => state.app.data)

    const [condicion, setCondicion] = useState('')
    const [muerte, setMuerte] = useState(false)
    const [showMuerte, setShowMuerte] = useState(false)
    const [dependientesEconomicos, setDependientesEconomicos] = useState(false)
    const [ingreso, setIngreso] = useState(0)
    const [InsertDepe, setInsertDepe] = useState(false)
    const [dependientesList, setDependientesList] = useState([])
    console.log(data)
    useEffect(() => {
        if (data.consecuencia === 'muerte') {
            setPregunta(false)
        }
    },[])

    useEffect(() => {
        if (pregunta === false && data.consecuencia !== 'muerte') {
            setPregunta(null)
        }
    }, [pregunta])

    return (
        <div className="dañoEmergenteContainer" >
            <div className="dañoEmergenteHeader">
                <h1>LUCRO CESANTE
                    {/* <Tooltip title={'Recuerde que si piensa efectuar un reclamo necesita documentos (facturas, recibos, etc.) o en general alguna prueba de haberlos sufrido'}>
                        <IconButton style={{ marginLeft: 10, color: '#338dc7'  }}>
                            <ErrorOutlineIcon />
                        </IconButton>
                    </Tooltip> */}
                </h1> 
                <label className="labelOption widthDaño">A continuación, seleccione o añada los ingresos de la víctima que quiere liquidar</label>
            </div>
            {pregunta && (
                <SelectIngresos setPregunta={setPregunta} />
            )}
            {pregunta === false && (
                <ReclamantesGestor setPregunta={setPregunta} />
            )}
            {pregunta === null && (
                <LucroTable ingreso={ingreso} muerte={muerte} condicion={condicion} setImgTable2={setImgTable2} saveData={saveData} />
            )}
        </div>
    )
}

export default LucroCesante