import React from 'react';
import InitialWelcome from '../../sharedComponents/InitialWelcome/InitialWelcome';
import LoginForm from './LoginForm';

function Login() {
    return(
        <div className='LoginRegisterLayout'>
            <InitialWelcome />
            <LoginForm />
        </div>
    )
}

export default Login;