import React from "react"
import { useAppSelector } from "../../../../store/hooks"

function AlertPregunta({setPreguntaIngresos, setShowAlertPregunta, setShowModalAlert}) {
    const data = useAppSelector(state => state.app.data)

    const handleYes = () => {
        setShowModalAlert(true)
        setPreguntaIngresos(false)
        setShowAlertPregunta(false)
    }
    const handleNo = () => {
        setShowModalAlert(false)
        setPreguntaIngresos(false)
        setShowAlertPregunta(false)
    }

    return (
        <div className="alertPreguntaContainer">
            <h1>¿La suma ingresada contiene el factor prestacional (aportes a seguridad social)?</h1>
            <div>
                <div className="buttonsContainer">
                    <button onClick={() => handleYes()}>SI</button>
                    <button onClick={() => handleNo()}>NO</button>
                </div>
            </div>
        </div>
    )
}

export default AlertPregunta