import React, {useEffect, useState} from "react";
import moment from "moment";
import './insertGastos.css'
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setDañoEmergenteFuturos, setDañoEmergentePasados } from "../../store/features/appSlice";
import { Divider, IconButton, Tooltip } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

function InsertGastos({
    // gastos, setGastos, gastosFuturos, setGastosFuturos, 
    setInsert}) {
    const dispatch = useAppDispatch()

    const data = useAppSelector(state => state.app.data)

    const gastosPasados = useAppSelector(state => state.app.data.dañoEmergente.pasados)
    const gastosFuturos = useAppSelector(state => state.app.data.dañoEmergente.futuros)

    const [gasto, setGasto] = useState({})
    const [unico, setPagoUnico] = useState(false)

    const agregar = () => {
        const localGasto = {
            unico,
            ...gasto
        }

        if (moment(localGasto.fecha2).isAfter(moment(data.fechaLiquidacion)) && !localGasto.unico
                    && moment(localGasto.fecha1).isBefore(moment(data.fechaLiquidacion))) {
            const gasto1 = { ...localGasto, fecha2: data.fechaLiquidacion }
            const gasto2 = { ...localGasto, fecha1: data.fechaLiquidacion }

            dispatch(setDañoEmergentePasados([...gastosPasados, gasto1]))
            dispatch(setDañoEmergenteFuturos([...gastosFuturos, gasto2]))
        } else if (gasto.vitalicio) {
            const gasto1 = { ...localGasto, fecha2: data.fechaLiquidacion }
            const gasto2 = { ...localGasto, fecha1: data.fechaLiquidacion }

            dispatch(setDañoEmergentePasados([...gastosPasados, gasto1]))
            dispatch(setDañoEmergenteFuturos([...gastosFuturos, gasto2]))
        } else {
            if (moment(data.fechaLiquidacion).diff(moment(gasto.fecha1), 'days') > 0) {
                dispatch(setDañoEmergentePasados([...gastosPasados, localGasto]))
            } else {
                dispatch(setDañoEmergenteFuturos([...gastosFuturos, localGasto]))
                // setGastosFuturos([...gastosFuturos, localGasto])
            }
        }


        setInsert(false)
    }

    const setInformacion = (ev) => {
        const {name, value} = ev.target
        setGasto({...gasto, [name]: value })
    }

    return (
        <div className="modalContainer">
            <div className="insertHeader">
                <h1>AÑADIR GASTO</h1>
                <Divider style={{ width: '100%', backgroundColor: '#686868'}}  />
            </div>
            <div className="unicoContainer">
                    <div className='radioContainer'>
                        <input style={{ margin: '0 0 0 0' }}    className='radioInput' type="radio" id="unico" name="sexo" value="true" onChange={ev => setPagoUnico(!unico)} />
                        <label style={{ margin: '0 0 0 10px' }} className='labelOption mt-10' for="unico">De suma única
                            <Tooltip title="El gasto único es aquel que se paga solo una vez.">
                                <IconButton style={{ paddingLeft: 5}}>
                                    <ErrorOutlineIcon style={{ width: 15, color: '#338dc7' }}  />
                                </IconButton>
                            </Tooltip>
                        </label>
                    </div>
                    <div className='radioContainer'>
                        <input style={{ margin: '0 0 0 10px' }} className='radioInput' type="radio" id="periodica" name="sexo" value="false" onChange={ev => setPagoUnico(false)} />
                        <label style={{ margin: '0 0 0 10px' }} className='labelOption mt-10' for="periodica">De suma periódica
                            <Tooltip style={{ width: 5 }} title="En este caso, el gasto periódico es una suma de dinero que se pago mensualmente.">
                                <IconButton style={{ paddingLeft: 5}}>
                                    <ErrorOutlineIcon  style={{ width: 15, color: '#338dc7' }} />
                                </IconButton>
                            </Tooltip>
                        </label>
                    </div>
            </div>
            <label className="labelOptionBold">Tipo de gasto</label>
            <input className="input mt" type="text" placeholder="" name="tipo" onChange={setInformacion} />
            <label className="labelOptionBold">Monto
            </label>
            <label className="labelOption mt-10 widthInsert" >Ingrese el monto mensual en pesos colombianos, sin puntos ni comas:</label>
            <input className="input mt" type="number" placeholder="" name="monto" onChange={setInformacion}/>
            {
                unico ? (
                <>
                        <label className="labelOptionBold">Fecha</label>
                        <label className="labelOption mt-10 widthInsert" >A continuación, añada la fecha en la que la víctima debió o debería obtener ese gastto:</label>
                        <input className="dateSelect" type="date" onChange={ev => setGasto({...gasto, fecha1: ev.target.value })}/>
                </>) 
                : (<>
                        <label className="labelOptionBold">Rango de fechas</label>
                        <label className="labelOption mt-10">Seleccione las fechas en la que la víctima debió o debería obtener ese gasto:</label>
                        <div className="datesContainerInsert">
                            <div className="dateContainerInsert">
                                <label className="labelOption mt-10">Inicio</label>
                                <input className="dateSelect insertWidth" type="date" onChange={ev => setGasto({...gasto, fecha1: ev.target.value })}/>
                            </div>
                            <div className="dateContainerInsert">
                                <label className="labelOption mt-10">Fin</label>
                                <input className="dateSelect insertWidth" type="date" onChange={ev => setGasto({...gasto, fecha2: ev.target.value })}/>
                                <div className="radioContainer mt">
                                    <input style={{ margin: '5px 5px 0 0' }}    className='radioInput' type="radio" id="vitalicio" name="vitalicio" value="true" onChange={setInformacion} />
                                    <label className="labelOption mt-10">De forma vitalicia</label>
                                </div>
                            </div>

                        </div>
                </>)
            }
            <div className="addContainerInsert">
                <button className="buttonInsert" onClick={() => agregar()} disabled={!(gasto.fecha1 && gasto.tipo && gasto.monto)}>AÑADIR</button>
            </div>

        </div>
    )


    return (
        <div className="modalContainer">
            <label className="subLabel">Ingrese si fue un pago único o si le tocó pagar periódicamente. El periodo se asumirá mensual.</label>
            <div className="unicoContainer">
                <label className="">¿Pago unico?</label>
                <input className="checkBox" type="checkbox" placeholder="pago unico?" onChange={() => setPagoUnico(!unico)}/>
            </div>
            {
                unico ? (
                <>
                        <label className="subLabel">Tipo</label>
                        <input className="input" type="text" placeholder="" name="tipo" onChange={setInformacion} />
                        <label className="label">Fechas</label>
                        <label className="subLabel">Inicio</label>
                        <input className="input" type="date" onChange={ev => setGasto({...gasto, fecha1: ev.target.value })}/>
                        <label className="subLabel">Monto</label>
                        <input className="input" type="number" placeholder="Monto en pesos" name="monto" onChange={setInformacion}/>
                </>) 
                : (<>
                        <label className="subLabel">Tipo</label>
                        <input className="input" type="text" placeholder="" name="tipo" onChange={setInformacion} />
                        <label className="label">Fechas</label>
                        <label className="subLabel">Inicio</label>
                        <input className="input" type="date" onChange={ev => setGasto({...gasto, fecha1: ev.target.value })}/>
                        <label className="subLabel">Fin</label>
                        <input className="input" type="date" onChange={ev => setGasto({...gasto, fecha2: ev.target.value })}/>
                        <label className="subLabel">Monto en pesos</label>
                        <input className="input" type="number" placeholder="" name="monto" onChange={setInformacion}/>
                </>)
            }
            <button className="button continueBtn addBtn" onClick={() => agregar() } disabled={!(gasto.fecha1 && gasto.tipo && gasto.monto)}>Agregar</button>

        </div>
    )
}

export default InsertGastos