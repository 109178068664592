
import React, {useState, useEffect, useRef} from "react"
import { Dialog, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material"
import Axios from 'axios'
import InsertGastos from "./insertGastos"
import config from "../../libs/config"
import moment from "moment"
import html2canvas from 'html2canvas'
import './dañoEmergente.css'
import ContinueButton from "../../sharedComponents/continueButton/ContinueButton"
import BackButton from "../../sharedComponents/backButton/BackButton"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { backStep, nextStep } from "../../store/features/appSlice"
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Modal from "../../sharedComponents/modal/Modal";
import DañoTable from "./dañoTable/DañoTable"
import DañoTableEdit from "./dañoTableEdit/DañoTableEdit"
import { getValorAbsoluto } from "../../libs/helpers"


function DañoEmergente({setData, saveData, setImgTable}) {
    const dispatch = useAppDispatch()

    const step = useAppDispatch(state => state.app.step)

    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    const miDiv = useRef(null)

    // const [gastos, setGastos] = useState([
    //     //  { unico: true, tipo: "funeraria", fecha1: "2023-03-27", monto: "123000" }, { unico: false, tipo: "Comida", fecha1: "2023-01-01", fecha2: "2023-02-28", monto: "200000" }
    //      ])
    // const [gastosFuturos, setGastosFuturos] = useState([ 
    //     // { unico: true, tipo: "funeraria", fecha1: "2023-03-27", monto: "123000" }, { unico: false, tipo: "Comida", fecha1: "2023-01-01", fecha2: "2023-02-28", monto: "200000" }
    //  ])


    const data = useAppSelector(state => state.app.data)
    const gastos = useAppSelector(state => state.app.data.dañoEmergente.pasados)
    const gastosFuturos = useAppSelector(state => state.app.data.dañoEmergente.futuros)

    const [insert, setInsert] = useState(false)
    const [quoted, setQuoted] = useState(false)
    const [ipcs, setIpcs] = useState([])
    const [calculoPasado, setCalculoPasado] = useState(0)
    const [calculoFuturo, setCalculoFuturo] = useState(0)

    const getIpcs = async () => {
        await Axios.get(`${config.api}/api/ipc`). then(response => {
            setIpcs(response.data)
        })
    }

    const handleNext = () => {
        dispatch(nextStep())
    }

    const handleBack = () => {
        dispatch(backStep())
    }

    function diffMonths(date1, date2) {
        var diffDays = (date2 - date1) / (1000 * 60 * 60 * 24);
        var diffMonths = diffDays / 30;
        return diffMonths.toFixed(2); // redondear a 2 decimales
    }

    useEffect(() => {
        getIpcs()
    }, [])

    const convertirImagen = () => {
        html2canvas(miDiv.current).then(canvas => {
            canvas.toBlob(blob => {
                setImgTable(blob)
            });
        })
    }

      



    return (
        <div className="dañoEmergenteContainer" >
            {gastos.length === 0 && gastosFuturos.length === 0 && (
                <>
                    <div className="dañoEmergenteHeader">
                        <h1>Daño Emergente
                            <Tooltip title={'Recuerde que si piensa efectuar un reclamo necesita documentos (facturas, recibos, etc.) o en general alguna prueba de haberlos sufrido'}>
                                <IconButton style={{ marginLeft: 10, color: '#338dc7'  }}>
                                    <ErrorOutlineIcon />
                                </IconButton>
                            </Tooltip></h1> 
                        <label className="labelOption widthDaño">Por favor ingrese a continuación ingrese los gastos, costos o erogaciones en las que incurrió la victima o el reclamante con ocasión del daño ocurrido.</label>
                    </div>
                    <div className="dañoCard">
                        <div className="dañoColumn">
                            <label className="labelOptionBold noMarginTop">Gastos</label>
                            <label className="labelOption">A continuación, inserte los gastos efectuados y la información correspondiente para cada uno</label>
                            <button onClick={() => setInsert(true)} className="insertButtonDaño">AÑADIR GASTOS</button>
                        </div>
                    </div>
                </>
            )}
            {(gastos.length > 0 || gastosFuturos.length > 0 ) && !quoted && <DañoTableEdit setQuoted={setQuoted} setInsert={setInsert} />}
            {quoted && <DañoTable />}
            <div className="navBtnsContainer">
                <ContinueButton onClick={() => dispatch(nextStep())} text="Siguiente"/>
            </div>
            <Modal open={insert} onClose={() => setInsert(false)} content={
                <InsertGastos setInsert={setInsert} />
            } />
        </div>
    )
}

export default DañoEmergente