import React from 'react'
import Header from '../../sharedComponents/Header'
import './politicas.css'
import { Divider, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';


export const Politicas = () => {

    const handleClose = () => {
        window.history.back()
    }


    return (
        <div className='containerPoliticas'>
            {/* <Header /> */}
            <div className='textContainer'>
                <h1>POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES:</h1>
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                    sx={{
                        marginTop: '-70px',
                        position: 'absolute',
                        right: "23%",
                        // top: "32vh",
                        // top: -10,
                        backgroundColor: '#cecfd0',
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Divider style={{ width: '100%', backgroundColor: '#686868'}}  />
                <p className='text'>En Liquilex, los datos personales son tratados de forma estrictamente privada y confidencial, en los términos de
la normatividad vigente, por lo cual, la recolección, almacenamiento, uso, circulación, supresión, transmisión o
transferencia de dichos datos personales, se hace mediante un uso adecuado, legítimo y lícito, con salvaguarda
permanente a lo dispuesto por la normativa aplicable y las disposiciones que los modifiquen o adicionen. Las
personas naturales titulares de la información podrán ejercer los derechos consagrados en la normatividad
vigente, en especial los derechos previstos por la Ley 1581 de 2012, de acceso, rectificación, actualización y
supresión, y todas aquellas normas que la reglamenten, adicionen o complementen. La finalidad para la cual se
almacenarán los datos personales se basa en la correcta cuantificación de los perjuicios posibles a reclamar.<br/><br/>

Los titulares de los derechos, cuando adviertan el presunto incumplimiento de cualquiera de los deberes
contenidos en la normativa vigente, podrán presentar un reclamo o solicitud ante la firma remitiéndola en una
primera instancia a través del siguiente correo electrónico, habilitado para el efecto: [*]<br/><br/>

Liquilex atenderá y dará respuesta a los reclamos o solicitudes en los plazos y términos establecidos para el
efecto por la legislación vigente.<br/><br/>
                </p>

            </div>
        </div>
    )
}