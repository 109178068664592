import React, { useState, useEffect } from "react"

import './liquidacion.css'
import { useDispatch, useSelector } from "react-redux"
import { capitalize, diffMonths, getDañoEmergente, getLucroCesnte, getMoneyFormat, getPeriod, getProbability, getSecondDate } from '../../libs/helpers'
import TableIngresosView from "../../sharedComponents/TableIngresosView/TableIngresosView"
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import Axios  from "axios"
import config from '../../libs/config'
import moment from "moment"
import { mortalidadHombres, mortalidadMujeres } from '../../sharedComponents/mortalidades/mortalidades'
import ResultadosTable from "../perjuciosExtrapatrimoniales/resultados/resultadosTable"
import { ArrowRight } from "@mui/icons-material"
import { PDFDocument, rgb } from 'pdf-lib';
import html2canvas from 'html2canvas';
import { resetAppData } from "../../store/features/appSlice"
import { useNavigate } from "react-router-dom"

function Liquidacion() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

    const data = useSelector(state => state.app.data)
    const gastos = useAppSelector(state => state.app.data.dañoEmergente.pasados)
    const gastosFuturos = useAppSelector(state => state.app.data.dañoEmergente.futuros)

    const gastosLucro = useAppSelector(state => state.app.data.lucroCesante.pasados)
    const gastosFuturosLucro = useAppSelector(state => state.app.data.lucroCesante.futuros)

    const [ipcs, setIpcs] = useState([])
    const [calculoPasado, setCalculoPasado] = useState(0)
    const [calculoFuturo, setCalculoFuturo] = useState(0)

    const [calculoPasadoLucro, setCalculoPasadoLucro] = useState(0)
    const [calculoFuturoLucro, setCalculoFuturoLucro] = useState(0)

    const getIpcs = async () => {
        await Axios.get(`${config.api}/api/ipc`). then(response => {
            setIpcs(response.data)
        })
    }

    const getGastoActualizado = (gasto, actualizado = true) => {
        if (ipcs.length === 0) return gasto.monto

        return getDañoEmergente(gasto, ipcs, data, actualizado)

        if (moment().diff(moment(gasto.fecha1), 'days') > 0) {
            if (gasto.unico){
                return getDañoEmergente(gasto, ipcs, data)
            } else {

                const fecha1 = moment(gasto.fecha1) 
                const mes1 = moment(fecha1).format('MM');
                const anio1 = moment(fecha1).format('YYYY');

                const fecha2 = moment(gasto.fecha2) 
                const mes2 = moment(fecha2).format('MM');
                const anio2 = moment(fecha2).format('YYYY');

                const diferenciaEnMeses = diffMonths(fecha1, fecha2);

                console.log({diferenciaEnMesesPasadoPeriodico: diferenciaEnMeses})

                const ipcInicial = ipcs.filter(ipc => ipc.ano === parseInt(anio1) && ipc.mes === meses[parseInt(mes1) - 1])[0]
                const ipcFinal = ipcs.filter(ipc => ipc.ano === parseInt(anio2) && ipc.mes === meses[parseInt(mes2) - 1])[0]

                let sa = gasto.monto * (ipcFinal.valor / ipcInicial.valor)

                let s = sa * ((((1 + 0.004867)** diferenciaEnMeses) - 1) / 0.004867)
                return s
                
            }
        } else {
            if (gasto.unico){
                const fecha = moment(gasto.fecha1) 
                const mes = moment(fecha).format('MM');
                const anio = moment(fecha).format('YYYY');
                const dia = moment(fecha).format('DD');
                
                const fechaHoy = moment() 
                const mesHoy = moment(fechaHoy).format('MM');
                const anioHoy = moment(fechaHoy).format('YYYY');
                const diahoy = moment(fechaHoy).format('DD');

                const diferenciaEnMeses = (anioHoy - anio) * 12 + (mesHoy - mes) + (diahoy - dia) * 30;

                console.log({diferenciaEnMesesUnicoFuturo: diferenciaEnMeses})

                let s = gasto.monto * (1 / (1 + 0.004867) ** (diferenciaEnMeses + 1))

                return s
            } else {

                const fecha1 = moment(gasto.fecha1) 
                const mes1 = moment(fecha1).format('MM');
                const anio1 = moment(fecha1).format('YYYY');
                const dia1 = moment(fecha1).format('DD');
                
                const fecha2 = moment(gasto.fecha2) 
                const mes2 = moment(fecha2).format('MM');
                const anio2 = moment(fecha2).format('YYYY');
                const dia2 = moment(fecha1).format('DD');

                const diferenciaEnMeses = (anio2 - anio1) * 12 + (mes2 - mes1) + (dia2 - dia1) * 30

                console.log({diferenciaEnMesesPeriodicoFuturo: meses})

                let s = gasto.monto * ((
                    ((1 + 0.004867)** diferenciaEnMeses) - 1) / (0.004867 * ((1 + 0.004867)** diferenciaEnMeses)))

                return s

            }
        }
    }

    const handlePrintPage = async () => {
        // Obtén el contenido del div que deseas agregar al PDF
        const contenidoDiv = document.getElementById('liquidationCOntainer');
    
        // Captura el contenido del div como una imagen utilizando html2canvas
        const canvas = await html2canvas(contenidoDiv);
    
        // Crea un nuevo documento PDF
        const pdfDoc = await PDFDocument.create();
        const page = pdfDoc.addPage([canvas.width, canvas.height]);
        const { width, height } = page.getSize();
    
        // Dibuja la imagen en la página del PDF
        const image = await pdfDoc.embedPng(canvas.toDataURL('image/png'));
        page.drawImage(image, {
          x: 20,
          y: 0,
          width: (width - 40),
          height,
            
        });
    
        // Guarda el PDF en un blob
        const pdfBytes = await pdfDoc.save();
    
        // Crea una URL para el blob y descarga automáticamente el PDF
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'liquidación_liquilex.pdf';
        link.click();
        handleFinish()
    }

    const handleFinish = () => {
        dispatch(resetAppData())
        navigate('/creditos')

    }

    const getGastoActualizadoLucro = (gasto, actualizado = true) => {
        if (ipcs.length === 0) return gasto.monto
        if (moment().diff(moment(gasto.fecha1), 'days') > 0){
            if (gasto.unico){
                return getLucroCesnte(gasto, ipcs, data, actualizado)
            } else {
                return getLucroCesnte(gasto, ipcs, data, actualizado)
                
            }
        } else {
            if (gasto.unico){
                return getLucroCesnte(gasto, ipcs, data, actualizado)
            } else {
                return getLucroCesnte(gasto, ipcs, data, actualizado)
        }
        }
    }

    const getTotal = () => {
        let total = 0

        total += calculoPasado + calculoFuturo
        total += calculoFuturoLucro + calculoPasadoLucro
        total += data.totalPerjuicios


        return total
    }


    useEffect(() => {
        getIpcs()
    }, [])

    
    useEffect(() => {
        let localPasados = 0
        let localFuturos = 0

        if (ipcs.length > 0 && gastos.length > 0) {
            gastos.forEach(gasto => {
                if (gasto.unico){
                    localPasados += getDañoEmergente(gasto, ipcs, data)
                } else {
                    localPasados += getDañoEmergente(gasto, ipcs, data)
                    
                }
            })
        }

        setCalculoPasado(localPasados)

        if (ipcs.length > 0 && gastosFuturos.length > 0) {
            gastosFuturos.forEach(gasto => {
                if (gasto.unico){
                    localFuturos += getDañoEmergente(gasto, ipcs, data)
                } else {
                    localFuturos += getDañoEmergente(gasto, ipcs, data)
    
                }
            })
        }

        setCalculoFuturo(localFuturos)
        
    }, [ipcs, gastos, gastosFuturos])
    
    useEffect(() => {
        let localPasados = 0
        let localFuturos = 0

        if (ipcs.length > 0 && gastosLucro.length > 0) {
            gastosLucro.forEach(gasto => {
                if (gasto.unico){
                    localPasados += getLucroCesnte(gasto, ipcs, data)
                } else {
                    localPasados += getLucroCesnte(gasto, ipcs, data)
                    
                }
            })
        }

        setCalculoPasadoLucro(localPasados)

        if (ipcs.length > 0 && gastosFuturosLucro.length > 0) {
            gastosFuturosLucro.forEach(gasto => {
                if (gasto.unico){
                    localFuturos += getLucroCesnte(gasto, ipcs, data)
                } else {
                    localFuturos += getLucroCesnte(gasto, ipcs, data)
    
                }
            })
        }

        setCalculoFuturoLucro(localFuturos)
        
    }, [ipcs, gastosLucro, gastosFuturosLucro])

    return(
        <div className="liquidationCOntainer" id="liquidationCOntainer">
            <h1>LIQUIDACIÓN</h1>
            <div className="liquidationSubCOntainer">
                <div className="dataUser">
                    <label className="subTitleLiquidation">Datos de la Víctima</label>
                    <div className="valuesContainer">
                        <label className="subLabelLiquidation">Nombre completo:</label>
                        <label className="subTextLiquidation">{data.nombre}</label>
                    </div>
                    <div className="valuesContainer">
                        <label className="subLabelLiquidation">Fecha de nacimiento:</label>
                        <label className="subTextLiquidation">{data.fechaNacimiento}</label>
                    </div>
                    <div className="valuesContainer">
                        <label className="subLabelLiquidation">Sexo:</label>
                        <label className="subTextLiquidation">{capitalize(data.sexo)}</label>
                    </div>
                    <div className="valuesContainer">
                        <label className="subLabelLiquidation">Ingresos reportados:</label>
                    </div>
                    <div className="valuesContainer">
                        <TableIngresosView ingresos={data.ingresos}/>
                    </div>
                    <div className="valuesContainer">
                        <label className="subLabelLiquidation">Situación laboral:</label>
                        <label className="subTextLiquidation">{data.situacionLaboral}</label>
                    </div>
                    <div className="valuesContainer">
                        <label className="subLabelLiquidation">Condición al momento de los hechos:</label>
                        <label className="subTextLiquidation">{data.condicion}</label>
                    </div>
                    <div className="valuesContainer">
                        <label className="subLabelLiquidation">Consecuencia del daño:</label>
                        <label className="subTextLiquidation">{capitalize(data.consecuencia)}</label>
                    </div>
                    <div className="valuesContainer">
                        <label className="subTitleLiquidation">Datos de los reclamantes:</label>
                    </div>
                    <div className="reclamanteContainerLiquidation">
                        {data.reclamantes.map((reclamante, index) => (
                            <div className="reclamanteLiquidation">
                                <label className="subTitleLiquidation2">Reclamante #{index + 1}</label>
                                <div className="valuesContainer">
                                    <label className="subLabelLiquidation">Nombre completo:</label>
                                    <label className="subTextLiquidation">{capitalize(reclamante.nombre)}</label>
                                </div>
                                <div className="valuesContainer">
                                    <label className="subLabelLiquidation">Fecha de nacimiento:</label>
                                    <label className="subTextLiquidation">{capitalize(reclamante.fechaNacimiento)}</label>
                                </div>
                                <div className="valuesContainer">
                                    <label className="subLabelLiquidation">Sexo:</label>
                                    <label className="subTextLiquidation">{capitalize(reclamante.sexo)}</label>
                                </div>
                                <div className="valuesContainer">
                                    <label className="subLabelLiquidation">Parentesco con la víctima:</label>
                                    <label className="subTextLiquidation">{capitalize(reclamante.parentesco)}</label>
                                </div>
                                <div className="valuesContainer">
                                    <label className="subLabelLiquidation">Condición al momento de los hechos:</label>
                                    <label className="subTextLiquidation">{capitalize(reclamante.condicion)}</label>
                                </div>
                                <div className="valuesContainer">
                                    <label className="subLabelLiquidation">Dependencia de la víctima:</label>
                                    <label className="subTextLiquidation">{capitalize(reclamante.dependenciaEconomica)}</label>
                                </div>
                            </div>
                        ))}
                    </div>
                    <label className="subTitleLiquidation mt-20">Liquidación por Daño Emergente</label>
                    <div className="valuesContainer">
                        <Table className="tablePregunta tableSelect">
                            <TableHead className="tableHead">
                                <TableRow>
                                    <TableCell className="textTableHead blue1">Gasto</TableCell>
                                    <TableCell className="textTableHead blue1">Tipo de gasto</TableCell>
                                    <TableCell className="textTableHead blue1">Monto</TableCell>
                                    <TableCell className="textTableHead blue1">Monto Actualizado</TableCell>
                                    <TableCell className="textTableHead blue2">Fecha</TableCell>
                                    <TableCell className="textTableHead blue3">Periodo indemnizable</TableCell>
                                    <TableCell className="textTableHead blue4">Liquidación</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.dañoEmergente.pasados.map((ingreso, index) => (
                                    <TableRow>
                                        <TableCell className="tableCellBody">{ingreso.unico ? 'Único' : 'Periódico'}</TableCell>
                                        <TableCell className="tableCellBody">{ingreso.tipo}</TableCell>
                                        <TableCell className="tableCellBody">{getMoneyFormat(ingreso.monto)}</TableCell>
                                        <TableCell className="tableCellBody">{getMoneyFormat(getGastoActualizado(ingreso))}</TableCell>
                                        <TableCell className="tableCellBody">{ingreso.fecha1} {getSecondDate(ingreso)}</TableCell>
                                        <TableCell className="tableCellBody">{getPeriod(ingreso, data.fechaLiquidacion, data)}</TableCell>
                                        <TableCell className="tableCellBody">{getMoneyFormat(getGastoActualizado(ingreso, false))}</TableCell>
                                    </TableRow>
                                ))}
                                {data.dañoEmergente.futuros.map((ingreso, index) => (
                                    <TableRow>
                                        <TableCell className="tableCellBody">{ingreso.unico ? 'Único' : 'Periódico'}</TableCell>
                                        <TableCell className="tableCellBody">{ingreso.tipo}</TableCell>
                                        <TableCell className="tableCellBody">{getMoneyFormat(ingreso.monto)}</TableCell>
                                        <TableCell className="tableCellBody">{getMoneyFormat(getGastoActualizado(ingreso))}</TableCell>
                                        <TableCell className="tableCellBody">{ingreso.fecha1} {getSecondDate(ingreso)}</TableCell>
                                        <TableCell className="tableCellBody">{getPeriod(ingreso, data.fechaLiquidacion, data)}</TableCell>
                                        <TableCell className="tableCellBody">{getMoneyFormat(getGastoActualizado(ingreso, false))}</TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell className="tableCellBody"></TableCell>
                                    <TableCell className="tableCellBody"></TableCell>
                                    <TableCell className="tableCellBody"></TableCell>
                                    <TableCell className="tableCellBody"></TableCell>
                                    <TableCell className="tableCellBody"></TableCell>
                                    <TableCell className="textTableHead blue3">Total a liquidar</TableCell>
                                    <TableCell className="tableCellBody">{getMoneyFormat(calculoPasado + calculoFuturo)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                    <div className="valuesContainer">
                        <label className="subLabelLiquidation">Total a liquidar:</label>
                        <label className="subTextLiquidation">{getMoneyFormat(calculoPasado + calculoFuturo)} COP</label>
                    </div>
                    <label className="subTitleLiquidation mt-20">Liquidación por Lucro Cesante</label>
                    <div className="valuesContainer">
                        <Table className="tablePregunta tableSelect">
                            <TableHead className="tableHead">
                                <TableRow>
                                    <TableCell className="textTableHead blue1">Gasto</TableCell>
                                    <TableCell className="textTableHead blue1">Tipo de gasto</TableCell>
                                    <TableCell className="textTableHead blue1">Monto</TableCell>
                                    <TableCell className="textTableHead blue1">Monto Actualizado</TableCell>
                                    <TableCell className="textTableHead blue2">Fecha</TableCell>
                                    <TableCell className="textTableHead blue3">Periodo indemnizable</TableCell>
                                    <TableCell className="textTableHead blue4">Liquidación</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {gastosLucro.map((ingreso, index) => (
                                    <TableRow>
                                        <TableCell className="tableCellBody">{ingreso.unico ? 'Único' : 'Periódico'}</TableCell>
                                        <TableCell className="tableCellBody">{ingreso.tipo}</TableCell>
                                        <TableCell className="tableCellBody">{getMoneyFormat(ingreso.monto)}</TableCell>
                                        <TableCell className="tableCellBody">{getMoneyFormat(getGastoActualizadoLucro(ingreso))}</TableCell>
                                        <TableCell className="tableCellBody">{ingreso.fecha1} {getSecondDate(ingreso)}</TableCell>
                                        <TableCell className="tableCellBody">{getPeriod(ingreso, data.fechaLiquidacion, data)}</TableCell>
                                        <TableCell className="tableCellBody">{getMoneyFormat(getGastoActualizadoLucro(ingreso, false))}</TableCell>
                                    </TableRow>
                                ))}
                                {gastosFuturosLucro.map((ingreso, index) => (
                                    <TableRow>
                                        <TableCell className="tableCellBody">{ingreso.unico ? 'Único' : 'Periódico'}</TableCell>
                                        <TableCell className="tableCellBody">{ingreso.tipo}</TableCell>
                                        <TableCell className="tableCellBody">{getMoneyFormat(ingreso.monto)}</TableCell>
                                        <TableCell className="tableCellBody">{getMoneyFormat(getGastoActualizadoLucro(ingreso))}</TableCell>
                                        <TableCell className="tableCellBody">{ingreso.fecha1} {getSecondDate(ingreso)}</TableCell>
                                        <TableCell className="tableCellBody">{getPeriod(ingreso, data.fechaLiquidacion, data)}</TableCell>
                                        <TableCell className="tableCellBody">{getMoneyFormat(getGastoActualizadoLucro(ingreso, false))}</TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell className="tableCellBody"></TableCell>
                                    <TableCell className="tableCellBody"></TableCell>
                                    <TableCell className="tableCellBody"></TableCell>
                                    <TableCell className="tableCellBody"></TableCell>
                                    <TableCell className="tableCellBody"></TableCell>
                                    <TableCell className="textTableHead blue3">Total a liquidar</TableCell>
                                    <TableCell className="tableCellBody">{getMoneyFormat(calculoPasadoLucro + calculoFuturoLucro)}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                    <div className="valuesContainer">
                        <label className="subLabelLiquidation">Total a liquidar:</label>
                        <label className="subTextLiquidation">{getMoneyFormat(calculoPasadoLucro + calculoFuturoLucro)} COP</label>
                    </div>
                    <label className="subTitleLiquidation mt-20">Liquidación por Perjuicios Extrapatrimoniales</label>
                    <div className="valuesContainer">
                        <ResultadosTable />
                    </div>
                </div>
            </div>
            <div className="totalContainer">
                <div className="valuesContainer">
                    <label className="subTitleLiquidation2">Total a liquidar:</label>
                </div>
                <div className="totalFinal">
                    <h1>TOTAL A LIQUIDAR
                        <ArrowRight style={{ fontSize:  '45px'}}/>
                    </h1>
                    <label className="totalText">{getMoneyFormat(getTotal())}</label>
                </div>
                {data.probabilityLoss.length > 0 && (
                    <div className="totalFinal2">
                        <h1>Total a liquidar ponderado por calificación de probabilidad de pérdida
                            <ArrowRight style={{ fontSize:  '45px'}}/>
                        </h1>
                        <label className="totalText2">{getMoneyFormat(getProbability(getTotal(), data))}</label>
                    </div>
                )}
            </div>
            <div className="footer-container">
                <button className="btn btn-primary-liquidation" onClick={handlePrintPage}>DESCARGAR PDF</button>
                <button className="btn btn-primary-liquidation" onClick={handleFinish}>FINALIZAR</button>
            </div>
        </div>
    )
}

export default Liquidacion