import React, { useEffect, useState } from 'react'
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'

import { mortalidadHombres, mortalidadMujeres } from '../../../../sharedComponents/mortalidades/mortalidades'
import { mortalidadHombres as mortalidadesHombresDispacidad, mortalidadMujeres as mortalidadesMujeresDispacidad } from '../../../../sharedComponents/mortalidades/mortalidadesDispacidad'
import moment from 'moment'
import { setIngresos, setIngresosPorIncapacidad } from '../../../../store/features/appSlice'
import { firtsMayus } from '../../../../libs/helpers'


const SelectIngresos = ({ setOpen }) => {
    const dispatch = useAppDispatch()

    //estados globales
    const {ingresos, edad, condicion, sexo, ingresosPorIncapacidad, tipoDeIncapacidad, fechasIncapacidad } = useAppSelector(state => state.app.data)

    //estados locales
    const [generalIngresos, setGeneralIngresos] = useState([])

    const getFinalDate = () => {
        let expectativa = 0
        if (condicion === 'capacidad') {
            if (sexo === 'masculino') {
                expectativa = mortalidadHombres[edad]
            }

            expectativa = mortalidadMujeres[edad]

        } else if (condicion === 'incapacidad') {
            if (sexo === 'masculino') {
                expectativa = mortalidadesHombresDispacidad[edad]
            }
            expectativa = mortalidadesMujeresDispacidad[edad]
        }

        const today = new Date()
        const finalDate = new Date(today.getFullYear() + expectativa.anos, today.getMonth(), today.getDate())
        return moment(finalDate).format('YYYY-MM-DD')
    }

    const checkIngreso = (ingreso) => {
        setGeneralIngresos([...generalIngresos, ingreso])
    }

    const setFechaIngresos = (newIngresosSelected) => {
        let newIngresos = ingresos.map(ingreso => {
            const ingresoSelected = newIngresosSelected.find(ingSel => ingSel.tipo === ingreso.tipo && ingSel.unico === ingreso.unico && ingSel.monto === ingreso.monto)

            if (ingresoSelected) {
                return ingresoSelected
            }
            return ingreso
        }) 

        dispatch(setIngresos(newIngresos))
    }

    const handleNext = () => {
        let newIngresos = []

        if (tipoDeIncapacidad === 'temporalmente') {
            newIngresos = generalIngresos.map(ingreso => {
                if (ingreso.unico) {
                    return ingreso
                } else {
                    let fechaInit = moment(ingreso.fecha1)
                    let fechaEnd = moment(ingreso.fecha2)


                    if (fechaInit.isBefore(fechasIncapacidad.inicial)) {
                        fechaInit = moment(fechasIncapacidad.inicial).format('YYYY-MM-DD')
                    } else if (fechaInit.isAfter(fechasIncapacidad.final)) {
                        fechaInit = moment(ingreso.fecha1).format('YYYY-MM-DD')
                    }

                    if (fechaEnd.isBefore(fechasIncapacidad.final)) {
                        fechaEnd = moment(ingreso.fecha2).format('YYYY-MM-DD')
                    } else if (fechaEnd.isAfter(fechasIncapacidad.final)) {
                        fechaEnd = moment(fechasIncapacidad.final).format('YYYY-MM-DD')
                    }
                    

                    return {
                        ...ingreso,
                        fecha1: typeof fechaInit === 'string' ? fechaInit : fechaInit.format('YYYY-MM-DD'),
                        fecha2: typeof fechaEnd === 'string' ? fechaEnd : fechaEnd.format('YYYY-MM-DD')
                    }
                }
            })

            setFechaIngresos(newIngresos)
        } else {
            newIngresos = generalIngresos
        }
        

        dispatch(setIngresosPorIncapacidad(newIngresos))
        setOpen(false)
    }
    console.log(generalIngresos)

    
    const getSecondDate = (ingreso) => {
        if (ingreso.unico) {
            return ''
        } else {
            if (ingreso.vitalicio){
                return '- Vitalicio'
            }
            return `- ${ingreso.fecha2}`
        }
    }


    return (
        <div className="selectIngresosContainer" style={{ marginTop: 20}}>
            <label>Seleccione los ingresos que dejó o dejará de percibir</label>
            <Table className="tablePregunta tableSelect mt-20">
                <TableHead className="tableHead">
                    <TableRow>
                        <TableCell className="textTableHead blue1">Ingreso de suma</TableCell>
                        <TableCell className="textTableHead blue2">Tipo de Ingreso</TableCell>
                        <TableCell className="textTableHead blue3">Rango de fechas</TableCell>
                        <TableCell className="textTableHead blue4">Monto</TableCell>
                        <TableCell className="textTableHead blue4">Seleccione</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ingresos.map((ingreso, index) => (
                        <TableRow>
                            <TableCell className="tableCellBody">{ingreso.unico ? 'Única' : 'Periódica'}</TableCell>
                            <TableCell className="tableCellBody">{firtsMayus(ingreso.tipo)}</TableCell>
                            <TableCell className="tableCellBody">{ingreso.fecha1} {getSecondDate(ingreso)}</TableCell>
                            <TableCell className="tableCellBody">${ingreso.monto}</TableCell>
                            <TableCell className="tableCellBody deleteCell" onClick={() => {}}><input type='radio' className='radioInput' onClick={() => checkIngreso(ingreso)} /></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {/* {generalIngresos.map((ingreso, index) => (
                <div key={index} className='row'>
                    <label>{ingreso.tipo}</label>
                    <input type='checkbox' value={ingreso.selected} onChange={() => checkIngreso(index)}/>
                    <input type='date' value={ingreso.fecha1}/>
                    <p>Monto: {ingreso.monto}</p>
                </div>
            ))} */}
            <button className="blueBTN" onClick={handleNext}>Listo</button>
        </div>
    )

}


export default SelectIngresos