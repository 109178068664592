import React, { useEffect, useState } from "react"
import { useAppSelector, useAppDispatch } from "../../../store/hooks"
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import moment from "moment"
import { nextStep, setLucroCensateFuturos, setLucroCesantePasados, setStep } from "../../../store/features/appSlice"
import ContinueButton from "../../../sharedComponents/continueButton/ContinueButton"
import { ArrowDropDown, Delete } from "@mui/icons-material"
import { firtsMayus, getMoneyFormat, getSecondDate } from "../../../libs/helpers"
import Modal from "../../../sharedComponents/modal/Modal"
import Alert3 from "../AlertToPass3/Alert3"

function SelectIngresos({ setPregunta }) {
    const dispatch = useAppDispatch()
    const data = useAppSelector(state => state.app.data)

    const [insert, setInsert] = useState(false)
    const [openModal, setOpenModal] = useState(false)

    const addIngreso = (ingreso) => {
        if (moment().diff(moment(ingreso.fecha1), 'days') > 0) {
            dispatch(setLucroCesantePasados([...data.lucroCesante.pasados, ingreso]))
            // setGastos([...gastos, localGasto])
        } else {
            // setGastosFuturos([...gastosFuturos, localGasto])
            dispatch(setLucroCensateFuturos([...data.lucroCesante.futuros, ingreso]))
        }
    }
    
    useEffect(() => {
        if (insert) {
            dispatch(setStep(2))
            setInsert(false)
        }
    }, [insert])

    return (
        <div className="cardContainerTable">
             <div className="rowTable">
                <label className="labelOptionBold">Ingresos</label>
                <label className="labelOption">A continuación, seleccione o añada los ingresos de la víctima que quiere liquidar</label>
             </div>
                <Table className="tablePregunta tableSelect mt-20">
                    <TableHead className="tableHead">
                        <TableRow>
                            <TableCell className="textTableHead blue1">Ingreso de suma...</TableCell>
                            <TableCell className="textTableHead blue3">Tipo de ingreso</TableCell>
                            <TableCell className="textTableHead blue1">Fecha</TableCell>
                            <TableCell className="textTableHead blue3">Monto</TableCell>
                            <TableCell className="textTableHead blue2">Acciones</TableCell>
                            <TableCell className="textTableHead blue1 cellCustomWidth"><ArrowDropDown style={{ fontSize: '36px' }}/></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.ingresos.map((ingreso, index) => (
                            <TableRow>
                                <TableCell className="tableCellBody">{ingreso.unico ? 'Única' : 'Periódica'}</TableCell>
                                <TableCell className="tableCellBody">{firtsMayus(ingreso.tipo)}</TableCell>
                                <TableCell className="tableCellBody">{ingreso.fecha1} {getSecondDate(ingreso)}</TableCell>
                                <TableCell className="tableCellBody">{getMoneyFormat(ingreso.monto)}</TableCell>
                                <TableCell className="tableCellBody deleteCell"><div className="deleteContainerTable"><Delete style={{ height: 14 }}/>Eliminar</div></TableCell>
                                <TableCell className="tableCellBody cellCustomWidth"><input type="radio" className="radioInput" onChange={() => addIngreso(ingreso)} /></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <div className='editBtnsDaño'>
                    <button onClick={() => setOpenModal(true)} className="insertButtonDaño" style={{ marginTop: 0 }}>AÑADIR INGRESOS</button>
                    <ContinueButton onClick={() => setPregunta(false)} text="Siguiente"/>
                </div>
            <Modal open={openModal} onClose={() => setOpenModal(false)} showClose={false} background="#0D78BC" content={
                <Alert3 setInsert={setInsert} setOpenModal={setOpenModal} />
            } />
        </div>)
 
}
export default SelectIngresos