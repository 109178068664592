import React from "react"
import alertIcon from '../../../imgs/ic7.png'
import { Divider } from "@mui/material"

function Alert3({ setOpenModal, setInsert }) {
    return (
        <div className="containerAlertIngresos">
            <img src={alertIcon} className="alertIcon"/>
            <h1>¡ATENCIÓN!</h1>
            <Divider style={{ width: '100%', backgroundColor: '#fff'}}  />
            <p className="textAlertIngresos">
            ¿Está seguro que quiere añadir/editar un ingreso? Al hacer
            clic sobre el botón <b>“SI”</b> regresará al Paso 2. Una vez editada
            la información depositada en este paso, puede regresar al
            Paso 5 (paso actual) haciendo clic sobre el número en la
            barra de progreso ubicada en la parte superior de la

            plataforma, su progreso será guardado.
            </p>
            <div classname="yesOrno">
                <button className="WhiteBtnYesOrNo" onClick={() => setInsert(true)} >SI</button>
                <button className="WhiteBtnYesOrNo" onClick={() => setOpenModal(false)}>NO</button>
            </div>

        </div>
    )
}

export default Alert3